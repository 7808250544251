import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { editProductTikTokInventory } from "actions/productTikTokActions";

const ModalEditQty = ({ isOpen, onClose, product, editProductTikTokInventory, onSubmit }) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState(null);
    const [data, setData] = useState({});

    useEffect(() => {
        if (product) setFormData(product);
    }, [product]);

    const handleSubmit = async () => {
        await editProductTikTokInventory({ productId: product._id, skus: formData.skus });
        onSubmit?.();
        onClose();
    };

    const handleChangeExt = (key) => {
        if (data?.extValue) {
            setFormData((prev) => ({
                ...prev,
                skus: prev.skus.map((v) => {
                    return { ...v, quantity: data?.extValue };
                }),
            }));
        }
    }

    return (
        <>
            <Modal
                centered={true}
                size="xl"
                toggle={() => {
                    onClose();
                }}
                isOpen={isOpen}
                scrollable={true}
            >
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Edit Product Quantity")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="form-group">
                        <label className="mb-4">Skus</label>
                        <div className="form-group mb-4">
                            <label>Quantity</label>
                            <div>
                                <input
                                    type="number"
                                    onChange={(data) => {
                                        setData((prev) => ({
                                            ...prev,
                                            extValue: data?.target?.value || 0,
                                        }));
                                    }}
                                    className="form-control"
                                    placeholder={t("Value")}
                                    name="extValue"
                                    value={data?.extValue || 0}
                                />
                            </div>
                        </div>
                        <button className="btn btn-sm btn-primary" onClick={() => handleChangeExt("price")}>Batch Update</button>
                        {formData?.skus?.map((sku, key) => (
                            <div key={key} className="row mt-2">
                                {/* <div className="col">
                                    <span>Id Sku</span>
                                    <input type="text" className="form-control" disabled value={sku.api_sku_id} />
                                </div> */}
                                <div className="col">
                                    <span>Data</span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        disabled
                                        value={Object.keys(sku)
                                            ?.filter(
                                                (i) =>
                                                    !["_id", "thumbnail", "price", "quantity", "api_sku_id"].includes(i)
                                            )
                                            .map((i) => sku[i])}
                                    />
                                </div>
                                <div className="col">
                                    <span>Quantity</span>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={sku.quantity}
                                        onChange={(e) => {
                                            setFormData((prev) => ({
                                                ...prev,
                                                skus: prev.skus.map((v) => {
                                                    if (v.api_sku_id === sku.api_sku_id)
                                                        return { ...v, quantity: e.target.value || 0 };
                                                    return v;
                                                }),
                                            }));
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { editProductTikTokInventory };

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditQty);
