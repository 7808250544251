import { useEffect, useState, useMemo } from "react";
import { ReactTableDataGrid } from "components/Table";
import { connect } from "react-redux";
import { getTotalByShop } from "actions/analyticActions";
import { convertTZ } from "components/Function";
import DatetimePicker from "components/elements/dateTimePicker";
import SelectStaff from "components/elements/selectStaff";

const ShopTable = ({ getTotalByShop }) => {
    const [filter, setFilter] = useState({});
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [totalSize, setTotalSize] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState([]);

    useEffect(() => {
        if (Object.keys(filter)?.length) {
            setIsLoading(true);
            getTotalByShop({ filter: filter }).then((d) => {
                setList(d?.data?.data || []);
                setIsLoading(false);
            });
        }
    }, [getTotalByShop, filter]);

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalSize || 50,
        },
    ];

    const columns = [
        {
            field: "_id",
            width: 120,
            cellClass: "centered-cell",
            headerName: "",
            cellRenderer: ({ rowIndex, value: cell, data: row }) => rowIndex + 1,
        },
        {
            field: "shopInfo",
            cellClass: "centered-cell",
            headerName: "Shop Info",
            flex: 1,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => (
                <div>
                    <p>{row.name}</p>
                    <span>
                        {row.note} - {row.member_note}
                    </span>
                </div>
            ),
            comparator: (_cellA, _cellB, { data: valueA }, { data: valueB }) => {
                if (valueA?.name < valueB?.name) return -1;
                if (valueA?.name > valueB?.name) return 1;
                return 0;
            },
        },
        {
            field: "totalListing",
            width: 120,
            cellClass: "centered-cell",
            headerName: "Total Listing",
            cellRenderer: ({ rowIndex, value: cell, data: row }) => row?.totalProductProductListing || 0,
            comparator: (_cellA, _cellB, { data: valueA }, { data: valueB }) => {
                if (valueA?.totalProductProductListing < valueB?.totalProductProductListing) return -1;
                if (valueA?.totalProductProductListing > valueB?.totalProductProductListing) return 1;
                return 0;
            },
        },
        {
            field: "totalActive",
            cellClass: "centered-cell",
            headerName: "Total Active",
            cellRenderer: ({ rowIndex, value: cell, data: row }) =>
                row?.tiktokProducts.filter((i) => i.apiStatus === "ACTIVATE")?.length || 0,
            comparator: (_cellA, _cellB, { data: valueA }, { data: valueB }) => {
                const a = valueA?.tiktokProducts.filter((i) => i.apiStatus === "ACTIVATE")?.length || 0;
                const b = valueB?.tiktokProducts.filter((i) => i.apiStatus === "ACTIVATE")?.length || 0;
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
            },
        },
        {
            field: "memberData",
            cellClass: "centered-cell",
            headerName: "Member",
            cellRenderer: ({ rowIndex, value: cell, data: row }) => row?.memberData?.email || row?.createBy?.email,
            comparator: (_cellA, _cellB, { data: valueA }, { data: valueB }) => {
                const a = valueA?.memberData?.email || valueA?.createBy?.email;
                const b = valueB?.memberData?.email || valueB?.createBy?.email;
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
            },
        },
    ];

    const onTableChanged = ({ page, limit, text }) => {
        if (limit) setSizePerPage(limit);
        if (page) setPage(page);
        if (typeof text === "string") setSearchText(text);
    };

    const defaultColDef = useMemo(
        () => ({
            wrapText: true,
            autoHeight: true,
            autoHeaderHeight: true,
        }),
        []
    );

    return (
        <div className="mt-4 card card-body">
            <h3>Analytic Shops</h3>
            <ReactTableDataGrid
                tableName="analyticShopTable"
                idProperty="_id"
                loading={isLoading}
                height={350}
                columns={columns}
                rowData={list}
                defaultColDef={defaultColDef}
                gridOptions={{
                    reactiveCustomComponents: true,
                }}
                paginationPageSize={sizePerPage}
                totalSize={totalSize}
                onTableChanged={onTableChanged}
                emptyText={"No data!"}
                rowSelection="multiple"
                sizePerPageList={sizePerPageList}
                groupButton={() => (
                    <div className="pb-4 d-flex gap-2">
                        <DatetimePicker onChange={(data) => setFilter((prev) => ({ ...prev, ...data }))} />
                        <SelectStaff onChange={(data) => setFilter((prev) => ({ ...prev, staff: data }))} />
                    </div>
                )}
                isSearchText={false}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { getTotalByShop };

export default connect(mapStateToProps, mapDispatchToProps)(ShopTable);
