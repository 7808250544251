import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { saveImportTrackingV2 } from "actions/orderActions";
import Loading from "components/Loading";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { getTracking } from 'ts-tracking-number';

function Modalimporttrackingv2(props) {
    const [apiOrderId] = useState(props.importtrackingv2.apiOrderId.split(","));
    const [list, setList] = useState([]);
    var { isLoading } = props.order;
    var { isOpenModalImportTrackingV2, openModal, saveImportTrackingV2 } = props;
    const { t } = useTranslation();

    const list_providers = [
        { value: '7011400463266350850', label: 'Yanwen' },
        { value: '7049196166784747269', label: 'Asendia US' },
        { value: '7117858858072016686', label: 'USPS' },
        { value: '7117859084333745966', label: 'UPS' },
        { value: '7129720299146184490', label: 'FedEx' },
        { value: '7132708441138677550', label: 'LaserShip' },
        { value: '7132721393761781550', label: 'OnTrac' },
        { value: '7212608507307099946', label: 'Better Trucks' },
        { value: '7212609771327719211', label: 'OSM Worldwide' },
        { value: '7212611208266909483', label: 'TForce' },
        { value: '7212612330725574442', label: 'Veho' },
        { value: '7213986298535610158', label: 'DHL express' },
        { value: '7220301902846625579', label: 'DHL eCommerce' },
        { value: '7248600717110282027', label: 'Amazon Logistics' },
        { value: '7254084300713232174', label: 'AxleHire' },
        { value: '7254085043432195882', label: 'Lone Star Overnight' },
        { value: '7260759364112221953', label: 'Deliver-it' },
        { value: '7260760118063531777', label: 'GLS US' },
        { value: '7260761851384825602', label: 'Spee-Dee Delivery' },
        { value: '7260762638932510466', label: 'Wizmo' },
        { value: '7325327335803406082', label: 'Speedx' },
        { value: '7347979279965095682', label: 'First Mile' },
        { value: '7352734302007723792', label: 'DoorDash' },
        { value: '7352738314622863120', label: 'UniUni' },
        { value: '7352739623900022544', label: 'Gofo Express' },
        { value: '7352739982978582289', label: 'ACI Logistix' },
        { value: '7372092091192575761', label: 'GPS' },
        { value: '7403621511082280721', label: 'Weee' },
        { value: '7443957391328020232', label: 'Ceva Logistics' },
        { value: '7443960654106461959', label: 'Pilot Freight Services' },
        { value: '7443960918918039303', label: 'Mainfreight' },
        { value: '7443961280932611847', label: 'GLS USA' },
        { value: '7443962159819654919', label: 'Freightquote by C.H. Robinson' },
        { value: '7443962761375123207', label: 'Tazmanian Freight Systems' },
        { value: '7443963002103006984', label: 'Metropolitan' },
        { value: '7443963103403837192', label: 'ABF Freight' },
        { value: '7443963680233899783', label: 'Saia Inc.' },
        { value: '7443963761360111378', label: 'Daylight Transport' },
        { value: '7443963984505472776', label: 'Day & Ross Freight' },
        { value: '7443964819356518162', label: 'Southeastern Freight Lines' },
        { value: '7443965081018189576', label: 'Roadrunner Freight' },
        { value: '7443965216620021522', label: 'Dayton Freight Lines' },
        { value: '7443965284153165586', label: 'Fastfrate Group' },
        { value: '7443965466114033416', label: 'CrossCountry Freight Solutions' },
        { value: '7443965673564276487', label: 'PS Logistics' },
        { value: '7443965834688464658', label: 'JP Express' },
        { value: '7443966039123035911', label: 'Tax-Air' },
        { value: '7443966093628344071', label: 'Oak Harbor Freight Lines' },
        { value: '7443967306838836999', label: 'DHL Heavyweight' },
        { value: '7443967395145729810', label: 'UPS Heavyweight' },
        { value: '7443971189820622610', label: 'XPO' },
        { value: '7443972065616463623', label: 'Estes Express Lines' },
        { value: '7443972524611733256', label: 'R+L Carriers' },
        { value: '7443972918754674450', label: 'Central Transport' },
        { value: '7443973316697655048', label: 'Averitt Express' },
        { value: '7443973716947502855', label: 'Pitt Ohio' },
        { value: '7443974197195310866', label: 'AAA Cooper Transportation' },
        { value: '7443974531196143368', label: 'A. Duie Pyle' },
        { value: '7444679050045425415', label: 'Old Dominion Freight Line USA' },
        { value: '7459702458671974152', label: 'Cirro' },
        { value: '7468802168162617094', label: 'PiggyShip' },
        { value: '7475569110803908358', label: 'Western Post' },
        { value: '7475848549314332421', label: 'DeliverDirect' },
        { value: '7482927909624088338', label: 'Cainiao US' },
        { value: '7482929018279298834', label: 'Tusk Logisitics' },
        { value: '6599541761693270018', label: 'EVRi' },
        { value: '6639580521074524161', label: 'DHL UK' },
        { value: '6641219975896514562', label: 'Yodel UK' },
        { value: '6649195729745887233', label: 'UK Mail' },
        { value: '6657598289359323137', label: 'DPD UK' },
        { value: '6658174162568658945', label: 'DX Delivery' },
        { value: '6671794738251726849', label: 'Royal Mail' },
        { value: '6699476450581430274', label: 'Parcel Force' },
        { value: '6760565269699084290', label: 'APC Overnight' },
        { value: '6760727669580627970', label: 'Panther UK' },
        { value: '7046331959399679746', label: 'Amazon Logistics' },
        { value: '7265220402182358786', label: 'Amazon Logistics UK' }
    ];

    useEffect(() => {
        if (apiOrderId.length > 0) {
            var tracking_number = "";
            var provider = "";
            if (props.importtrackingv2?.tracking_number) {
                tracking_number = props.importtrackingv2?.tracking_number;
                const getTrackingData = getTracking(tracking_number);
                provider = list_providers.find((v, i) => v?.label?.toUpperCase() === getTrackingData?.courier?.code?.toUpperCase())?.value || "";
            }
            var list_tmp = apiOrderId.map((v, i) => ({ apiOrderId: v, tracking_number, provider }))
            setList(list_tmp);
        }
    }, [apiOrderId]);

    const onClickSaveImportTrackingV2 = () => {
        saveImportTrackingV2({ list, page: props.importtrackingv2.page, sizePerPage: props.importtrackingv2.sizePerPage, searchText: props.importtrackingv2.searchText, filter_printer: props.importtrackingv2.filter_printer, filter_shop_code: props.importtrackingv2.filter_shop_code, filter_status: props.importtrackingv2.filter_status, filter_work_status: props.importtrackingv2.filter_work_status, filter_staff: props.importtrackingv2.filter_staff }, openModal);
    };

    const onChange = (e, i) => {
        if (e.target.name === "tracking_number") {
            const getTrackingData = getTracking(e.target.value);
            const provider = list_providers.find((v, i) => v?.label?.toUpperCase() === getTrackingData?.courier?.code?.toUpperCase());
            setList((prevList) =>
                prevList.map((v2, i2) =>
                    i2 === i ? { ...v2, [e.target.name]: e.target.value, provider: provider?.value || "" } : v2
                )
            );
        }
        if (e.target.name === "provider") {
            setList((prevList) =>
                prevList.map((v2, i2) =>
                    i2 === i ? { ...v2, [e.target.name]: e.target.value } : v2
                )
            );
        }
    };

    const onOpenModal = () => {
        openModal();
    };

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={() => { onOpenModal() }} isOpen={isOpenModalImportTrackingV2}>
                <Loading isLoading={isLoading} />
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Import Tracking")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onOpenModal() }} ></i>
                    </div>
                </div>
                <ModalBody>
                    {
                        list.map((v, i) => (
                            <div className="row" key={i}>
                                <div className="col-4 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                        <span>{t("Order Number")}</span>
                                    </label>
                                    <input type="text" className="form-control" placeholder="Order Number" name="api_order_id" value={v?.apiOrderId} disabled />
                                </div>
                                <div className="col-4 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                        <span>{t("Tracking number")}</span>
                                    </label>
                                    <input type="text" className="form-control" placeholder="Tracking Number" name="tracking_number" value={v?.tracking_number} onChange={(e) => { onChange(e, i) }} />
                                </div>
                                <div className="col-4 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                        <span>{t("Provider")}</span>
                                    </label>
                                    <select className="form-select" name="provider" value={v?.provider} onChange={(e) => { onChange(e, i) }} >
                                        <option value=""></option>
                                        {list_providers.map((v, i) => (
                                            <option key={i} value={v.value}>
                                                {v.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        ))
                    }
                </ModalBody>
                <ModalFooter>
                    <button type="reset" id="kt_modal_users_search_reset" data-bs-dismiss="modal" className="btn btn-active-light me-3" onClick={() => { onOpenModal() }} >{t("Cancel")}</button>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveImportTrackingV2() }}  >{t("Save")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

Modalimporttrackingv2.propTypes = {
    saveImportTrackingV2: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    order: state.order
});
const mapDispatchToProps = {
    saveImportTrackingV2
}

export default connect(mapStateToProps, mapDispatchToProps)(Modalimporttrackingv2);