import { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Select from "react-select";
import { formatterCreateAt, convertTZ } from "components/Function";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { ListDateType } from "helper/constant";
import { getShopDetail } from "actions/analyticActions";
import Loading from "components/Loading";
import Map from "./map";
import TableState from "./tableState";
import TableProduct from "./tableProduct";

const ModalAnalytic = ({ shopId, onClose, getShopDetail }) => {
    const { t } = useTranslation();
    const [dateType, setDateType] = useState("today");
    const [filter, setFilter] = useState({});
    const [orderState, setOrderState] = useState([]);
    const [orderProduct, setOrderProduct] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (shopId) {
            setIsLoading(true)
            getShopDetail({ filter: { ...filter, shopId } }).then((data) => {
                setIsLoading(false)
                setOrderState(data?.orderState || []);
                setOrderProduct(data?.product || []);
            });
        }
    }, [shopId, filter, getShopDetail]);

    useEffect(() => {
        if (dateType === "") {
            setFilter((prev) => ({
                ...prev,
                dateStart: "",
                dateEnd: "",
            }));
        }
        if (dateType === "custom") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end, "Asia/Ho_Chi_Minh");
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "today") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end, "Asia/Ho_Chi_Minh");
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "yesterday") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setDate(date_start.getDate() - 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate());
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setDate(date_start.getDate() - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setDate(date_start.getDate() - 7 - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_month") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_month") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            date.setMonth(date.getMonth() - 1);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_year") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_year") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            date.setFullYear(date.getFullYear() - 1);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        // eslint-disable-next-line
    }, [dateType]);

    const handleEventDate = (start, end) => {
        let date_start = new Date(start);
        date_start.setHours(0, 0, 0, 0);

        let date_end = new Date(end);
        date_end.setHours(23, 59, 59, 999);
        setFilter((prev) => ({
            ...prev,
            dateStart: date_start.getTime(),
            dateEnd: date_end.getTime(),
        }));
    };

    return (
        <Fragment>
            <Loading isLoading={isLoading} />
            <Modal centered={true} size="xl" toggle={onClose} isOpen={Boolean(shopId)} scrollable={true}>
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Shop Analytic")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <div className="d-flex gap-4">
                            <Select
                                name="dateType"
                                options={ListDateType.filter((v) => v.value)}
                                className="al-select"
                                classNamePrefix="select"
                                value={ListDateType.filter(({ value }) => value === dateType)}
                                onChange={(e) => {
                                    setDateType(e?.value || "");
                                }}
                                placeholder="Select a date ... "
                            />
                            {dateType === "custom" && filter?.dateStart && filter?.dateEnd && (
                                <div>
                                    <DateRangePicker
                                        onCallback={handleEventDate}
                                        initialSettings={{
                                            startDate: formatterCreateAt(filter.dateStart, "dmy"),
                                            endDate: formatterCreateAt(filter.dateEnd, "dmy"),
                                            showDropdowns: true,
                                            locale: { format: "DD/MM/YYYY" },
                                        }}
                                    >
                                        <input
                                            type="text"
                                            className="form-control al-form-control-daterangepicker mr-1"
                                        />
                                    </DateRangePicker>
                                </div>
                            )}
                        </div>
                    </div>
                    <Map data={orderState} />
                    <TableState data={orderState} />
                    <TableProduct data={orderProduct} />
                </ModalBody>
                <ModalFooter>
                    <button data-bs-dismiss="modal" className="btn btn-active-light me-3" onClick={onClose}>
                        {t("Cancel")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { getShopDetail })(ModalAnalytic);
