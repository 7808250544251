import axios from "axios";
import Swal from "sweetalert2";
import { t } from "i18next";
import { tokenConfig } from "./websiteActions";

const getProfileAutomate =
    ({ page = 1, sizeperpage = 50, search = "", filter = {} }) =>
        (dispatch, getState) => {
            dispatch({ type: "LOADING_PROFILE_AUTOMATE", payload: true });
            var param_search = "";
            if (search !== "") {
                param_search = `&search=${search}`;
            }
            filter = new URLSearchParams(filter).toString();
            if (filter) filter = `&${filter}`;

            return axios
                .get(`/api/profiles-automate?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}`, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_PROFILE_AUTOMATE", payload: false });

                    if (res.data.status === 200) {
                        dispatch({
                            type: "LIST_PROFILE_AUTOMATE",
                            payload: res.data.data,
                        });
                        return res.data.data;
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                        return [];
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_PROFILE_AUTOMATE", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                    return [];
                });
        };

const updateProfileAutomate = (data) => (dispatch, getState) => {
    const body = {
        ...data,
    };
    return Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to add config?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then((result) => {
        if (result.value) {
            dispatch({ type: "LOADING_PROFILE_AUTOMATE", payload: true });
            return axios
                .post("/api/profiles-automate/update", body, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_PROFILE_AUTOMATE", payload: false });
                    if (res.data.status === 200) {
                        Swal.fire("Success", res.data.message, "success");
                    } else {
                        Swal.fire("Error", res.data.message, "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_PROFILE_AUTOMATE", payload: false });
                    Swal.fire("Error", "An error occurred!", "error");
                });
        }
    });
};

const changeProfileAutomate = (data) => (dispatch, getState) => {
    return Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to change config?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then((result) => {
        if (result.value) {
            dispatch({ type: "LOADING_PROFILE_AUTOMATE", payload: true });
            return axios
                .post("/api/profiles-automate/change", data, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_PROFILE_AUTOMATE", payload: false });
                    if (res.data.status === 200) {
                        Swal.fire("Success", res.data.message, "success");
                    } else {
                        Swal.fire("Error", res.data.message, "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_PROFILE_AUTOMATE", payload: false });
                    Swal.fire("Error", "An error occurred!", "error");
                });
        }
    });
};

const calculatorTotalFilter =
    ({ search = "", filter = {} }) =>
        (dispatch, getState) => {
            dispatch({ type: "LOADING_PROFILE_AUTOMATE", payload: true });
            var param_search = "";
            if (search !== "") {
                param_search = `&search=${search}`;
            }
            filter = new URLSearchParams(filter).toString();
            if (filter) filter = `&${filter}`;

            return axios
                .get(`/api/profiles-automate/calculator?${param_search}${filter}`, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_PROFILE_AUTOMATE", payload: false });
                    if (res.data.status === 200) {                        
                        return res.data.data;
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                        return {};
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_PROFILE_AUTOMATE", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                    return {};
                });
        };

const exportProfileAutomate =
    ({ search = "", filter = {} }) =>
    (dispatch, getState) => {
        dispatch({ type: "LOADING_PROFILE_AUTOMATE", payload: true });
        var param_search = "";
        if (search !== "") {
            param_search = `&search=${search}`;
        }
        filter = new URLSearchParams(filter).toString();
        if (filter) filter = `&${filter}`;

        return axios
            .get(`/api/profiles-automate/export?${param_search}${filter}`, tokenConfig(getState))
            .then((res) => {
                dispatch({ type: "LOADING_PROFILE_AUTOMATE", payload: false });
                if (res.data.status === 200) {
                    dispatch({
                        type: 'EXPORT_PROFILE_AUTOMATE',
                        payload: res.data.data
                    });
                } else {
                    Swal.fire("Error", res.data.message, 'error');
                }
            })
            .catch((err) => {
                dispatch({ type: "LOADING_PROFILE_AUTOMATE", payload: false });
                Swal.fire(t("Error"), t("An error occurred!"), "error");
            });
};

const deleteProfileAutomate = (data) => (dispatch, getState) => {
    return Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to delete data?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then((result) => {
        if (result.value) {
            dispatch({ type: "LOADING_PROFILE_AUTOMATE", payload: true });
            return axios
                .post("/api/profiles-automate/delete", data, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_PROFILE_AUTOMATE", payload: false });
                    if (res.data.status === 200) {
                        Swal.fire("Success", res.data.message, "success");
                    } else {
                        Swal.fire("Error", res.data.message, "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_PROFILE_AUTOMATE", payload: false });
                    Swal.fire("Error", "An error occurred!", "error");
                });
        }
    });
};

const moveProfileAutomate = (data) => (dispatch, getState) => {
    return Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to move?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then((result) => {
        if (result.value) {
            dispatch({ type: "LOADING_PROFILE_AUTOMATE", payload: true });
            return axios
                .post("/api/profiles-automate/move", data, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_PROFILE_AUTOMATE", payload: false });
                    if (res.data.status === 200) {
                        Swal.fire("Success", res.data.message, "success");
                    } else {
                        Swal.fire("Error", res.data.message, "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_PROFILE_AUTOMATE", payload: false });
                    Swal.fire("Error", "An error occurred!", "error");
                });
        }
    });
};

export { getProfileAutomate, updateProfileAutomate, changeProfileAutomate, calculatorTotalFilter, exportProfileAutomate, deleteProfileAutomate, moveProfileAutomate };
