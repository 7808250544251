import { useMemo } from "react";
import { connect } from "react-redux";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import * as d3 from "d3-geo";
import { getLevelByCount } from "components/Function";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const stateAbbreviations = {
    Massachusetts: "MCS",
    "District of Columbia": "DOC",
    Connecticut: " ",
    Maryland: "ML",
    Delaware: " ",
    "West Virginia": "WV",
};

const colors = ["#008001", "#469537", "#6eaa5e", "#b7d5ad", "#dbead5"];

const Map = ({ data }) => {
    const arrState = useMemo(() => {
        return getLevelByCount(data);
    }, [data]);

    return (
        <ComposableMap projection="geoAlbersUsa">
            <Geographies geography={geoUrl}>
                {({ geographies, projection }) =>
                    geographies.map((geo) => {
                        const stateName = geo.properties.name;
                        const centroid = d3.geoCentroid(geo.geometry);
                        const data = projection(centroid);
                        const indexMap = arrState.find((v) => v._id === stateName)?.rank || -1;
                        const color = indexMap >= 0 ? colors[indexMap] || "#dbead5" : "#EAEAEC";

                        return (
                            <g key={geo.rsmKey}>
                                <Geography geography={geo} stroke="#fff" strokeWidth={1} fill={color} />
                                <title>{stateName}</title>
                                {data?.[0] && data?.[1] && (
                                    <text
                                        x={data[0]}
                                        y={data[1]}
                                        textAnchor="middle"
                                        alignmentBaseline="middle"
                                        style={{
                                            fontSize: "8px",
                                            fontWeight: "bold",
                                            fill: "#333",
                                            zIndex: 999,
                                        }}
                                    >
                                        {stateAbbreviations[stateName] || stateName}
                                    </text>
                                )}
                            </g>
                        );
                    })
                }
            </Geographies>
        </ComposableMap>
    );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Map);
