import { Fragment, useState } from "react";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import SelectBox from "components/elements/selectBox";

function ModalExportFileTemplate({ onSubmit, row }) {
    const [isOpen, setIsOpen] = useState(false);
    const [template, setTemplate] = useState("");
    const { t } = useTranslation();

    const handleSubmit = () => {
        onSubmit({ template });
        setIsOpen(false);
        setTemplate("");
    };

    return (
        <Fragment>
            <button onClick={() => { setIsOpen(true) }} className="btn btn-warning btn-sm me-1" disabled={row.length <= 0}><i className="ki-outline ki-exit-down"></i>{t("Export Tiktok XLSX")}</button>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    setIsOpen(!isOpen);
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h2 className="mb-0 text-warning">{t("Export Tiktok XLSX")}</h2>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex justify-content-between mb-5 w-100 flex-column gap-4">
                        <div className="form-group w-100">
                            <div className="col-md-12">
                                <SelectBox
                                    options={[
                                        { value: "fashion_accessories_1", label: "Fashion Accessories" },
                                        { value: "home_supplies_1", label: "Home Supplies" },
                                        { value: "jewelry_accessories_and_derivatives_1", label: "Jewelry Accessories & Derivatives" },
                                        { value: "kitchenware_1", label: "Kitchenware" },
                                        { value: "luggage_bags_1", label: "Luggage & Bags" },
                                        { value: "pet_supplies_1", label: "Pet Supplies" },
                                        { value: "sports_outdoor_1", label: "Sports & Outdoor" },
                                        { value: "textiles_soft_furnishings_1", label: "Textiles & Soft Furnishings" },
                                        { value: "womenswear_underwear_1", label: "Womenswear & Underwear" },
                                    ]}
                                    className="h-100"
                                    name="category_version"
                                    value={template}
                                    onChange={(data) => {
                                        setTemplate(data?.value);
                                    }}
                                    placeholder={t("Select a template ... ")}
                                />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Download")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

// ModalExportFileTemplate.propTypes = {
// };
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalExportFileTemplate);
