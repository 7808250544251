import { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactTable, ReactTableDataGrid } from "components/Table";
import { getShops, renewalShop, deleteShop, cancelDeleteShop } from "actions/shopMessageActions";
import AddShop from "./addShop";
import EditShop from "./editShop";
import { getStaffs } from "actions/teamActions";
import { permissionComponent, FormatterStatus, formatterCreateAt } from "components/Function";
import Loading from "components/Loading";
import CustomHeader from "components/elements/customHeaderTable";
import SelectBox from "components/elements/selectBox";
import { ShopStatus } from "helper/constant";

const Shop = ({
    getShops,
    shop,
    user,
    deleteShop,
    cancelDeleteShop,
    FormatterStatus,
    formatterCreateAt,
    renewalShop,
}) => {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [shopSelect, setShopSelect] = useState(null);
    const [shopsSelect, setShopsSelect] = useState([]);
    const [filter, setFilter] = useState({});
    const [sort_by, setSortBy] = useState("");
    const [sort_order, setSortOrder] = useState("");

    const { t } = useTranslation();
    const { list, totalsize, isLoading } = shop;

    const columns = [
        {
            field: "checkbox",
            headerName: "",
            cellClass: "centered-cell",
            headerCheckboxSelection: true,
            checkboxSelection: true,
            width: 50,
            sortable: false,
        },
        {
            field: "_id",
            width: 120,
            cellClass: "centered-cell",
            headerName: "",
            cellRenderer: ({ rowIndex, value: cell, data: row }) => rowIndex + 1,
            sortable: true,
            comparator: () => 0,
        },
        {
            field: "name",
            width: 200,
            headerName: t("Name"),
            sortable: false,
        },
        {
            field: "region",
            headerName: t("Region"),
            sortable: false,
        },
        {
            field: "status",
            headerName: t("Status"),
            sortable: true,
            comparator: () => 0,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => <FormatterStatus status={cell || "Connected"} />,
        },
        {
            field: "code",
            headerName: t("Code"),
            sortable: false,
        },
        {
            field: "note",
            headerName: t("Note"),
            sortable: false,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => cell,
        },
        {
            field: "member_note",
            headerName: t("Member Note"),
            sortable: false,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => cell,
        },
        {
            field: "expired_at",
            headerName: t("Expired At"),
            sortable: false,
            cellRenderer: ({ rowIndex, value: cell, data: row }) =>
                +cell && +cell > 0 ? formatterCreateAt(+cell) : "Since 1905",
        },
        {
            field: "last_order",
            headerName: t("Last Order"),
            sortable: false,
            cellRenderer: ({ rowIndex, value: cell, data: row }) =>
                cell && cell > 0 ? formatterCreateAt(cell) : "Since 1905",
        },
        {
            field: "memberData",
            headerName: t("Members"),
            sortable: false,
            cellRenderer: ({ value: cell }) =>
                cell?.map((v, i) => (
                    <p>
                        - {v?.name} ({v?.email})
                    </p>
                )),
        },
        {
            field: "-",
            headerName: t("Actions"),
            sortable: false,
            cellRenderer: ({ rowIndex, value: cell, data: row }) =>
                permissionComponent({
                    user: user.user,
                    permission: "UPDATE_SHOP",
                    component:
                        row.status !== "Deleted" ? (
                            <>
                                <button
                                    onClick={() => {
                                        setShopSelect(row);
                                    }}
                                    className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={t("Edit Note")}
                                >
                                    <i className="ki-outline ki-notepad-edit fs-2"></i>
                                </button>
                                <button
                                    className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Renewal Shop"
                                    onClick={() => handleRenewalShop(row._id)}
                                >
                                    <i className="ki-outline ki-disk fs-2"></i>
                                </button>
                                <button
                                    className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Delete"
                                    onClick={() => handleDelete(row._id)}
                                >
                                    <i className="ki-outline ki-trash fs-2"></i>
                                </button>
                            </>
                        ) : (
                            <button
                                className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Cancel Delete"
                                onClick={() => handleCancelDelete(row._id)}
                            >
                                <i className="ki-outline ki-delete-folder fs-2"></i>
                            </button>
                        ),
                }),
        },
    ];

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalsize || 50,
        },
    ];

    const handleDelete = (id) => {
        deleteShop({ id, page, sizePerPage, searchText, filter, sort_by, sort_order });
    };

    const handleCancelDelete = (id) => {
        cancelDeleteShop({ id, page, sizePerPage, searchText, filter, sort_by, sort_order });
    };

    const handleRenewalShop = async (shopId) => {
        await renewalShop({ shopId });
        getShops({ page, sizeperpage: sizePerPage, search: searchText, filter, sort_by, sort_order });
    };

    const handleDeleteMultiple = () => {
        deleteShop({ id: shopsSelect, page, sizePerPage, searchText, filter, sort_by, sort_order });
    };

    useEffect(() => {
        getShops({ page, sizeperpage: sizePerPage, search: searchText, filter, sort_by, sort_order });
    }, [getShops, page, sizePerPage, searchText, filter, sort_by, sort_order]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    const groupButton = () => {
        return (
            <>
                <div className="mb-5 d-flex gap-2 pb-4">
                    <AddShop />
                    <button onClick={handleDeleteMultiple} type="button" className="btn btn-danger">
                        Delete shops
                    </button>
                    <SelectBox
                        options={ShopStatus}
                        value={filter.staff || ""}
                        onChange={(data) => {
                            setFilter((prev) => ({
                                ...prev,
                                status: data.value || "",
                            }));
                            setPage(1);
                        }}
                        placeholder="Select Status"
                    />
                </div>
            </>
        );
    };

    const onTableChanged = ({ page, limit, text }) => {
        if (limit) setSizePerPage(limit);
        if (page) setPage(page);
        if (typeof text === "string") setSearchText(text);
    };

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onSortChanged = (e) => {
        if (e.source === "uiColumnSorted") {
            console.log(e.api.setSuppressAnimations);
            const sortedColumns = e.api.getColumnState().filter((col) => col.sort);
            console.log(sortedColumns?.[0]?.colId, sortedColumns?.[0]?.sort);

            setSortBy(sortedColumns?.[0]?.colId);
            setSortOrder(sortedColumns?.[0]?.sort || "desc");
        }
    };

    const onSelectionChanged = (data) => {
        if (data.api) {
            const ids = data.api.getSelectedRows().map((d) => d._id);
            setShopsSelect(ids);
        }
    };

    return (
        <div className="card mb-5 mb-xl-10">
            <Loading isLoading={isLoading} />
            <div className="card-body">
                <div className="al-variants-table al-max-height-table-cus">
                    {/* <ReactTable
                        columns={columns}
                        data={list || []}
                        groupButton={groupButton}
                        handleTableChange={handleTableChange}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={totalSize}
                        alClassName="table-layout-inherit"
                        sizePerPageList={sizePerPageList}
                        isSelectRow={true}
                        selectRow={selectRow}
                    /> */}
                    <ReactTableDataGrid
                        tableName="shopMessageConnect"
                        idProperty="_id"
                        columns={columns}
                        rowData={list}
                        gridOptions={{
                            reactiveCustomComponents: true,
                        }}
                        paginationPageSize={sizePerPage}
                        totalSize={totalSize}
                        onTableChanged={onTableChanged}
                        emptyText={t("No data!")}
                        rowSelection="multiple"
                        sizePerPageList={sizePerPageList}
                        groupButton={groupButton}
                        onSortChanged={onSortChanged}
                        components={components}
                        onSelectionChanged={onSelectionChanged}
                    />
                </div>
            </div>
            <EditShop
                data={shopSelect}
                onClose={() => setShopSelect(null)}
                onSubmit={() => {
                    getShops({ page, sizeperpage: sizePerPage, search: searchText, filter, sort_by, sort_order });
                }}
            />
        </div>
    );
};

const mapStateToProps = ({ shopMessage, user }) => ({ shop: shopMessage, user });
const mapDispatchToProps = {
    getShops,
    getStaffs,
    FormatterStatus,
    formatterCreateAt,
    renewalShop,
    deleteShop,
    cancelDeleteShop,
};

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
