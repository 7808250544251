import axios from "axios";
import Swal from "sweetalert2";
import { t } from "i18next";
import { tokenConfig } from "./websiteActions";

const getAdminSku =
    ({ page = 1, sizeperpage = 10, search = "", filter = {} }) =>
    (dispatch, getState) => {
        let param_search = "";
        if (search !== "") {
            param_search = `&search=${search}`;
        }
        filter = new URLSearchParams(filter).toString();
        if (filter) filter = `&${filter}`;

        return axios
            .get(
                `/api/admin-sku?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}`,
                tokenConfig(getState)
            )
            .then((res) => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    Swal.fire(t("Error"), t(res.data.message), "error");
                    return [];
                }
            })
            .catch((err) => {
                Swal.fire(t("Error"), t("An error occurred!"), "error");
                return [];
            });
    };

const createAdminSku = (data) => (dispatch, getState) => {
    return axios
        .post(`/api/admin-sku`, data, tokenConfig(getState))
        .then((res) => {
            if (res.data.status === 200) {
                return res.data.data;
            } else {
                Swal.fire(t("Error"), t(res.data.message), "error");
                return [];
            }
        })
        .catch((err) => {
            Swal.fire(t("Error"), t("An error occurred!"), "error");
            return [];
        });
};

const editAdminSku = (data) => (dispatch, getState) => {
    return axios
        .put(`/api/admin-sku`, data, tokenConfig(getState))
        .then((res) => {
            if (res.data.status === 200) {
                return res.data.data;
            } else {
                Swal.fire(t("Error"), t(res.data.message), "error");
                return [];
            }
        })
        .catch((err) => {
            Swal.fire(t("Error"), t("An error occurred!"), "error");
            return [];
        });
};

export { getAdminSku, createAdminSku, editAdminSku };
