import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { OrderCancelReason } from "helper/constant";
import { cancelOrderTiktok } from "actions/orderActions";
import SelectBox from "components/elements/selectBox";

function ModalRefund({ isOpen, onClose, order, onSubmit, cancelOrderTiktok }) {
    const [cancelReason, setCancelReason] = useState("");
    const { t } = useTranslation();

    const getListReason = () => {
        if (order?.status === "UNPAID") return OrderCancelReason.reason;
        if (order?.status === "ON_HOLD" || order?.status === "AWAITING_SHIPMENT" || order?.status === "AWAITING_COLLECTION") return OrderCancelReason.reason1;
        return [];
    };

    const handleSubmit = async () => {
        await cancelOrderTiktok({
            orderId: order._id,
            cancel_reason: cancelReason,
        });
        onSubmit?.();
    };

    return (
        <Fragment>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    onClose();
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Cancel Infomation")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex gap-2 w-100">
                        <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container w-100">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="">{t("Reason")}</span>
                            </label>
                            <SelectBox
                                options={getListReason()}
                                value={cancelReason || ""}
                                onChange={(data) => {
                                    setCancelReason(data?.value || "");
                                }}
                                placeholder="Select Reason"
                                className="me-2 mb-2"
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { cancelOrderTiktok };

export default connect(mapStateToProps, mapDispatchToProps)(ModalRefund);
