import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { editProductTikTokPrice } from "actions/productTikTokActions";
import SelectBox from "components/elements/selectBox";

const ModalEditPrice = ({ isOpen, onClose, product, editProductTikTokPrice, onSubmit }) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState(null);
    const [data, setData] = useState({});

    useEffect(() => {
        if (product) setFormData(product);
    }, [product]);

    const handleChangeExt = (key) => {
        if (key === "price" && +data.extValue_price !== 0) {
            if (data?.price === "fixed") {
                setFormData((prev) => ({
                    ...prev,
                    skus: prev.skus.map((v) => {
                        return { ...v, price: (+data.extValue_price).toFixed(2) };
                    }),
                }));
            }
            if (data?.price === "plus") {
                setFormData((prev) => ({
                    ...prev,
                    skus: prev.skus.map((v) => {
                        return { ...v, price: (+v.price + +data.extValue_price).toFixed(2) };
                    }),
                }));
            }
            if (data?.price === "percent") {
                setFormData((prev) => ({
                    ...prev,
                    skus: prev.skus.map((v) => {
                        return { ...v, price: (+v.price + (+v.price / 100 * +data.extValue_price)).toFixed(2) };
                    }),
                }));
            }
            if (data?.price === "decrease") {
                setFormData((prev) => ({
                    ...prev,
                    skus: prev.skus.map((v) => {
                        return { ...v, price: (+v.price - +data.extValue_price).toFixed(2) };
                    }),
                }));
            }
            if (data?.price === "decrease_percent") {
                setFormData((prev) => ({
                    ...prev,
                    skus: prev.skus.map((v) => {
                        return { ...v, price: (+v.price - (+v.price / 100 * +data.extValue_price)).toFixed(2) };
                    }),
                }));
            }
        }
        if (key === "list_price" && +data.extValue_list_price !== 0) {
            if (data?.list_price === "fixed") {
                setFormData((prev) => ({
                    ...prev,
                    skus: prev.skus.map((v) => {
                        return {
                            ...v, list_price: {
                                amount: (+data.extValue_list_price).toFixed(2),
                                currency: "USD",
                            },
                        };
                    }),
                }));
            }
            if (data?.list_price === "plus") {
                setFormData((prev) => ({
                    ...prev,
                    skus: prev.skus.map((v) => {
                        return {
                            ...v, list_price: {
                                amount: (+v?.list_price?.amount + +data.extValue_list_price).toFixed(2),
                                currency: "USD",
                            },
                        };
                    }),
                }));
            }
            if (data?.list_price === "percent") {
                setFormData((prev) => ({
                    ...prev,
                    skus: prev.skus.map((v) => {
                        return {
                            ...v, list_price: {
                                amount: (+v?.list_price?.amount + (+v?.list_price?.amount / 100 * +data.extValue_list_price)).toFixed(2),
                                currency: "USD",
                            },
                        };
                    }),
                }));
            }
            if (data?.list_price === "decrease") {
                setFormData((prev) => ({
                    ...prev,
                    skus: prev.skus.map((v) => {
                        return {
                            ...v, list_price: {
                                amount: (+v?.list_price?.amount - +data.extValue_list_price).toFixed(2),
                                currency: "USD",
                            },
                        };
                    }),
                }));
            }
            if (data?.list_price === "decrease_percent") {
                setFormData((prev) => ({
                    ...prev,
                    skus: prev.skus.map((v) => {
                        return {
                            ...v, list_price: {
                                amount: (+v?.list_price?.amount - (+v?.list_price?.amount / 100 * +data.extValue_list_price)).toFixed(2),
                                currency: "USD",
                            },
                        };
                    }),
                }));
            }
            if (data?.list_price === "by_price") {
                setFormData((prev) => ({
                    ...prev,
                    skus: prev.skus.map((v) => {
                        return {
                            ...v, list_price: {
                                amount: (+v?.price).toFixed(2),
                                currency: "USD",
                            },
                        };
                    }),
                }));
            }
        }
        // const value = e.target.value;
        // setData((prev) => {
        //     const amount = +sku?.price || 0;
        //     let dataAmount = amount + +value;
        //     if (prev.type === "percent") {
        //         dataAmount = (amount + (amount * +value) / 100).toFixed(2);
        //     }
        //     if (!sku) dataAmount = 0;
        //     return { ...prev, extValue: value, amount: dataAmount };
        // });
    };

    const handleSubmit = async () => {
        await editProductTikTokPrice({ productId: product._id, skus: formData.skus });
        onSubmit?.();
        onClose();
    };

    return (
        <>
            <Modal
                centered={true}
                size="xl"
                toggle={() => {
                    onClose();
                }}
                isOpen={isOpen}
                scrollable={true}
            >
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Edit Product Price")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="form-group">
                        <label className="mb-4">Skus</label>
                        <div className="row">
                            <div className="col mb-4">
                                <div className="form-group mb-4">
                                    <label>Change Price</label>
                                    <SelectBox
                                        options={[
                                            { value: "percent", label: "Percent" },
                                            { value: "plus", label: "Plus" },
                                            { value: "fixed", label: "Fixed" },
                                            { value: "decrease", label: "Decrease" },
                                            { value: "decrease_percent", label: "Decrease Percent" },
                                        ]}
                                        className="h-100"
                                        name="price"
                                        value={data?.price || "fixed"}
                                        onChange={(data) => {
                                            setData((prev) => ({
                                                ...prev,
                                                price: data?.value || "fixed",
                                            }));
                                        }}
                                    />

                                </div>
                                <div className="form-group mb-4">
                                    <label>Value Ext</label>
                                    <div>
                                        <input
                                            type="number"
                                            onChange={(data) => {
                                                setData((prev) => ({
                                                    ...prev,
                                                    extValue_price: data?.target?.value || 0,
                                                }));
                                            }}
                                            className="form-control"
                                            placeholder={t("Percent Value")}
                                            name="extValue_price"
                                            value={data?.extValue_price || 0}
                                        />
                                    </div>
                                </div>
                                <button className="btn btn-sm btn-primary" onClick={() => handleChangeExt("price")}>Batch Update</button>
                            </div>
                            <div className="col mb-4">
                                <div className="form-group mb-4">
                                    <label>Change List Price</label>
                                    <SelectBox
                                        options={[
                                            { value: "percent", label: "Percent" },
                                            { value: "plus", label: "Plus" },
                                            { value: "fixed", label: "Fixed" },
                                            { value: "by_price", label: "By Price" },
                                            { value: "decrease", label: "Decrease" },
                                            { value: "decrease_percent", label: "Decrease Percent" },
                                        ]}
                                        className="h-100"
                                        name="list_price"
                                        value={data?.list_price || "by_price"}
                                        onChange={(data) => {
                                            setData((prev) => ({
                                                ...prev,
                                                list_price: data?.value || "by_price",
                                            }));
                                        }}
                                    />
                                </div>
                                {["percent", "plus", "fixed"].includes(data.list_price) && (
                                    <div className="form-group mb-4">
                                        <label>Value Ext</label>
                                        <div>
                                            <input
                                                type="number"
                                                onChange={(data) => {
                                                    setData((prev) => ({
                                                        ...prev,
                                                        extValue_list_price: data?.target?.value || 0,
                                                    }));
                                                }}
                                                className="form-control"
                                                placeholder={t("Percent Value")}
                                                name="extValue_list_price"
                                                value={data?.extValue_list_price || "0"}
                                            />
                                        </div>
                                    </div>
                                )}
                                <button className="btn btn-sm btn-info" onClick={() => handleChangeExt("list_price")}>Batch Update</button>
                            </div>
                        </div>

                        {formData?.skus?.map((sku, key) => (
                            <div key={key} className="row mt-2">
                                {/* <div className="col">
                                    <span>Id Sku</span>
                                    <input type="text" className="form-control" disabled value={sku.api_sku_id} />
                                </div> */}
                                <div className="col">
                                    <span>Data</span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        disabled
                                        value={Object.keys(sku)
                                            ?.filter(
                                                (i) =>
                                                    !["_id", "thumbnail", "price", "quantity", "api_sku_id", "list_price"].includes(i)
                                            )
                                            .map((i) => sku[i])}
                                    />
                                </div>
                                <div className="col">
                                    <span>Price</span>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={sku.price}
                                        onChange={(e) => {
                                            setFormData((prev) => ({
                                                ...prev,
                                                skus: prev.skus.map((v) => {
                                                    if (v.api_sku_id === sku.api_sku_id)
                                                        return { ...v, price: e.target.value || 0 };
                                                    return v;
                                                }),
                                            }));
                                        }}
                                    />
                                </div>
                                <div className="col">
                                    <span>List Price</span>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={sku?.list_price?.amount || ""}
                                        onChange={(e) => {
                                            setFormData((prev) => ({
                                                ...prev,
                                                skus: prev.skus.map((v) => {
                                                    if (v.api_sku_id === sku.api_sku_id)
                                                        return {
                                                            ...v,
                                                            list_price: {
                                                                amount: e.target.value,
                                                                currency: "USD",
                                                            },
                                                        };
                                                    return v;
                                                }),
                                            }));
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { editProductTikTokPrice };

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditPrice);
