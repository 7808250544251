import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import SelectBox from "components/elements/selectBox";

function ModalListPrice({ isOpen, onClose, sku, onSubmit }) {
    const [data, setData] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        if (sku) {
            setData({ amount: sku?.list_price?.amount || 0 });
        }
    }, [sku]);

    const handleSubmit = () => {
        const amount = data.amount;
        onSubmit(amount || "0", data);
    };

    const handleChange = (e) => {
        setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleChangeExt = (e) => {
        const value = e.target.value;
        setData((prev) => {
            const amount = +sku?.price || 0;
            let dataAmount = amount + +value;
            if (prev.type === "percent") {
                dataAmount = (amount + (amount * +value) / 100).toFixed(2);
            }
            if (!sku) dataAmount = 0;
            return { ...prev, extValue: value, amount: dataAmount };
        });
    };

    return (
        <Fragment>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    onClose();
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Edit List Price")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="form-group mb-4">
                        <label>Type</label>
                        <div>
                            <SelectBox
                                options={[
                                    { value: "percent", label: "Percent" },
                                    { value: "plus", label: "Plus" },
                                    { value: "fixed", label: "Fixed" },
                                ]}
                                className="h-100"
                                name="type"
                                value={data?.type || "fixed"}
                                onChange={(data) => {
                                    setData((prev) => ({
                                        ...prev,
                                        type: data?.value || "fixed",
                                        amount: sku?.price || "0",
                                        extValue: "0",
                                    }));
                                }}
                            />
                        </div>
                    </div>
                    {["percent", "plus"].includes(data.type) && (
                        <div className="form-group mb-4">
                            <label>Value Ext</label>
                            <div>
                                <input
                                    type="number"
                                    onChange={handleChangeExt}
                                    className="form-control"
                                    placeholder={t("Percent Value")}
                                    name="extValue"
                                    value={data?.extValue || "0"}
                                />
                            </div>
                        </div>
                    )}
                    <div className="form-group">
                        <label>Amount</label>
                        <div>
                            <input
                                type="number"
                                onChange={handleChange}
                                className="form-control"
                                placeholder={t("Price")}
                                name="amount"
                                value={data?.amount || "0"}
                                disabled={["percent", "plus"].includes(data.type)}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalListPrice);
