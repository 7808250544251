import { useEffect, useState } from "react";
import SelectBox from "components/elements/selectBox";
import { connect } from "react-redux";
import { getStaffs } from "actions/teamActions";

const SelectStaff = ({ getStaffs, onChange }) => {
    const [staffs, setStaffs] = useState([]);
    const [staff, setStaff] = useState("");

    useEffect(() => {
        getStaffs({ sizeperpage: 100 }).then((data) => {
            setStaffs(data?.data?.map((staff) => ({ value: staff?.user?._id, label: staff.user.email })) || []);
        });
    }, [getStaffs]);

    return (
        <SelectBox
            options={staffs}
            name="filter_staff"
            placeholder="Select Staff"
            value={staff || ""}
            onChange={(data) => {
                setStaff(data.value || "");
                onChange?.(data.value || "");
            }}
        />
    );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = { getStaffs };

export default connect(mapStateToProps, mapDispatchToProps)(SelectStaff);
