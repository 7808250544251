import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { getTransitions } from "actions/transitionActions";
import { formatterCreateAt, FormatterStatus } from "components/Function";
import { ReactTable } from "components/Table";
import Loading from "components/Loading";

function ListTransition(props) {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [filter, setFilter] = useState({});
    const [totalSize, setTotalSize] = useState(0);
    const { t } = useTranslation();
    var { list, totalsize, isLoading } = props.adminTransition;
    var { getTransitions, formatterCreateAt } = props;

    const listType = [
        {
            label: "Add",
            value: "add",
        },
        {
            label: "Subtract",
            value: "subtract",
        },
        {
            label: "Buy",
            value: "buy",
        },
    ];

    useEffect(() => {
        getTransitions({ page, sizeperpage: sizePerPage, filter });
    }, [getTransitions, page, sizePerPage, filter]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "user",
            text: "User",
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return (
                    <>
                        <p>{cell?.name}</p>
                        <b>{cell?.email}</b>
                    </>
                );
            },
        },
        {
            dataField: "type",
            text: "Type",
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return (
                    <span
                        className={`badge badge-light-${
                            cell === "add" || cell === "buy" || cell === "auto_add" ? "success" : "danger"
                        }`}
                    >
                        {cell}
                    </span>
                );
            },
        },
        {
            dataField: "beforeBalance",
            text: t("Before Balance"),
            sort: true,
        },
        {
            dataField: "amount",
            text: t("Balance Change"),
            sort: true,
        },
        {
            dataField: "afterBalance",
            text: t("After Balance"),
            sort: true,
        },
        {
            dataField: "description",
            text: t("Content"),
            sort: true,
        },
        {
            dataField: "created_at",
            text: t("Created At"),
            sort: true,
            formatter: (cell, row) => formatterCreateAt(cell),
        },
    ];

    const groupButton = () => {
        return (
            <div className="col-3">
                <Select
                    name="dateType"
                    options={listType}
                    className="al-select"
                    classNamePrefix="select"
                    value={listType.filter(({ value }) => value === filter.type)}
                    onChange={(e) => {
                        setFilter((prev) => ({ ...prev, type: e.value }));
                    }}
                    placeholder="Select a status ... "
                />
            </div>
        );
    };

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setFilter((prev) => ({ ...prev, search: searchText }));
        } else {
            setPage(page || 1);
            setSizePerPage(sizePerPage);
        }
    };

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalsize || 50,
        },
    ];

    return (
        <Fragment>
            <Loading isLoading={isLoading} />
            <div className="card mb-5 mb-xl-10">
                <div className="card-body p-9">
                    <div className="al-variants-table">
                        <ReactTable
                            columns={columns}
                            data={list}
                            groupButton={groupButton}
                            handleTableChange={handleTableChange}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            alClassName="table-layout-inherit"
                            sizePerPageList={sizePerPageList}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    adminTransition: state.adminTransition,
});
const mapDispatchToProps = {
    getTransitions,
    formatterCreateAt,
    FormatterStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListTransition);
