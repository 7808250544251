import React, { useState, useEffect } from "react";
import Select from "react-select-virtualized";

const SelectBox = ({ options, name, onChange, value, className = "", placeholder = "", ...rest }) => {
    const [optionSelected, setSelectedOptions] = useState([]);

    useEffect(() => {
        setSelectedOptions(options.find((o) => o.value === value));
    }, [options, value]);

    const handleChange = (selected) => {
        onChange({ name, value: selected?.value });
        setSelectedOptions(selected);
    };

    return (
        <Select
            options={options}
            isLoading={!options}
            closeMenuOnSelect={true}
            onChange={handleChange}
            value={optionSelected}
            className={className || "cotik-select-virtual"}
            theme={(theme) => ({
                ...theme,
                spacing: {
                    ...theme.spacing,
                    controlHeight: "100%",
                    controlWidth: "200px",
                },
            })}
            placeholder={placeholder}
            {...rest}
        />
    );
};

export default SelectBox;
