import { useEffect, useState, useMemo, useRef, Fragment } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactTableDataGrid } from "components/Table";
import {
    getProfileAutomate,
    updateProfileAutomate,
    changeProfileAutomate,
    calculatorTotalFilter,
    exportProfileAutomate,
    deleteProfileAutomate,
    moveProfileAutomate,
} from "actions/profileAutomateActions";
import { FormatterStatus, formatterCreateAt } from "components/Function";
import Loading from "components/Loading";
import CustomHeader from "components/elements/customHeaderTable";
import ModalUpload from "./modalUpload";
import ModalChange from "./modalChange";
import ModalMove from "./modalMove";
import EditProfile from "./editProfile";
import SelectBox from "components/elements/selectBox";
import OnHold from "./onHold";
import { CSVLink } from "react-csv";
import { getStaffs } from "actions/teamActions";

const Shop = ({
    getProfileAutomate,
    profileAutomate,
    updateProfileAutomate,
    changeProfileAutomate,
    formatterCreateAt,
    calculatorTotalFilter,
    exportProfileAutomate,
    team,
    getStaffs,
    user,
    deleteProfileAutomate,
    moveProfileAutomate,
}) => {
    const [page, setPage] = useState(1);
    const [data, setData] = useState({});
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [shopsSelect, setShopsSelect] = useState([]);
    const [shopSelect, setShopSelect] = useState(null);
    const [filter, setFilter] = useState({});
    const [initiateDownload, setInitiateDownload] = useState(false);
    const [export_orders_data, setExportProfileAutomatesData] = useState([]);

    const btnRef = useRef(null);
    const { t } = useTranslation();
    const { role } = user.user;
    const { list, totalsize, isLoading, all_profileautomates_for_export } = profileAutomate;
    const { list: list_team } = team;
    const list_staff = list_team?.map((v, i) => {
        return { label: v?.user?.email, value: v?.user?._id };
    });

    const columns = [
        {
            field: 'checkbox',
            cellClass: "centered-cell",
            headerCheckboxSelection: true,
            checkboxSelection: true,
            width: 50,
            sortable: false,
        },
        {
            field: "_id",
            width: 50,
            cellClass: "centered-cell",
            headerName: "",
            cellRenderer: ({ rowIndex, value: cell, data: row }) => rowIndex + 1,
            sortable: true,
            comparator: () => 0,
        },
        {
            field: "profile_name",
            headerName: t("Name"),
            flex: 1,
            sortable: false,
        },
        {
            field: "profile_status",
            headerName: t("Status"),
            flex: 1,
            sortable: true,
        },
        {
            field: "profile_point",
            headerName: t("Point"),
            flex: 1,
            sortable: true,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => <span className="" data-tooltip-id="my-tooltip"
                data-tooltip-content={t("Violation points")}>{cell}</span>,
        },
        {
            field: "profile_hold",
            headerName: t("Hold"),
            flex: 1,
            sortable: true,
            cellRenderer: ({ rowIndex, value: cell, data: row }) =>
                <div>
                    <span data-tooltip-id="my-tooltip"
                        data-tooltip-content={t("Total Onhold")}>{(cell || 0) + '$'}</span>
                    <div className="d-flex justify-content-between">
                        <span className="text-primary me-3" data-tooltip-id="my-tooltip"
                            data-tooltip-content={t("Awaiting Delivery")}>{(row?.profile_await_delivery || 0) + '$'}</span>
                        <span className="text-warning  me-3" data-tooltip-id="my-tooltip"
                            data-tooltip-content={t("Awaiting Statement")}>{(row?.profile_await_statement || 0) + '$'}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span className="text-danger  me-3" data-tooltip-id="my-tooltip"
                            data-tooltip-content={t("Awaiting Refund/Return")}>{(row?.profile_await_return_refund || 0) + '$'}</span>
                        <span className="text-success  me-3" data-tooltip-id="my-tooltip"
                            data-tooltip-content={t("Reserver")}>{(row?.profile_reserve || 0) + '$'}</span>
                    </div>
                </div>
        },
        {
            field: "profile_pending",
            headerName: t("Pending"),
            flex: 1,
            sortable: true,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => <span className="" data-tooltip-id="my-tooltip"
                data-tooltip-content={t("Statement Paused")}>{(cell || 0) + '$'}</span>,
        },
        {
            field: "profile_total_paid",
            headerName: t("Total Paid"),
            flex: 1,
            sortable: true,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => <span className="" data-tooltip-id="my-tooltip"
                data-tooltip-content={t("Total Paid")}>{(cell || 0) + '$'}</span>,
        },
        {
            field: "profile_type",
            headerName: t("Type"),
            flex: 1,
            sortable: true,
        },
        {
            field: "profile_account",
            headerName: t("Account"),
            flex: 1,
            sortable: false,
        },
        {
            field: "profile_code",
            headerName: t("Code"),
            flex: 1,
            sortable: false,
        },
        {
            field: "updated_at",
            headerName: t("Last Update"),
            editable: false,
            flex: 1,
            cellRenderer: ({ value: cell }) => <span data-tooltip-id="my-tooltip"
                data-tooltip-content={formatterCreateAt(cell)}>{formatterCreateAt(cell)}</span>,
        },
        {
            field: "created_by",
            headerName: t("By"),
            editable: false,
            flex: 1,
            cellRenderer: ({ value: cell }) => <span data-tooltip-id="my-tooltip"
            data-tooltip-content={list_staff?.find((v) => v?.value === cell)?.label || "Admin"}>{list_staff?.find((v) => v?.value === cell)?.label || "Admin"}</span>,
        },
        {
            field: "-",
            headerName: t("Actions"),
            flex: 1,
            sortable: false,
            cellRenderer: ({ rowIndex, value: cell, data: row }) =>
                <button
                    onClick={() => {
                        setShopSelect(row);
                    }}
                    className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={t("Edit Note")}
                >
                    <i className="ki-outline ki-notepad-edit fs-2"></i>
                </button>
        },
    ];

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalsize || 50,
        },
    ];

    const onSelectionChanged = (data) => {
        if (data.api) {
            const ids = data.api.getSelectedRows().map((d) => d._id);
            setShopsSelect(ids);
        }
    };

    const handleUpdateProfile = async (data) => {
        await updateProfileAutomate({ ...data });
    };

    const handleChangeProfile = async (data) => {
        await changeProfileAutomate({ _id: shopsSelect, data });
    };

    const handleMoveProfile = async (data) => {
        await moveProfileAutomate(data);
    };
    const handleChangeDataProfile = async (data) => {
        await changeProfileAutomate({ _id: [shopSelect._id.toString()], data });
    };

    const handleDeleteProfile = async () => {
        await deleteProfileAutomate({ _id: shopsSelect });
    };

    useEffect(() => {
        getProfileAutomate({ page, sizeperpage: sizePerPage, search: searchText, filter });
    }, [getProfileAutomate, page, sizePerPage, searchText, filter]);

    useEffect(() => {
        calculatorTotalFilter({ search: searchText, filter }).then((o) => setData(o || {}));;
    }, [calculatorTotalFilter, searchText, filter]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    useEffect(() => {
        setInitiateDownload(false);
        if (initiateDownload) {
            btnRef.current?.click();
        }
    }, [initiateDownload]);

    useEffect(() => {
        if (all_profileautomates_for_export.length > 0) {
            var export_orders_data = [];
            for (var i = 0; i < all_profileautomates_for_export.length; i++) {
                if (shopsSelect.length > 0) {
                    if (!shopsSelect.includes(all_profileautomates_for_export[i]._id)) {
                        continue;
                    }
                }
                var export_orders_data_tmp = {
                    email_member: all_profileautomates_for_export[i]?.email_member,
                    profile_id: all_profileautomates_for_export[i]?.profile_id,
                    profile_name: all_profileautomates_for_export[i]?.profile_name,
                    profile_status: all_profileautomates_for_export[i]?.profile_status,
                    profile_type: all_profileautomates_for_export[i]?.profile_type,
                    profile_account: all_profileautomates_for_export[i]?.profile_account,
                    profile_point: all_profileautomates_for_export[i]?.profile_point,
                    profile_hold: all_profileautomates_for_export[i]?.profile_hold,
                    profile_reserve: all_profileautomates_for_export[i]?.profile_reserve,
                    profile_await_delivery: all_profileautomates_for_export[i]?.profile_await_delivery,
                    profile_await_statement: all_profileautomates_for_export[i]?.profile_await_statement,
                    profile_await_return_refund: all_profileautomates_for_export[i]?.profile_await_return_refund,
                    profile_pending: all_profileautomates_for_export[i]?.profile_pending,
                    profile_code: all_profileautomates_for_export[i]?.profile_code,
                    profile_total_paid: all_profileautomates_for_export[i]?.profile_total_paid,
                    created_at: formatterCreateAt(all_profileautomates_for_export[i]?.created_at, "full", "Asia/Ho_Chi_Minh"),
                }
                export_orders_data.push(export_orders_data_tmp);
            }
            setExportProfileAutomatesData(export_orders_data);
            setInitiateDownload(true);
        }
    }, [all_profileautomates_for_export, formatterCreateAt]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getStaffs({ page: 1, sizeperpage: 9999 });
    }, [getStaffs]);

    const groupButton = () => {
        return (
            <div className="mb-5">
                <ModalUpload onSubmit={handleUpdateProfile} />
                <ModalChange onSubmit={handleChangeProfile} />

                {(role === 1 || role === 0) && (
                    <Fragment>
                        <ModalMove onSubmit={handleMoveProfile} staffs={list_staff} />
                        <button onClick={() => { handleDeleteProfile() }} type="button" className="btn btn-danger btn-sm me-2">
                            <i className="ki-outline ki-cross-square fs-2"></i> {t("Delete")}
                        </button>
                        <button onClick={() => { onClickExportProfileAutomates() }} type="button" className="btn btn-success btn-sm me-2">
                            <i className="fa fa-file-excel-o fs-2"></i> {t("Export")}
                        </button>
                        <CSVLink filename={"profiles-automate_" + formatterCreateAt((new Date()).getTime(), "dmy", "Asia/Ho_Chi_Minh") + ".csv"} data={export_orders_data}>
                            <span ref={btnRef} />
                        </CSVLink>
                    </Fragment>
                )}
                <SelectBox
                    options={[{ value: "Live", label: "Live" }, { value: "Account Deactivated", label: "Deactivated" }]}
                    name="profile_status"
                    value={filter.profile_status || ""}
                    onChange={(data) => {
                        setFilter((prev) => ({
                            ...prev,
                            profile_status: data.value || "",
                        }));
                        setPage(1);
                    }}
                    className="basic-multi-select al-custom-select-search-variants mr-1 mb-0 al-filter-table me-2 css-b62m3t-container"
                    placeholder={t("Select a status ... ")}
                />
                <SelectBox
                    options={[{ value: "T+31", label: "T+31" }, { value: "T+8", label: "T+8" }, { value: "T+5", label: "T+5" }, { value: "T+1", label: "T+1" }]}
                    name="profile_type"
                    value={filter.profile_type || ""}
                    onChange={(data) => {
                        setFilter((prev) => ({
                            ...prev,
                            profile_type: data.value || "",
                        }));
                        setPage(1);
                    }}
                    className="basic-multi-select al-custom-select-search-variants mr-1 mb-0 al-filter-table me-2 css-b62m3t-container"
                    placeholder={t("Select a type ... ")}
                />
                <SelectBox
                    options={list_staff}
                    name="staff"
                    value={filter.staff || ""}
                    onChange={(data) => {
                        setFilter((prev) => ({
                            ...prev,
                            staff: data.value || "",
                        }));
                        setPage(1);
                    }}
                    className="basic-multi-select al-custom-select-search-variants mr-1 mb-0 al-filter-table me-2 css-b62m3t-container"
                    placeholder={t("Select a staff ... ")}
                />
            </div>
        );
    };

    const onTableChanged = ({ page, limit, text }) => {
        if (limit) setSizePerPage(limit);
        if (page) setPage(page);
        if (typeof text === "string") setSearchText(text);
    };

    const defaultColDef = useMemo(() => ({
        wrapText: true,
        autoHeight: true,
        autoHeaderHeight: true,
    }), []);


    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onSortChanged = (e) => {
        if (e.source === "uiColumnSorted") {
            console.log(e.api.setSuppressAnimations);
            const sortedColumns = e.api.getColumnState().filter((col) => col.sort);
            console.log(sortedColumns?.[0]?.colId, sortedColumns?.[0]?.sort);
        }
    };

    const onClickExportProfileAutomates = () => {
        exportProfileAutomate({ search: searchText, filter });
    }

    return (
        <div className="card mb-5 mb-xl-10">
            <Loading isLoading={isLoading} />
            <OnHold data={data} />
            <div className="card-body">
                <div className="al-variants-table al-max-height-table-cus">
                    {/* <ReactTable
                        columns={columns}
                        data={list || []}
                        groupButton={groupButton}
                        handleTableChange={handleTableChange}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={totalSize}
                        alClassName="table-layout-inherit"
                        sizePerPageList={sizePerPageList}
                        isSelectRow={true}
                        selectRow={selectRow}
                    /> */}
                    <ReactTableDataGrid
                        tableName="shopConnect"
                        idProperty="_id"
                        columns={columns}
                        rowData={list}
                        defaultColDef={defaultColDef}
                        gridOptions={{
                            reactiveCustomComponents: true,
                        }}
                        paginationPageSize={sizePerPage}
                        totalSize={totalSize}
                        onTableChanged={onTableChanged}
                        emptyText={t("No data!")}
                        rowSelection="multiple"
                        onSelectionChanged={onSelectionChanged}
                        sizePerPageList={sizePerPageList}
                        groupButton={groupButton}
                        onSortChanged={onSortChanged}
                        components={components}
                    />
                </div>
                <EditProfile
                    data={shopSelect}
                    onClose={() => setShopSelect(null)}
                    onSubmit={handleChangeDataProfile}
                />
            </div>
        </div>
    );
};

const mapStateToProps = ({ profileAutomate, team, user }) => ({ profileAutomate, team, user });
const mapDispatchToProps = {
    getProfileAutomate,
    FormatterStatus,
    formatterCreateAt,
    updateProfileAutomate,
    changeProfileAutomate,
    calculatorTotalFilter,
    exportProfileAutomate,
    getStaffs,
    deleteProfileAutomate,
    moveProfileAutomate,
};

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
