import { useState, useEffect } from "react";
import { connect } from "react-redux";
import Loading from "components/Loading";
import { useTranslation } from "react-i18next";
import { ReactTableDataGrid } from "components/Table";
import { getSizePerPageList } from "helper/constant";
import DatetimePicker from "components/elements/dateTimePicker";
import SelectStaff from "components/elements/selectStaff";
import SelectShop from "components/elements/selectShop";
import { getListOrders, syncOrderStatement, getListSyncOrderStatement } from "actions/orderActions";
import ModalStatements from "./modalStatements";
import { formatterCreateAt } from "components/Function";

const OrderStatements = ({ getListOrders, syncOrderStatement, staffs, formatterCreateAt, getListSyncOrderStatement }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSync, setPageSync] = useState(1);
    const [totalSync, setTotalSync] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [filter, setFilter] = useState({});
    const [orderSelect, setOrderSelect] = useState(null);
    const { t } = useTranslation();

    const columns = [
        {
            field: "_id",
            headerName: "",
            sort: true,
            editable: false,
            cellClass: "centered-cell",
            width: 10,
            cellRenderer: ({ rowIndex }) => rowIndex + 1,
        },
        {
            field: "orderId",
            headerName: t("Order Id"),
            cellClass: "centered-cell",
            editable: false,
            sort: true,
            cellRenderer: ({ data: row }) => (
                <>
                    {row.apiOrderId}
                    <button className="btn btn-link text-hover-primary p-0">
                        <i
                            className="ki-duotone ki-eye ms-2"
                            style={{ fontSize: "20px" }}
                            onClick={() => setOrderSelect(row?.orderstatements?.sku_transactions)}
                        >
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                        </i>
                    </button>
                </>
            ),
        },
        {
            field: "create_time",
            headerName: t("Order Create Time"),
            cellClass: "centered-cell",
            editable: false,
            sort: true,
            autoHeight: true,
            cellRenderer: ({ value: cell, data: row, rowIndex }) => formatterCreateAt(row?.orderstatements?.order_create_time),
        },
        {
            field: "revenue_amount",
            headerName: t("Revenue"),
            cellClass: "centered-cell",
            editable: false,
            sort: true,
            autoHeight: true,
            cellRenderer: ({ value: cell, data: row, rowIndex }) => row?.orderstatements?.revenue_amount,
        },
        {
            field: "fee_and_tax_amount",
            headerName: t("Tax Amount"),
            cellClass: "centered-cell",
            editable: false,
            sort: true,
            cellRenderer: ({ value: cell, data: row, rowIndex }) => row?.orderstatements?.fee_and_tax_amount,
        },
        {
            field: "shipping_cost_amount",
            headerName: t("Shipping Cost"),
            cellClass: "centered-cell",
            editable: false,
            sort: true,
            cellRenderer: ({ value: cell, data: row, rowIndex }) => row?.orderstatements?.shipping_cost_amount,
        },
        {
            field: "settlement_amount",
            headerName: t("Settlement Amount"),
            cellClass: "centered-cell",
            editable: false,
            sort: true,
            autoHeight: true,
            cellRenderer: ({ value: cell, data: row, rowIndex }) => row?.orderstatements?.settlement_amount,
        },
        {
            field: "currency",
            headerName: t("Currency"),
            cellClass: "centered-cell",
            editable: false,
            sort: true,
            autoHeight: true,
            cellRenderer: ({ value: cell, data: row, rowIndex }) => row?.orderstatements?.currency,
        },
        {
            field: "total_sku_transition",
            headerName: t("Total Transition"),
            editable: false,
            sort: true,
            autoHeight: true,
            cellRenderer: ({ value: cell, data: row, rowIndex }) => row?.orderstatements?.sku_transactions?.length,
        },
        {
            field: "member",
            headerName: t("Member"),
            editable: false,
            sort: true,
            autoHeight: true,
            cellRenderer: ({ value: cell, data: row, rowIndex }) =>
                staffs?.find((staff) => staff._id === row.createdBy)?.email || "Owner",
        },
    ];

    useEffect(() => {
        if (Object.keys(filter).length) {
            setIsLoading(true);
            getListOrders(
                page,
                sizePerPage,
                searchText,
                "",
                filter.shop || "",
                "DELIVERED,COMPLETED",
                "",
                filter.staff || "",
                filter.dateStart,
                filter.dateEnd
            ).then((data) => {
                if (data) {
                    setList(data?.listorders?.filter((v) => v?.orderstatements) || []);
                    setTotalSize(data?.listorders?.filter((v) => v?.orderstatements)?.length || 0);
                }
                setIsLoading(false);
            });
        }
    }, [getListOrders, page, sizePerPage, searchText, filter]);

    const onTableChanged = ({ page, limit, text }) => {
        if (limit) setSizePerPage(limit);
        if (page) setPage(page);
        if (typeof text === "string") setSearchText(text);
    };

    const groupButton = () => (
        <div className="pb-4 d-flex gap-2 mb-4">
            <DatetimePicker onChange={(data) => setFilter((prev) => ({ ...prev, ...data }))} />
            <SelectStaff onChange={(data) => setFilter((prev) => ({ ...prev, staff: data }))} />
            <SelectShop onChange={(data) => setFilter((prev) => ({ ...prev, shop: data }))} />
        </div>
    );

    const syncOrder = async (dataOrders, page = 0) => {
        if (dataOrders?.length > page) {
            setPageSync(page);
            await syncOrderStatement(dataOrders?.[page].apiOrderId);
            return await syncOrder(dataOrders, ++page);
        } else {
            return;
        }

    };

    const handleSync = async () => {
        setIsLoading(true);
        await getListSyncOrderStatement().then(async (data) => {
            if (data?.listorders.length > 0) {
                setTotalSync(data?.listorders?.length);
                await syncOrder(data?.listorders);
            }
            setIsLoading(false);
        });


    };

    return (
        <div className="card min-w-full">
            <Loading isLoading={isLoading} pageSync={pageSync} totalSync={totalSync} />
            <div className="card-body">
                <div className="mb-4">
                    <button onClick={handleSync} className="btn btn-primary">
                        Sync
                    </button>
                </div>
                <div className="al-variants-table al-max-height-table-cus">
                    <ReactTableDataGrid
                        tableName="mockSystem"
                        idProperty="_id"
                        columns={columns}
                        rowData={list}
                        gridOptions={{
                            rowHeight: 80,
                            reactiveCustomComponents: true,
                        }}
                        paginationPageSize={sizePerPage}
                        totalSize={totalSize}
                        onTableChanged={onTableChanged}
                        emptyText={t("No data!")}
                        rowSelection="multiple"
                        sizePerPageList={getSizePerPageList(totalSize)}
                        groupButton={groupButton}
                    />
                    <ModalStatements
                        isOpen={Boolean(orderSelect)}
                        onClose={() => setOrderSelect(null)}
                        data={orderSelect}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    staffs: state.team?.list || [],
});
const mapDispatchToProps = { getListOrders, syncOrderStatement, formatterCreateAt, getListSyncOrderStatement };

export default connect(mapStateToProps, mapDispatchToProps)(OrderStatements);
