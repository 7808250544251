import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import ReactImageMagnify from 'react-image-magnify';
import { Link } from "react-router-dom";

function ModalUpload({ onSubmit }) {
    const [name, setName] = useState("");
    const [design, setDesign] = useState("");
    const [mockup, setMockup] = useState("");
    const [description, setDescription] = useState("");
    const [sharing, setSharing] = useState("No");
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    const onChange = (e) => {
        if (e.target.name === "name") {
            setName(e.target.value);
        }
        if (e.target.name === "design") {
            setDesign(e.target.value);
        }
        if (e.target.name === "mockup") {
            setMockup(e.target.value);
        }
        if (e.target.name === "description") {
            setDescription(e.target.value);
        }
        if (e.target.name === "sharing") {
            setSharing(e.target.value);
        }
    }

    const onClose = () => {
        setIsOpen(false);
    };

    const handleSubmit = () => {
        onSubmit({ name, design, mockup, description, sharing });
        onClose();
    };

    return (
        <Fragment>
            <button
                className="btn btn-sm btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_create_app"
                id="kt_toolbar_primary_button"
                onClick={() => setIsOpen(true)}
            >
                <i className="ki-outline ki-cloud-download"></i>
                {t("Add")}
            </button>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    onClose();
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Config")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="row mb-5">
                        <div className="col form-group">
                            <label>Name</label>
                            <div className="al-form-input-date">

                                <input type="text" className="form-control w-100" placeholder="0 second" name="name" value={name} onChange={(e) => {
                                    onChange(e);
                                }} />
                            </div>
                        </div>
                        <div className="col form-group">
                            <label>Sharing</label>
                            <div className="al-form-input-date">
                                <select name="sharing" value={sharing} className="form-control w-100" onChange={(e) => {
                                    onChange(e);
                                }}>
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="form-group">
                            <label>Description</label>
                            <div className="al-form-input-date">
                                <textarea type="text" className="form-control w-100" rows={3} placeholder="1 day" name="description" value={description} onChange={(e) => {
                                    onChange(e);
                                }} ></textarea>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col form-group">
                            <label>Design</label>
                            <div className="al-form-input-date">
                                <input type="text" className="form-control w-100" placeholder="0 second" name="design" value={design} onChange={(e) => {
                                    onChange(e);
                                }} />
                                <div className="d-flex flex-column">
                                    <i>Sizes require from 600 to 2000.</i>
                                    <i className="text-danger">We recommend that you use 1000x1000 square images.</i>
                                </div>
                                {design ? <Link to={design} target="_blank">
                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 100, height: 100, src: design }, largeImage: { src: design, width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 } }} />

                                </Link> : <img src={require("components/assets/media/misc/image.png")} alt="" width={100} />}
                            </div>
                        </div>
                        <div className="col form-group">
                            <label>Mockup</label>
                            <div className="al-form-input-date">
                                <input type="text" className="form-control w-100" placeholder="0 second" name="mockup" value={mockup} onChange={(e) => {
                                    onChange(e);
                                }} />
                                {mockup ? <Link to={mockup} target="_blank">
                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 100, height: 100, src: mockup }, largeImage: { src: mockup, width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 } }} />

                                </Link> : <img src={require("components/assets/media/misc/image.png")} alt="" width={100} />}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpload);
