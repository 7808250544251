import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import SelectBox from "components/elements/selectBox";

function ModalUpdateVariant({ onSubmit, disabled, showListPrice }) {
    const [data, setData] = useState({ price: "0", quantity: "0" });
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    const handleSubmit = () => {
        const dataSubmit = { list_price: { amount: data.amount, extValue: data.extValue, type: data.type || "fixed" } };
        if (data.price !== "0" && data.price) dataSubmit.price = data.price;
        if (data.quantity !== "0" && data.quantity) dataSubmit.quantity = data.quantity;
        if (data.seller_sku) dataSubmit.seller_sku = data.seller_sku;
        onSubmit(dataSubmit);
        setIsOpen(false);
    };

    const handleChange = (e) => {
        setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    return (
        <Fragment>
            <button onClick={() => setIsOpen(true)} type="button" className="btn btn-success" disabled={disabled}>
                Edit Variant
            </button>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    setIsOpen(!isOpen);
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Edit Variant")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="form-group">
                        <label>Price</label>
                        <div>
                            <input
                                type="number"
                                onChange={handleChange}
                                className="form-control"
                                placeholder={t("Price")}
                                name="price"
                                value={data?.price || "0"}
                            />
                        </div>
                    </div>
                    <div className="form-group mt-2">
                        <label>Quantity</label>
                        <div>
                            <input
                                type="number"
                                onChange={handleChange}
                                className="form-control"
                                placeholder={t("quantity")}
                                name="quantity"
                                value={data?.quantity || "0"}
                            />
                        </div>
                    </div>
                    <div className="form-group mt-2">
                        <label>Sku</label>
                        <div>
                            <input
                                type="text"
                                onChange={handleChange}
                                className="form-control"
                                placeholder={t("Sku")}
                                name="seller_sku"
                                value={data?.seller_sku || ""}
                            />
                        </div>
                    </div>
                    {showListPrice && (
                        <div className="mt-4">
                            <h3 className="mb-0">List Price</h3>
                            <div className="form-group mb-4">
                                <label>Type</label>
                                <div>
                                    <SelectBox
                                        options={[
                                            { value: "percent", label: "Percent" },
                                            { value: "plus", label: "Plus" },
                                            { value: "fixed", label: "Fixed" },
                                        ]}
                                        className="h-100"
                                        name="type"
                                        value={data?.type || "fixed"}
                                        onChange={(data) => {
                                            setData((prev) => ({
                                                ...prev,
                                                type: data?.value || "fixed",
                                                extValue: "0",
                                            }));
                                        }}
                                    />
                                </div>
                            </div>
                            {["percent", "plus"].includes(data.type) && (
                                <div className="form-group mb-4">
                                    <label>Percent</label>
                                    <div>
                                        <input
                                            type="number"
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder={t("Percent Value")}
                                            name="extValue"
                                            value={data?.extValue || "0"}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="form-group">
                                <label>Amount</label>
                                <div>
                                    <input
                                        type="number"
                                        onChange={handleChange}
                                        className="form-control"
                                        placeholder={t("Price")}
                                        name="amount"
                                        value={data?.amount || "0"}
                                        disabled={["percent", "plus"].includes(data.type)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpdateVariant);
