import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

function ModalUpload({ onSubmit }) {
    const [profile_id, setProfileID] = useState("");
    const [profile_name, setProfileName] = useState("");
    const [profile_email, setProfileEmail] = useState("");
    const [profile_secret, setProfileSecret] = useState("");
    const [list_data, setListData] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    const onChange = (e) => {
        if (e.target.name === "profile_id") {
            setProfileID(e.target.value);
        }
        if (e.target.name === "profile_name") {
            setProfileName(e.target.value);
        }
        if (e.target.name === "profile_email") {
            setProfileEmail(e.target.value);
        }
        if (e.target.name === "profile_secret") {
            setProfileSecret(e.target.value);
        }
        if (e.target.name === "list_data") {
            setListData(e.target.value);
        }
    }

    const onClose = () => {
        setIsOpen(false);
    };

    const handleSubmit = () => {
        onSubmit({ profile_id, profile_name, profile_email, profile_secret, list_data });
        onClose();
    };

    return (
        <Fragment>
            <button
                className="btn btn-danger btn-sm me-2"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_create_app"
                id="kt_toolbar_primary_button"
                onClick={() => setIsOpen(true)}
            >
                <i className="ki-outline ki-cloud-download"></i>
                {t("Add")}
            </button>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    onClose();
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Config")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="row mb-5">
                        <div className="col form-group">
                            <label>ID</label>
                            <div className="al-form-input-date">

                                <input type="text" className="form-control w-100" placeholder="0 second" name="profile_id" value={profile_id} onChange={(e) => {
                                    onChange(e);
                                }} />
                            </div>
                        </div>
                        <div className="col form-group">
                            <label>Name</label>
                            <div className="al-form-input-date">

                                <input type="text" className="form-control w-100" placeholder="0 second" name="profile_name" value={profile_name} onChange={(e) => {
                                    onChange(e);
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col form-group">
                            <label>Email</label>
                            <div className="al-form-input-date">

                                <input type="text" className="form-control w-100" placeholder="1 day" name="profile_email" value={profile_email} onChange={(e) => {
                                    onChange(e);
                                }} />
                            </div>
                        </div>
                        <div className="col form-group">
                            <label>Secret</label>
                            <div className="al-form-input-date">

                                <input type="text" className="form-control w-100" placeholder="1 day" name="profile_secret" value={profile_secret} onChange={(e) => {
                                    onChange(e);
                                }} />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <h4>OR List Name|Email|Secret</h4>
                    <textarea name="list_data" className="form-control w-100" rows={5} value={list_data}
                        placeholder="Name|Email|Secret
Name|Email|Secret" onChange={(e) => {
                            onChange(e);
                        }}></textarea>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpload);
