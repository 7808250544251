import { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { ReactTableDataGrid } from "components/Table";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";

const limit = 10;

const ModalStatements = ({ isOpen, data, onClose }) => {
    const [list, setList] = useState([]);
    const [listAll, setListAll] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (data) {
            setListAll(data);
            setList(data?.slice(0, limit) || []);
        }
    }, [data]);

    const columns = [
        {
            field: "_id",
            headerName: "",
            sort: true,
            editable: false,
            cellClass: "centered-cell",
            width: 10,
            cellRenderer: ({ rowIndex }) => rowIndex + 1,
        },
        {
            field: "sku_id",
            headerName: t("Sku Id"),
            cellClass: "centered-cell",
            editable: false,
            sort: true,
        },
        {
            field: "sku_name",
            headerName: t("Sku Name"),
            cellClass: "centered-cell",
            editable: false,
            sort: true,
            flex: 1,
        },
        {
            field: "product_name",
            headerName: t("Product Name"),
            editable: false,
            sort: true,
            width: 350,
            cellRenderer: ({ value: cell }) => <span>{cell}</span>,
        },
        {
            field: "revenue_amount",
            headerName: t("Revenue Amount"),
            cellClass: "centered-cell",
            editable: false,
            sort: true,
        },
        {
            field: "shipping_cost_amount",
            headerName: t("Shipping Cost Amount"),
            cellClass: "centered-cell",
            editable: false,
            sort: true,
        },
        {
            field: "fee_tax_amount",
            headerName: t("Tax Amount"),
            cellClass: "centered-cell",
            editable: false,
            sort: true,
        },
        {
            field: "settlement_amount",
            headerName: t("Settlement Amount"),
            cellClass: "centered-cell",
            editable: false,
            sort: true,
        },
    ];

    const onTableChanged = ({ page }) => {
        const startIndex = (page - 1) * limit;
        setList(listAll?.slice(startIndex, startIndex + limit) || []);
    };

    return (
        <Fragment>
            <Modal centered={true} fullscreen={true} toggle={onClose} isOpen={!!isOpen}>
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Sku Transitions")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="al-variants-table al-max-height-table-cus">
                        <ReactTableDataGrid
                            idProperty="_id"
                            columns={columns}
                            height={350}
                            rowData={list}
                            gridOptions={{
                                reactiveCustomComponents: true,
                            }}
                            paginationPageSize={limit}
                            totalSize={data?.length || 0}
                            emptyText={"No data!"}
                            rowSelection="multiple"
                            onTableChanged={onTableChanged}
                            isSearchText={false}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t("Close")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(ModalStatements);
