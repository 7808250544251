import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import cx from "classnames";
import Listusers from "./listusers/Listusers";
import Listapps from "./listapps/Listapps";
import Listpayments from "./listpayments/Listpayments";
import Email from "./email/Email";
import Listpackages from "./listpackages/Listpackages";
import Sku from "./sku";
import ListTransition from "./transition";
import Loading from "components/Loading";

function Main(props) {
    const [tab, setTab] = useState("listusers");
    const { t } = useTranslation();
    var { isLoading } = props.user;

    const onClick = (data) => {
        setTab(data);
    };

    return (
        <Fragment>
            <div className="card mb-5 mb-xl-10">
                <div className="card-body pt-0 pb-0">
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                        <li className="nav-item mt-2">
                            <Link
                                className={cx("nav-link text-active-primary ms-0 me-10 py-5", {
                                    active: tab === "listusers",
                                })}
                                to="/admin/manager"
                                onClick={(e) => {
                                    onClick("listusers");
                                }}
                            >
                                {t("List Users")}
                            </Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link
                                className={cx("nav-link text-active-primary ms-0 me-10 py-5", {
                                    active: tab === "listapps",
                                })}
                                to="/admin/manager"
                                onClick={(e) => {
                                    onClick("listapps");
                                }}
                            >
                                {t("List Apps")}
                            </Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link
                                className={cx("nav-link text-active-primary ms-0 me-10 py-5", {
                                    active: tab === "listpackages",
                                })}
                                to="/admin/manager"
                                onClick={(e) => {
                                    onClick("listpackages");
                                }}
                            >
                                {t("List Packages")}
                            </Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link
                                className={cx("nav-link text-active-primary ms-0 me-10 py-5", {
                                    active: tab === "payment",
                                })}
                                to="/admin/manager"
                                onClick={(e) => {
                                    onClick("payment");
                                }}
                            >
                                {t("Payment")}
                            </Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link
                                className={cx("nav-link text-active-primary ms-0 me-10 py-5", {
                                    active: tab === "email",
                                })}
                                to="/admin/manager"
                                onClick={(e) => {
                                    onClick("email");
                                }}
                            >
                                {t("Email")}
                            </Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link
                                className={cx("nav-link text-active-primary ms-0 me-10 py-5", {
                                    active: tab === "transition",
                                })}
                                to="/admin/manager"
                                onClick={(e) => {
                                    onClick("transition");
                                }}
                            >
                                {t("Transition")}
                            </Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link
                                className={cx("nav-link text-active-primary ms-0 me-10 py-5", {
                                    active: tab === "sku",
                                })}
                                to="/admin/manager"
                                onClick={(e) => {
                                    onClick("sku");
                                }}
                            >
                                {t("Admin Sku")}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <Loading isLoading={isLoading} />
            {tab === "listusers" ? (
                <Listusers />
            ) : tab === "listapps" ? (
                <Listapps />
            ) : tab === "listpackages" ? (
                <Listpackages />
            ) : tab === "payment" ? (
                <Listpayments />
            ) : tab === "transition" ? (
                <ListTransition />
            ) : tab === "sku" ? (
                <Sku />
            ) : (
                <Email />
            )}
        </Fragment>
    );
}

Main.propTypes = {};
const mapStateToProps = (state) => ({
    user: state.user,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
