import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactTable } from "components/Table";
import { createProductsByTemplate } from "actions/productActions";
import { searchTikTok } from "actions/spytoolActions";
import Loading from "components/Loading";
import Swal from "sweetalert2";
import { formatterCreateAt, countString } from "components/Function";
import ReactImageMagnify from 'react-image-magnify';
import { ModalChangeText, ModalTemplate } from "./modal";

function Main({ user, countString, formatterCreateAt, searchTikTok, createProductsByTemplate }) {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(100);
    const { t } = useTranslation();
    const [list, setList] = useState([]);
    const [products_selected, setProductsSelected] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [searchProduct, setProduct] = useState("");
    const [searchProxy, setProxy] = useState("");
    var { expired_at } = user.user;

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            editable: false,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "main_images",
            text: t("Images"),
            sort: true,
            editable: false,
            formatter: (cell, row, rowIndex) => <Fragment>
                {cell && <div className="d-flex align-items-center flex-wrap">
                    {cell?.map((o, j) => <div className="">
                        <Link to={row?.link} target="_blank"><ReactImageMagnify {...{ smallImage: { alt: "", width: 100, src: (o) || require("components/assets/media/misc/image.png") }, largeImage: { src: (o) || require("components/assets/media/misc/image.png"), width: 600, }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                        </Link>
                    </div>)}
                </div>}
            </Fragment>,
        },
        {
            dataField: "title",
            text: t("Title"),
            sort: true,
            formatter: (cell, row, rowIndex) => <Fragment>
                <div className="d-flex flex-column">
                    <span className={cell?.length < 140 && cell?.length > 40 ? "text-success" : "text-danger"}>
                        Total Title: {cell?.length} <span className="text-info">{countString(cell)}</span>
                    </span>
                    <div className="h4">
                        {cell} <i className="fa fa-pencil"></i>
                    </div>
                </div>
            </Fragment>,
        },
        {
            dataField: "des",
            text: t("Description"),
            width: 200,
            editable: false,
            formatter: (cell, row, rowIndex) => (
                <Fragment>
                    <div className="d-flex gap-4">
                        <div style={{ height: "auto", maxHeight: "300px", maxWidth: "300px", overflow: "auto" }} dangerouslySetInnerHTML={{ __html: cell }} />
                    </div>
                </Fragment>
            ),
        },
        {
            dataField: "videos",
            text: t("Video"),
            sort: true,
            editable: false,
            formatter: (cell, row, rowIndex) => cell?.map((v, i) => {
                return <Link to={v} target="_blank"><i className="ki-outline ki-screen fs-2 me-3" data-tooltip-id="my-tooltip"
                    data-tooltip-content={`Video ${i + 1}`}></i></Link>
            })
            ,
        },
    ];


    const removeSelect = (arr, value) => {
        var i = 0;
        while (i < arr.length) {
            if (arr[i] === value) {
                arr.splice(i, 1);
            } else {
                ++i;
            }
        }
        return arr;
    };

    const onSelect = (row, isSelect) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp.push(row.id);
        } else {
            products_selected_tmp = removeSelect(products_selected_tmp, row.id);
        }
        var new_arr = JSON.stringify(products_selected_tmp);
        setProductsSelected(JSON.parse(new_arr));
    };

    const onSelectAll = (isSelect, row) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp = list.map((v, i) => v.id);
        } else {
            products_selected_tmp = [];
        }
        setProductsSelected(products_selected_tmp);
    };

    const selectRow = {
        mode: "checkbox",
        clickToSelect: false,
        selected: products_selected,
        onSelect: onSelect,
        onSelectAll: onSelectAll,
        selectionHeaderRenderer: ({ indeterminate, checked, onChange }) => (
            <div className="d-flex">
                <input
                    type="checkbox"
                    ref={(input) => {
                        if (input) {
                            input.indeterminate = indeterminate;
                            input.checked = checked;
                        }
                    }}
                    onChange={onChange}
                />
                <span style={{ marginLeft: '8px' }}>({products_selected.length})</span>
            </div>
        ),
    };

    const groupButton = () => {
        return <div className="mb-5">
            <button onClick={deleteProducts} type="button" className="btn btn-danger btn-sm me-1" disabled={products_selected.length <= 0}>
                {t("Delete Products")}
            </button>
            {
                expired_at - Date.now() >= 0 ?
                    <Fragment>
                        <ModalChangeText onSubmit={handleChangeText} />
                        <ModalTemplate onSubmit={uploadProduct} />
                        {/* <button onClick={onClickExport} className="btn btn-success btn-sm me-1" disabled={products_selected.length <= 0}><i className="ki-outline ki-exit-down"></i>{t("Export")}</button>
                        <CSVLink className="d-none" filename={"etsy_" + formatterCreateAt((new Date()).getTime(), "dmy") + ".csv"} data={products_selected_export}>
                            <span ref={btnRef} />
                        </CSVLink> */}
                    </Fragment>
                    : ""
            }
        </div>;
    };

    const uploadProduct = (templateId, otherImage, numberImage) => {
        createProductsByTemplate({
            templateId,
            data: list.reduce((result, item) => {
                if (products_selected.includes(item.id)) {
                    const itemAdd = { ...item, main_images: item.main_images.slice(0, numberImage), title: item.title, product_sku: item.id };
                    if (otherImage?.length) itemAdd.main_images = [...(itemAdd.main_images || []), ...otherImage];
                    result.push(itemAdd);
                }
                return result;
            }, []),
        });
    };

    const handleChangeText = (data) => {
        setList((prev) => {
            prev = prev.map((item) => {
                if (!products_selected.includes(item.id)) return item;
                let title = item?.title;
                if (data?.textNew && data?.textOld) {
                    title = title?.replace(new RegExp(data?.textOld, "gi"), data?.textNew);
                }

                if (data?.textStart) title = `${data?.textStart} ${title}`;
                if (data?.textEnd) title = `${title} ${data?.textEnd}`;

                return { ...item, title };
            });
            return prev;
        });
    };

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(100);
            setSearchText(searchText);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };

    const deleteProducts = () => {
        setList((prev) => [...prev?.filter((v) => !products_selected.includes(v.id))]);
    };

    const onClickSearch = async (data) => {
        setIsLoading(true)
        await searchTikTok({ links: searchProduct, proxy: searchProxy })
            .then((data) => {
                setList(data)
                setIsLoading(false)
                console.log(data)
                if (data.length > 0) {
                    return Swal.fire(t("Success"), data.length + " Products", "success");
                }
                return Swal.fire(t("Error"), "Failed", "error");
            })
            .catch((err) => {
                return Swal.fire(t("Error"), err, "error");
            });
    };

    const handleTableSaveCell = (oldValue, newValue, row, column) => {
        row[column.dataField] = newValue;
        setList((prev) => {
            const data = prev.map((product) => {
                if (product.id === row.id) {
                    return { ...product, [column.dataField]: newValue };
                }
                return { ...product };
            });
            return [...data];
        });
    };

    const sizePerPageList = [
        {
            text: '10', value: 10
        },
        {
            text: '50', value: 50
        },
        {
            text: '100', value: 100
        },
        {
            text: '150', value: 150
        },
        {
            text: '200', value: 200
        },
        {
            text: 'All', value: list.length || 10
        }
    ];

    return (
        <Fragment>
            <Loading isLoading={isLoading} />
            <div className="d-flex gap-5">
                {
                    expired_at - Date.now() >= 0 ?
                        <Fragment>
                            <div className="d-flex">
                                <div className="row me-5">
                                    <div className="form-group">
                                        <textarea rows={4} cols={50} value={searchProduct} placeholder="Link or ID" onChange={(e) => {
                                            setProduct(e.target.value);
                                        }}>{searchProduct}</textarea>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group">
                                        <textarea rows={4} cols={50} value={searchProxy} placeholder="Proxy example:
                                        ip:port
                                        user:pass@ip:port" onChange={(e) => {
                                                setProxy(e.target.value);
                                            }}>{searchProxy}</textarea>
                                    </div>
                                </div>
                                <div className="row me-5">
                                    <div className="form-group">
                                        <button onClick={onClickSearch} className="btn btn-dark btn-sm me-1"><i className="ki-outline ki-filter-search"></i>{t("Crawl Product")}</button>
                                    </div>
                                </div>

                            </div>
                        </Fragment>
                        : ""
                }
            </div>
            <div className="card mb-5 mb-xl-10">
                <Loading isLoading={isLoading} />
                <div className="card-body">
                    <div className="al-variants-table al-max-height-table-cus">
                        <ReactTable
                            keyField="id"
                            columns={columns}
                            data={list}
                            groupButton={groupButton}
                            handleTableChange={handleTableChange}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={list.length}
                            alClassName="table-layout-inherit"
                            remote={{ search: false, pagination: false, sort: false }}
                            isSelectRow={true}
                            selectRow={selectRow}
                            isCellEdit={true}
                            handleBeforeSaveCell={handleTableSaveCell}
                            sizePerPageList={sizePerPageList}
                        />
                    </div>

                </div>
            </div>

        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    user: state.user
});
const mapDispatchToProps = { createProductsByTemplate, countString, formatterCreateAt, searchTikTok };

export default connect(mapStateToProps, mapDispatchToProps)(Main);
