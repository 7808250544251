import { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";

const EditProfile = ({ data, onClose, onSubmit }) => {
    const [formData, setFormData] = useState({ ...data });
    const { t } = useTranslation();
    useEffect(() => {
        setFormData(data);
    }, [data]);

    const handleChange = (e) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async () => {
        let d = formData;
        onSubmit?.(d);
        onClose?.();
    };

    return (
        <Fragment>
            <Modal centered={true} size="xl" toggle={onClose} isOpen={!!data}>
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Edit Shop")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onClose(); }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="row mb-5" >
                        <div className="col form-group">
                            <label>ID</label>
                            <div className="al-form-input-date">

                                <input type="text" className="form-control w-100" placeholder="0 second" name="profile_id" value={formData?.profile_id} onChange={handleChange} disabled={true} />
                            </div>
                        </div>
                        <div className="col form-group">
                            <label>Name</label>
                            <div className="al-form-input-date">

                                <input type="text" className="form-control w-100" placeholder="0 second" name="profile_name" value={formData?.profile_name} onChange={handleChange} disabled={true} />
                            </div>
                        </div>
                    </div>
                    <div className="row" >
                        <div className="col form-group">
                            <label>Email</label>
                            <div className="al-form-input-date">

                                <input type="text" className="form-control w-100" placeholder="1 day" name="profile_email" value={formData?.profile_email} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="col form-group">
                            <label>Secret</label>
                            <div className="al-form-input-date">
                                <input type="text" className="form-control w-100" placeholder="1 day" name="profile_secret" value={formData?.profile_secret} onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={handleSubmit}  >{t("Change")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(EditProfile);
