import { useState, useEffect } from "react";
import { ReactTableDataGrid } from "components/Table";

const limit = 10;
const TableState = ({ data }) => {
    const [list, setList] = useState([]);

    useEffect(() => {
        if (data) {
            setList(data?.slice(0, limit));
        }
    }, [data]);

    const columns = [
        {
            field: "_id",
            width: 40,
            cellClass: "centered-cell",
            headerName: "",
            cellRenderer: ({ rowIndex, value: cell, data: row }) => rowIndex + 1,
            sortable: false,
        },
        {
            field: "state",
            headerName: "State",
            cellRenderer: ({ rowIndex, value: cell, data: row }) => row._id || "No State",
            sortable: false,
            flex: 1,
        },
        {
            field: "state",
            headerName: "Order Total",
            cellRenderer: ({ rowIndex, value: cell, data: row }) => row.count,
            sortable: false,
        },
    ];

    const onTableChanged = ({ page }) => {
        const startIndex = (page - 1) * limit;
        setList(data.slice(startIndex, startIndex + limit));
    };

    return (
        <ReactTableDataGrid
            idProperty="_id"
            columns={columns}
            height={350}
            rowData={list}
            gridOptions={{
                reactiveCustomComponents: true,
            }}
            paginationPageSize={limit}
            totalSize={data?.length || 0}
            emptyText={"No data!"}
            rowSelection="multiple"
            onTableChanged={onTableChanged}
            isSearchText={false}
        />
    );
};

export default TableState;
