const AppRegions = {
    vi: "VN",
    us: "US",
};

const TeamRole = {
    Staff: "staff",
    Fulfill: "fulfill",
    ShopManager: "shopManager",
};

const ListDateType = [
    { label: "Alltime", value: "" },
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last week", value: "last_week" },
    { label: "Last month", value: "last_month" },
    { label: "Last year", value: "last_year" },
    { label: "This week", value: "this_week" },
    { label: "This month", value: "this_month" },
    { label: "This year", value: "this_year" },
    { label: "Custom", value: "custom" },
];

const CurrencyTiktok = {
    USD: "United States",
    EUR: "France, Germany, Ireland, Italy, Spain (EU)",
    BRL: "Brazil",
    GBP: "United Kingdom",
    IDR: "Indonesia",
    MXN: "Mexico",
    MYR: "Malaysia",
    PHP: "Philippines",
    SGD: "Singapore",
    THB: "Thailand",
    JPY: "Japan",
    VND: "Vietnam",
};

const ShopStatus = [
    { label: "Connected", value: "Connected" },
    { label: "Disconnected", value: "Disconnected" },
    { label: "Delete", value: "Deleted" },
];

const OrderCancelReason = {
    reason: [
        { label: "Out of stock", value: "seller_cancel_unpaid_reason_out_of_stock" },
        { label: "Pricing error", value: "seller_cancel_unpaid_reason_wrong_price" },
        {
            label: "Buyer did not pay on time",
            value: "seller_cancel_unpaid_reason_buyer_hasnt_paid_within_time_allowed",
        },
        { label: "Buyer requested cancellation", value: "seller_cancel_unpaid_reason_buyer_requested_cancellation" },
    ],
    reason1: [
        { label: "Out of stock", value: "seller_cancel_reason_out_of_stock" },
        { label: "Pricing error", value: "seller_cancel_reason_wrong_price" },
        { label: "Unable to deliver to buyer address", value: "seller_cancel_paid_reason_address_not_deliver" },
        { label: "Buyer requested cancellation", value: "seller_cancel_paid_reason_buyer_requested_cancellation" },
    ],
};

const OptionKVFulfill = [
    { label: "3D Shirt As Design size M", value: "3DSHIRT-AS-DESIGN-M", enabled: ["other"] },
    { label: "3D Shirt As Design size 2XL", value: "3DSHIRT-AS-DESIGN-2XL", enabled: ["other"] },
    { label: "3D Shirt As Design size L", value: "3DSHIRT-AS-DESIGN-L", enabled: ["other"] },
    { label: "3D Shirt As Design size S", value: "3DSHIRT-AS-DESIGN-S", enabled: ["other"] },
    { label: "3D Shirt As Design size XL", value: "3DSHIRT-AS-DESIGN-XL", enabled: ["other"] },
    { label: "3D Shirt As Design size 5XL", value: "3DSHIRT-AS-DESIGN-5XL", enabled: ["other"] },
    { label: "3D Shirt As Design size 3XL", value: "3DSHIRT-AS-DESIGN-3XL", enabled: ["other"] },
    { label: "3D Shirt As Design size 4XL", value: "3DSHIRT-AS-DESIGN-4XL", enabled: ["other"] },
    { label: "Hawaiin Shirt XS", value: "HW-SHIRT-AS-DESIGN-XS", enabled: ["other"] },
    { label: "iPhone 11 Pro Max", value: "TC11PRM", enabled: ["other"] },
    { label: "iPhone 12 Mini", value: "TC12mini", enabled: ["other"] },
    { label: "iPhone 14 Pro Max", value: "TC14PRM", enabled: ["other"] },
    { label: "iPhone 13 Pro Max", value: "TC13PRM", enabled: ["other"] },
    { label: "iPhone 14 Pro", value: "TC14PR", enabled: ["other"] },
    { label: "iPhone 14 Plus", value: "TC14PL", enabled: ["other"] },
    { label: "iPhone 15 Pro", value: "TC15PR", enabled: ["other"] },
    { label: "iPhone 15 Plus", value: "TC15PL", enabled: ["other"] },
    { label: "iPhone 15", value: "TC15", enabled: ["other"] },
    { label: "iPhone 12", value: "TC12", enabled: ["other"] },
    { label: "iPhone 13 Mini", value: "TC13mini", enabled: ["other"] },
    { label: "iPhone 13", value: "TC13", enabled: ["other"] },
    { label: "iPhone 14", value: "TC14", enabled: ["other"] },
    { label: "iPhone XS", value: "TCXS", enabled: ["other"] },
    { label: "Hawaiin Shirt S", value: "HW-SHIRT-AS-DESIGN-S", enabled: ["other"] },
    { label: "Hawaiin Shirt M", value: "HW-SHIRT-AS-DESIGN-M", enabled: ["other"] },
    { label: "Hawaiin Shirt L", value: "HW-SHIRT-AS-DESIGN-L", enabled: ["other"] },
    { label: "Hawaiin Shirt XL", value: "HW-SHIRT-AS-DESIGN-XL", enabled: ["other"] },
    { label: "Hawaiin Shirt 2XL", value: "HW-SHIRT-AS-DESIGN-2XL", enabled: ["other"] },
    { label: "Hawaiin Shirt 3XL", value: "HW-SHIRT-AS-DESIGN-3XL", enabled: ["other"] },
    { label: "iPhone 15 Pro Max", value: "TC15PRM", enabled: ["other"] },
    { label: "Hawaiin Shirt 4XL", value: "HW-SHIRT-AS-DESIGN-4XL", enabled: ["other"] },
    { label: "Hawaiin Shirt 5XL", value: "HW-SHIRT-AS-DESIGN-5XL", enabled: ["other"] },
    { label: "US Hawaiin Shorts S", value: "HW-SHORT-ASDES-S", enabled: ["other"] },
    { label: "US Hawaiin Shorts M", value: "HW-SHORT-ASDES-M", enabled: ["other"] },
    { label: "US Hawaiin Shorts L", value: "HW-SHORT-ASDES-L", enabled: ["other"] },
    { label: "US Hawaiin Shorts XL", value: "HW-SHORT-ASDES-XL", enabled: ["other"] },
    { label: "iPhone 12 Pro", value: "TC12PR", enabled: ["other"] },
    { label: "iPhone 13 Pro", value: "TC13PR", enabled: ["other"] },
    { label: "Samsung Galaxy S22 Plus", value: "TCSSGS22PL", enabled: ["other"] },
    { label: "Samsung Galaxy S21 Ultra", value: "TCSSGS21UT", enabled: ["other"] },
    { label: "iPhone XR", value: "TCXR", enabled: ["other"] },
    { label: "Google Pixel 7A", value: "GGPX7A", enabled: ["other"] },
    { label: "Google Pixel 8", value: "GGPX8", enabled: ["other"] },
    { label: "Google Pixel 8 Pro", value: "GGPX8P", enabled: ["other"] },
    { label: "US Hawaiin Shorts 2XL", value: "HW-SHORT-ASDES-2XL", enabled: ["other"] },
    { label: "Airpods Pro ", value: "APPRO", enabled: ["other"] },
    { label: "iPhone 7 Plus", value: "TC7PL", enabled: ["other"] },
    { label: "Airpods Gen 1", value: "AP1", enabled: ["other"] },
    { label: "Airpod Gen 2", value: "AP2", enabled: ["other"] },
    { label: "Airpods Gen 3", value: "AP3", enabled: ["other"] },
    { label: "Samsung Galaxy S22", value: "TCSSGS22", enabled: ["other"] },
    { label: "Samsung Galaxy S23", value: "TCSSGS23", enabled: ["other"] },
    { label: "Samsung Galaxy S21 Plus", value: "TCSSGS21PL", enabled: ["other"] },
    { label: "Airpods Gen 4", value: "AP4", enabled: ["other"] },
    { label: "Samsung Galaxy S22 Ultra", value: "TCSSGS22UT", enabled: ["other"] },
    { label: "Samsung Galaxy S24 Ultra", value: "TCSSGS24UT", enabled: ["other"] },
    { label: "Samsung Galaxy S21", value: "TCSSGS21", enabled: ["other"] },
    { label: "Samsung Galaxy S23 Plus", value: "TCSSGS23PL", enabled: ["other"] },
    { label: "Samsung Galaxy S23 Ultra", value: "TCSSGS23UT", enabled: ["other"] },
    { label: "Airpods Pro 2", value: "APPRO2", enabled: ["other"] },
    { label: "Samsung Galaxy S24 Plus", value: "TCSSGS24PL", enabled: ["other"] },
    { label: "Box 15x20", value: "BOX-1", enabled: ["other"] },
    { label: "US Hawaiin Shorts 3XL", value: "HW-SHORT-ASDES-3XL", enabled: ["other"] },
    { label: "Label paper", value: "LABEL-PAPER", enabled: ["other"] },
    { label: "Bag 15x20", value: "BAG-1", enabled: ["other"] },
    { label: "FulfillVNUS", value: "FulfillVNUS", enabled: ["other"] },
    { label: "iPhone 16", value: "TC16", enabled: ["other"] },
    { label: "iPhone 8", value: "TC8", enabled: ["other"] },
    { label: "iPhone 11", value: "TC11", enabled: ["other"] },
    { label: "iPhone 8 Plus", value: "TC8PL", enabled: ["other"] },
    { label: "iPhone XS MAX", value: "TCXSM", enabled: ["other"] },
    { label: "iPhone 16 Pro", value: "TC16PR", enabled: ["other"] },
    { label: "iPhone X", value: "TCX", enabled: ["other"] },
    { label: "iPhone 16 Pro Max", value: "TC16PRM", enabled: ["other"] },
    { label: "iPhone 16 Plus", value: "TC16PL", enabled: ["other"] },
    { label: "iPhone 11 Pro", value: "TC11PR", enabled: ["other"] },
    { label: "US Hawaiin Shorts 4XL", value: "HW-SHORT-ASDES-4XL", enabled: ["other"] },
    { label: "US Hawaiin Shorts 5XL", value: "HW-SHORT-ASDES-5XL", enabled: ["other"] },
    { label: "Baseball Jersey (Border) XS", value: "P-BBJES-AS-DESIGN-XS", enabled: ["other"] },
    { label: "Baseball Jersey (Border) S", value: "P-BBJES-AS-DESIGN-S", enabled: ["other"] },
    { label: "Baseball Jersey (Border) M", value: "P-BBJES-AS-DESIGN-M", enabled: ["other"] },
    { label: "Baseball Jersey (Border) L", value: "P-BBJES-AS-DESIGN-L", enabled: ["other"] },
    { label: "Baseball Jersey (Border) XL", value: "P-BBJES-AS-DESIGN-XL", enabled: ["other"] },
    { label: "Samsung Galaxy S24", value: "TCSSGS24", enabled: ["other"] },
    { label: "Baseball Jersey (Border) 2XL", value: "P-BBJES-AS-DESIGN-2XL", enabled: ["other"] },
    { label: "Baseball Jersey (Border) 3XL", value: "P-BBJES-AS-DESIGN-3XL", enabled: ["other"] },
    { label: "iPhone SE", value: "TCSE", enabled: ["other"] },
    { label: "iPhone 12 Pro Max", value: "TC12PRM", enabled: ["other"] },
    { label: "iPhone 7", value: "TC7", enabled: ["other"] },
    { label: "Shaped magnet stickers 3inch", value: "SMS3", enabled: ["other"] },
    { label: "Baseball Jersey (Border) 4XL", value: "P-BBJES-AS-DESIGN-4XL", enabled: ["other"] },
    { label: "Baseball Jersey (Border) 5XL", value: "P-BBJES-AS-DESIGN-5XL", enabled: ["other"] },
    { label: "Classic AOP Baseball (Button) XS", value: "C-BBJER-AS-DESIGN-XS", enabled: ["other"] },
    { label: "Classic AOP Baseball (Button) S", value: "C-BBJER-AS-DESIGN-S", enabled: ["other"] },
    { label: "Classic AOP Baseball (Button) M", value: "C-BBJER-AS-DESIGN-M", enabled: ["other"] },
    { label: "Classic AOP Baseball (Button) L", value: "C-BBJER-AS-DESIGN-L", enabled: ["other"] },
    { label: "Classic AOP Baseball (Button) XL", value: "C-BBJER-AS-DESIGN-XL", enabled: ["other"] },
    { label: "Classic AOP Baseball (Button) 2XL", value: "C-BBJER-AS-DESIGN-2XL", enabled: ["other"] },
    { label: "Classic AOP Baseball (Button) 3XL", value: "C-BBJER-AS-DESIGN-3XL", enabled: ["other"] },
    { label: "Classic AOP Baseball (Button) 4XL", value: "C-BBJER-AS-DESIGN-4XL", enabled: ["other"] },
    { label: "Classic AOP Baseball (Button) 5XL", value: "C-BBJER-AS-DESIGN-5XL", enabled: ["other"] },
    { label: "Men Polo Shirt S", value: "PAOPPOLO-SAME-DESIGN-S", enabled: ["other"] },
    { label: "Men Polo Shirt M", value: "PAOPPOLO-SAME-DESIGN-M", enabled: ["other"] },
    { label: "Men Polo Shirt L", value: "PAOPPOLO-SAME-DESIGN-L", enabled: ["other"] },
    { label: "Men Polo Shirt XL", value: "PAOPPOLO-SAME-DESIGN-XL", enabled: ["other"] },
    { label: "Men Polo Shirt 2XL", value: "PAOPPOLO-SAME-DESIGN-2XL", enabled: ["other"] },
    { label: "Men Polo Shirt 3XL", value: "PAOPPOLO-SAME-DESIGN-3XL", enabled: ["other"] },
    { label: "Men Polo Shirt 4XL", value: "PAOPPOLO-SAME-DESIGN-4XL", enabled: ["other"] },
    { label: "Men Polo Shirt 5XL", value: "PAOPPOLO-SAME-DESIGN-5XL", enabled: ["other"] },
    { label: "Women Polo Shirt S", value: "W-PAOPPOLO-SAME-DESIGN-S", enabled: ["other"] },
    { label: "Women Polo Shirt M", value: "W-PAOPPOLO-SAME-DESIGN-M", enabled: ["other"] },
    { label: "Women Polo Shirt L", value: "W-PAOPPOLO-SAME-DESIGN-L", enabled: ["other"] },
    { label: "Women Polo Shirt XL", value: "W-PAOPPOLO-SAME-DESIGN-XL", enabled: ["other"] },
    { label: "Women Polo Shirt 2XL", value: "W-PAOPPOLO-SAME-DESIGN-2XL", enabled: ["other"] },
    { label: "Women Polo Shirt 3XL", value: "W-PAOPPOLO-SAME-DESIGN-3XL", enabled: ["other"] },
    { label: "Women Polo Shirt 4XL", value: "W-PAOPPOLO-SAME-DESIGN-4XL", enabled: ["other"] },
    { label: "Women Polo Shirt 5XL", value: "W-PAOPPOLO-SAME-DESIGN-5XL", enabled: ["other"] },
    { label: "Full printed UNISEX Sweatshirt 230GSM S", value: "PRE-SWSHIRT270-S", enabled: ["other"] },
    { label: "Full printed UNISEX Sweatshirt 230GSM M", value: "PRE-SWSHIRT270-M", enabled: ["other"] },
    { label: "Full printed UNISEX Sweatshirt 230GSM L", value: "PRE-SWSHIRT270-L", enabled: ["other"] },
    { label: "Full printed UNISEX Sweatshirt 230GSM XL", value: "PRE-SWSHIRT270-XL", enabled: ["other"] },
    { label: "Full printed UNISEX Sweatshirt 230GSM 2XL", value: "PRE-SWSHIRT270-2XL", enabled: ["other"] },
    { label: "Full printed UNISEX Sweatshirt 230GSM 3XL", value: "PRE-SWSHIRT270-3XL", enabled: ["other"] },
    { label: "Full printed UNISEX Sweatshirt 230GSM 4XL", value: "PRE-SWSHIRT270-4XL", enabled: ["other"] },
    { label: "Full printed UNISEX Sweatshirt 230GSM 5XL", value: "PRE-SWSHIRT270-5XL", enabled: ["other"] },
    { label: "Full printed UNISEX Premium zip Hoodie S", value: "PRE-ZHOODIE270-S", enabled: ["other"] },
    { label: "Full printed UNISEX Premium zip Hoodie M", value: "PRE-ZHOODIE270-M", enabled: ["other"] },
    { label: "Full printed UNISEX Premium zip Hoodie L", value: "PRE-ZHOODIE270-L", enabled: ["other"] },
    { label: "Full printed UNISEX Premium zip Hoodie XL", value: "PRE-ZHOODIE270-XL", enabled: ["other"] },
    { label: "Full printed UNISEX Premium zip Hoodie 2XL", value: "PRE-ZHOODIE270-2XL", enabled: ["other"] },
    { label: "Full printed UNISEX Premium zip Hoodie 3XL", value: "PRE-ZHOODIE270-3XL", enabled: ["other"] },
    { label: "Full printed UNISEX Premium zip Hoodie 4XL", value: "PRE-ZHOODIE270-4XL", enabled: ["other"] },
    { label: "Full printed UNISEX Premium zip Hoodie 5XL", value: "PRE-ZHOODIE270-5XL", enabled: ["other"] },
    { label: "Premium Hoodie 230GSM S", value: "AOPHOODIE-SAME-DESIGN-S", enabled: ["other"] },
    { label: "Premium Hoodie 230GSM M", value: "AOPHOODIE-SAME-DESIGN-M", enabled: ["other"] },
    { label: "Premium Hoodie 230GSM L", value: "AOPHOODIE-SAME-DESIGN-L", enabled: ["other"] },
    { label: "Premium Hoodie 230GSM XL", value: "AOPHOODIE-SAME-DESIGN-XL", enabled: ["other"] },
    { label: "Premium Hoodie 230GSM 2XL", value: "AOPHOODIE-SAME-DESIGN-2XL", enabled: ["other"] },
    { label: "Premium Hoodie 230GSM 3XL", value: "AOPHOODIE-SAME-DESIGN-3XL", enabled: ["other"] },
    { label: "Premium Hoodie 230GSM 4XL", value: "AOPHOODIE-SAME-DESIGN-4XL", enabled: ["other"] },
    { label: "Premium Hoodie 230GSM 5XL", value: "AOPHOODIE-SAME-DESIGN-5XL", enabled: ["other"] },
    { label: "Pillow cover 16X16", value: "PILLOWCASE-ASDES-16X16", enabled: ["other"] },
    { label: "Pillow cover 18X18", value: "PILLOWCASE-ASDES-18X18", enabled: ["other"] },
    { label: "Pillow cover 20X20", value: "PILLOWCASE-ASDES-20X20", enabled: ["other"] },
    { label: "Pillow cover 14X20", value: "PILLOWCASE-ASDES-14X20", enabled: ["other"] },
    { label: "Pillow cover 16X20", value: "PILLOWCASE-ASDES-16X24", enabled: ["other"] },
    { label: "Pillow cover 20X30", value: "PILLOWCASE-ASDES-20X30", enabled: ["other"] },
    { label: "Canvas and doubles sides printed Flag 12X18", value: "US-CANVAS-FLAG-12X18", enabled: ["other"] },
    { label: "Canvas and doubles sides printed Flag 28X40", value: "US-CANVAS-FLAG-28X40\t", enabled: ["other"] },
    { label: "Shaped magnet stickers 5inch", value: "SMS5", enabled: ["other"] },
    { label: "Shaped magnet stickers 6inch", value: "SMS6", enabled: ["other"] },
    { label: "Shaped magnet stickers 4inch", value: "SMS4", enabled: ["other"] },
    { label: "Shaped magnet stickers FULL", value: "SMSF", enabled: ["other"] },
    { label: "Shaped magnet stickers 7inch", value: "SMS7", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - PEPPER - S", value: "UT-1717-PER-S", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - PEPPER - M", value: "UT-1717-PER-M", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - PEPPER - L", value: "UT-1717-PER-L", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - PEPPER - XL", value: "UT-1717-PER-XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - PEPPER - 2XL", value: "UT-1717-PER-2XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - PEPPER - 3XL", value: "UT-1717-PER-3XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - BLACK - S", value: "UT-1717-BLK-S", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - BLACK - M", value: "UT-1717-BLK-M", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - BLACK - L", value: "UT-1717-BLK-L", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - BLACK - XL", value: "UT-1717-BLK-XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - BLACK - 2XL", value: "UT-1717-BLK-2XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - BLACK - 3XL", value: "UT-1717-BLK-3XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - IVORY - S", value: "UT-1717-IVY-S", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - IVORY - M", value: "UT-1717-IVY-M", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - IVORY - L", value: "UT-1717-IVY-L", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - IVORY - XL", value: "UT-1717-IVY-XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - IVORY - 2XL", value: "UT-1717-IVY-2XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - IVORY - 3XL", value: "UT-1717-IVY-3XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - ORCHID - S", value: "UT-1717-ORD-S", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - ORCHID - M", value: "UT-1717-ORD-M", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - ORCHID - L", value: "UT-1717-ORD-L", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - ORCHID - XL", value: "UT-1717-ORD-XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - ORCHID - 2XL", value: "UT-1717-ORD-2XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - WHITE - S", value: "UT-1717-WHE-S", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - WHITE - M", value: "UT-1717-WHE-M", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - WHITE - L", value: "UT-1717-WHE-L", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - WHITE - XL", value: "UT-1717-WHE-XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - WHITE - 2XL", value: "UT-1717-WHE-2XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - YAM - S", value: "UT-1717-YAM-S", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - YAM - M", value: "UT-1717-YAM-M", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - YAM - L", value: "UT-1717-YAM-L", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - YAM - XL", value: "UT-1717-YAM-XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - YAM - 2XL", value: "UT-1717-YAM-2XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - NAVY - S", value: "UT-1717-NAY-S", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - NAVY - M", value: "UT-1717-NAY-M", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - NAVY - L", value: "UT-1717-NAY-L", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - NAVY - XL", value: "UT-1717-NAY-XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - NAVY - 2XL", value: "UT-1717-NAY-2XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - MOSS - S", value: "UT-1717-MOS-S", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - MOSS - M", value: "UT-1717-MOS-M", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - MOSS - L", value: "UT-1717-MOS-L", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - MOSS - XL", value: "UT-1717-MOS-XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - MOSS - 2XL", value: "UT-1717-MOS-2XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - LIGHT GREEN - S", value: "UT-1717-LIN-S", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - LIGHT GREEN - M", value: "UT-1717-LIN-M", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - LIGHT GREEN - L", value: "UT-1717-LIN-L", enabled: ["other"] },
    {
        label: "Garment-Dyed Heavyweight T-Shirt - 1717 - LIGHT GREEN - XL",
        value: "UT-1717-LIN-XL",
        enabled: ["other"],
    },
    {
        label: "Garment-Dyed Heavyweight T-Shirt - 1717 - LIGHT GREEN - 2XL",
        value: "UT-1717-LIN-2XL",
        enabled: ["other"],
    },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - GRAPHITE - S", value: "UT-1717-GRE-S", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - GRAPHITE - M", value: "UT-1717-GRE-M", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - GRAPHITE - L", value: "UT-1717-GRE-L", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - GRAPHITE - XL", value: "UT-1717-GRE-XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - GRAPHITE - 2XL", value: "UT-1717-GRE-2XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - BLUE JEAN - S", value: "UT-1717-BLN-S", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - BLUE JEAN - M", value: "UT-1717-BLN-M", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - BLUE JEAN - L", value: "UT-1717-BLN-L", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - BLUE JEAN - XL", value: "UT-1717-BLN-XL", enabled: ["other"] },
    {
        label: "Garment-Dyed Heavyweight T-Shirt - 1717 - BLUE JEAN - 2XL",
        value: "UT-1717-BLN-2XL",
        enabled: ["other"],
    },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - BLOSSOM - S", value: "UT-1717-BLM-S", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - BLOSSOM - M", value: "UT-1717-BLM-M", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - BLOSSOM - L", value: "UT-1717-BLM-L", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - BLOSSOM - XL", value: "UT-1717-BLM-XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - BLOSSOM - 2XL", value: "UT-1717-BLM-2XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - MUSTARD - S", value: "UT-1717-MUD-S", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - MUSTARD - M", value: "UT-1717-MUD-M", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - MUSTARD - L", value: "UT-1717-MUD-L", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - MUSTARD - XL", value: "UT-1717-MUD-XL", enabled: ["other"] },
    { label: "Garment-Dyed Heavyweight T-Shirt - 1717 - MUSTARD - 2XL", value: "UT-1717-MUD-2XL", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - BLACK - S", value: "SW-18000-BLK-S", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - BLACK - M", value: "SW-18000-BLK-M", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - BLACK - L", value: "SW-18000-BLK-L", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - BLACK - XL", value: "SW-18000-BLK-XL", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - BLACK - 2XL", value: "SW-18000-BLK-2XL", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - BLACK - 3XL", value: "SW-18000-BLK-3XL", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - WHITE - S", value: "SW-18000-WHE-S", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - WHITE - M", value: "SW-18000-WHE-M", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - WHITE - L", value: "SW-18000-WHE-L", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - WHITE - XL", value: "SW-18000-WHE-XL", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - WHITE - 2XL", value: "SW-18000-WHE-2XL", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - SAND - S", value: "SW-18000-SAD-S", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - SAND - M", value: "SW-18000-SAD-M", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - SAND - L", value: "SW-18000-SAD-L", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - SAND - XL", value: "SW-18000-SAD-XL", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - SAND - 2XL", value: "SW-18000-SAD-2XL", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - SPORT GREY - S", value: "SW-18000-SPY-S", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - SPORT GREY - M", value: "SW-18000-SPY-M", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - SPORT GREY - L", value: "SW-18000-SPY-L", enabled: ["other"] },
    {
        label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - SPORT GREY - XL",
        value: "SW-18000-SPY-XL",
        enabled: ["other"],
    },
    {
        label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - SPORT GREY - 2XL",
        value: "SW-18000-SPY-2XL",
        enabled: ["other"],
    },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - ORANGE - S", value: "SW-18000-ORE-S", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - ORANGE - M", value: "SW-18000-ORE-M", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - ORANGE - L", value: "SW-18000-ORE-L", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - ORANGE - XL", value: "SW-18000-ORE-XL", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - ORANGE - 2XL", value: "SW-18000-ORE-2XL", enabled: ["other"] },
    {
        label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - MILITARY GREEN - S",
        value: "SW-18000-MIN-S",
        enabled: ["other"],
    },
    {
        label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - MILITARY GREEN - M",
        value: "SW-18000-MIN-M",
        enabled: ["other"],
    },
    {
        label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - MILITARY GREEN - L",
        value: "SW-18000-MIN-L",
        enabled: ["other"],
    },
    {
        label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - MILITARY GREEN - XL",
        value: "SW-18000-MIN-XL",
        enabled: ["other"],
    },
    {
        label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - MILITARY GREEN - 2XL",
        value: "SW-18000-MIN-2XL",
        enabled: ["other"],
    },
    {
        label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - FOREST GREEN - S",
        value: "SW-18000-FON-S",
        enabled: ["other"],
    },
    {
        label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - FOREST GREEN - M",
        value: "SW-18000-FON-M",
        enabled: ["other"],
    },
    {
        label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - FOREST GREEN - L",
        value: "SW-18000-FON-L",
        enabled: ["other"],
    },
    {
        label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - FOREST GREEN - XL",
        value: "SW-18000-FON-XL",
        enabled: ["other"],
    },
    {
        label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - FOREST GREEN - 2XL",
        value: "SW-18000-FON-2XL",
        enabled: ["other"],
    },
    {
        label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - DARK HEATHER - S",
        value: "SW-18000-DAR-S",
        enabled: ["other"],
    },
    {
        label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - DARK HEATHER - M",
        value: "SW-18000-DAR-M",
        enabled: ["other"],
    },
    {
        label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - DARK HEATHER - L",
        value: "SW-18000-DAR-L",
        enabled: ["other"],
    },
    {
        label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - DARK HEATHER - XL",
        value: "SW-18000-DAR-XL",
        enabled: ["other"],
    },
    {
        label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - DARK HEATHER - 2XL",
        value: "SW-18000-DAR-2XL",
        enabled: ["other"],
    },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - NAVY - S", value: "SW-18000-NAY-S", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - NAVY - M", value: "SW-18000-NAY-M", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - NAVY - L", value: "SW-18000-NAY-L", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - NAVY - XL", value: "SW-18000-NAY-XL", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - NAVY - 2XL", value: "SW-18000-NAY-2XL", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - MAROON - S", value: "SW-18000-MAN-S", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - MAROON - M", value: "SW-18000-MAN-M", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - MAROON - L", value: "SW-18000-MAN-L", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - MAROON - XL", value: "SW-18000-MAN-XL", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - MAROON - 2XL", value: "SW-18000-MAN-2XL", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - CHARCOAL - S", value: "SW-18000-CHL-S", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - CHARCOAL - M", value: "SW-18000-CHL-M", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - CHARCOAL - L", value: "SW-18000-CHL-L", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - CHARCOAL - XL", value: "SW-18000-CHL-XL", enabled: ["other"] },
    {
        label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - CHARCOAL - 2XL",
        value: "SW-18000-CHL-2XL",
        enabled: ["other"],
    },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - LIGHT BLUE - S", value: "SW-18000-LIE-S", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - LIGHT BLUE - M", value: "SW-18000-LIE-M", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - LIGHT BLUE - L", value: "SW-18000-LIE-L", enabled: ["other"] },
    {
        label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - LIGHT BLUE - XL",
        value: "SW-18000-LIE-XL",
        enabled: ["other"],
    },
    {
        label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - LIGHT BLUE - 2XL",
        value: "SW-18000-LIE-2XL",
        enabled: ["other"],
    },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - RED - M", value: "SW-18000-RED-M", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - RED - L", value: "SW-18000-RED-L", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - RED - XL", value: "SW-18000-RED-XL", enabled: ["other"] },
    { label: "Heavy Blend™ Crewneck Sweatshirt - 18000 - RED - 2XL", value: "SW-18000-RED-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - BLACK - S", value: "UT-5000-BLK-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - BLACK - M", value: "UT-5000-BLK-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - BLACK - L", value: "UT-5000-BLK-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - BLACK - XL", value: "UT-5000-BLK-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - BLACK - 2XL", value: "UT-5000-BLK-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - BLACK - 3XL", value: "UT-5000-BLK-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - BLACK - 4XL", value: "UT-5000-BLK-4XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - BLACK - 5XL", value: "UT-5000-BLK-5XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - SAND - S", value: "UT-5000-SAD-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - SAND - M", value: "UT-5000-SAD-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - SAND - L", value: "UT-5000-SAD-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - SAND - XL", value: "UT-5000-SAD-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - SAND - 2XL", value: "UT-5000-SAD-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - SAND - 3XL", value: "UT-5000-SAD-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - WHITE - S", value: "UT-5000-WHE-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - WHITE - M", value: "UT-5000-WHE-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - WHITE - L", value: "UT-5000-WHE-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - WHITE - XL", value: "UT-5000-WHE-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - WHITE - 2XL", value: "UT-5000-WHE-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - WHITE - 3XL", value: "UT-5000-WHE-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - WHITE - 4XL", value: "UT-5000-WHE-4XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - WHITE - 5XL", value: "UT-5000-WHE-5XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - SPORT GREY - S", value: "UT-5000-SPY-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - SPORT GREY - M", value: "UT-5000-SPY-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - SPORT GREY - L", value: "UT-5000-SPY-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - SPORT GREY - XL", value: "UT-5000-SPY-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - SPORT GREY - 2XL", value: "UT-5000-SPY-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - SPORT GREY - 3XL", value: "UT-5000-SPY-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - DARK HEATHER - S", value: "UT-5000-DAR-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - DARK HEATHER - M", value: "UT-5000-DAR-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - DARK HEATHER - L", value: "UT-5000-DAR-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - DARK HEATHER - XL", value: "UT-5000-DAR-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - DARK HEATHER - 2XL", value: "UT-5000-DAR-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - DARK HEATHER - 3XL", value: "UT-5000-DAR-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - FOREST GREEN - S", value: "UT-5000-FON-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - FOREST GREEN - M", value: "UT-5000-FON-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - FOREST GREEN - L", value: "UT-5000-FON-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - FOREST GREEN - XL", value: "UT-5000-FON-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - FOREST GREEN - 2XL", value: "UT-5000-FON-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - FOREST GREEN - 3XL", value: "UT-5000-FON-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - LIGHT BLUE - S", value: "UT-5000-LIE-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - LIGHT BLUE - M", value: "UT-5000-LIE-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - LIGHT BLUE - L", value: "UT-5000-LIE-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - LIGHT BLUE - XL", value: "UT-5000-LIE-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - LIGHT BLUE - 2XL", value: "UT-5000-LIE-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - LIGHT BLUE - 3XL", value: "UT-5000-LIE-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - LIGHT PINK - S", value: "UT-5000-LIK-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - LIGHT PINK - M", value: "UT-5000-LIK-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - LIGHT PINK - L", value: "UT-5000-LIK-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - LIGHT PINK - XL", value: "UT-5000-LIK-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - LIGHT PINK - 2XL", value: "UT-5000-LIK-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - LIGHT PINK - 3XL", value: "UT-5000-LIK-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - ROYAL - S", value: "UT-5000-ROL-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - ROYAL - M", value: "UT-5000-ROL-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - ROYAL - L", value: "UT-5000-ROL-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - ROYAL - XL", value: "UT-5000-ROL-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - ROYAL - 2XL", value: "UT-5000-ROL-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - ROYAL - 3XL", value: "UT-5000-ROL-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - RED - S", value: "UT-5000-RED-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - RED - M", value: "UT-5000-RED-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - RED - L", value: "UT-5000-RED-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - RED - XL", value: "UT-5000-RED-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - RED - 2XL", value: "UT-5000-RED-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - RED - 3XL", value: "UT-5000-RED-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - PURPLE - S", value: "UT-5000-PUE-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - PURPLE - M", value: "UT-5000-PUE-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - PURPLE - L", value: "UT-5000-PUE-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - PURPLE - XL", value: "UT-5000-PUE-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - PURPLE - 2XL", value: "UT-5000-PUE-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - PURPLE - 3XL", value: "UT-5000-PUE-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - NAVY - S", value: "UT-5000-NAY-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - NAVY - M", value: "UT-5000-NAY-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - NAVY - L", value: "UT-5000-NAY-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - NAVY - XL", value: "UT-5000-NAY-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - NAVY - 2XL", value: "UT-5000-NAY-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - NAVY - 3XL", value: "UT-5000-NAY-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - MAROON - S", value: "UT-5000-MAN-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - MAROON - M", value: "UT-5000-MAN-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - MAROON - L", value: "UT-5000-MAN-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - MAROON - XL", value: "UT-5000-MAN-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - MAROON - 2XL", value: "UT-5000-MAN-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - MAROON - 3XL", value: "UT-5000-MAN-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - CHARCOAL - S", value: "UT-5000-CHL-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - CHARCOAL - M", value: "UT-5000-CHL-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - CHARCOAL - L", value: "UT-5000-CHL-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - CHARCOAL - XL", value: "UT-5000-CHL-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - CHARCOAL - 2XL", value: "UT-5000-CHL-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - CHARCOAL - 3XL", value: "UT-5000-CHL-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - ASH - S", value: "UT-5000-ASH-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - ASH - M", value: "UT-5000-ASH-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - ASH - L", value: "UT-5000-ASH-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - ASH - XL", value: "UT-5000-ASH-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - ASH - 2XL", value: "UT-5000-ASH-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - ASH - 3XL", value: "UT-5000-ASH-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - DAISY - S", value: "UT-5000-DAY-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - DAISY - M", value: "UT-5000-DAY-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - DAISY - L", value: "UT-5000-DAY-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - DAISY - XL", value: "UT-5000-DAY-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - DAISY - 2XL", value: "UT-5000-DAY-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - DAISY - 3XL", value: "UT-5000-DAY-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - TENNESSEE ORANGE - S", value: "UT-5000-TEE-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - TENNESSEE ORANGE - M", value: "UT-5000-TEE-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - TENNESSEE ORANGE - L", value: "UT-5000-TEE-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - TENNESSEE ORANGE - XL", value: "UT-5000-TEE-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - TENNESSEE ORANGE - 2XL", value: "UT-5000-TEE-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - TENNESSEE ORANGE - 3XL", value: "UT-5000-TEE-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - ORANGE - S", value: "UT-5000-ORE-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - ORANGE - M", value: "UT-5000-ORE-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - ORANGE - L", value: "UT-5000-ORE-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - ORANGE - XL", value: "UT-5000-ORE-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - ORANGE - 2XL", value: "UT-5000-ORE-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - ORANGE - 3XL", value: "UT-5000-ORE-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - NATURAL - S", value: "UT-5000-NAL-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - NATURAL - M", value: "UT-5000-NAL-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - NATURAL - L", value: "UT-5000-NAL-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - NATURAL - XL", value: "UT-5000-NAL-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - NATURAL - 2XL", value: "UT-5000-NAL-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - NATURAL - 3XL", value: "UT-5000-NAL-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - DARK CHOCOLATE - S", value: "UT-5000-DAE-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - DARK CHOCOLATE - M", value: "UT-5000-DAE-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - DARK CHOCOLATE - L", value: "UT-5000-DAE-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - DARK CHOCOLATE - XL", value: "UT-5000-DAE-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - DARK CHOCOLATE - 2XL", value: "UT-5000-DAE-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - DARK CHOCOLATE - 3XL", value: "UT-5000-DAE-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - HELICONIA - S", value: "UT-5000-HEA-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - HELICONIA - M", value: "UT-5000-HEA-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - HELICONIA - L", value: "UT-5000-HEA-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - HELICONIA - XL", value: "UT-5000-HEA-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - HELICONIA - 2XL", value: "UT-5000-HEA-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - HELICONIA - 3XL", value: "UT-5000-HEA-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - MILITARY GREEN - S", value: "UT-5000-MIN-S", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - MILITARY GREEN - M", value: "UT-5000-MIN-M", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - MILITARY GREEN - L", value: "UT-5000-MIN-L", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - MILITARY GREEN - XL", value: "UT-5000-MIN-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - MILITARY GREEN - 2XL", value: "UT-5000-MIN-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ T-Shirt - 5000 - MILITARY GREEN - 3XL", value: "UT-5000-MIN-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - BLACK - S", value: "YT-5000B-BLK-S", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - BLACK - M", value: "YT-5000B-BLK-M", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - BLACK - L", value: "YT-5000B-BLK-L", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - BLACK - XL", value: "YT-5000B-BLK-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - WHITE - S", value: "YT-5000B-WHE-S", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - WHITE - M", value: "YT-5000B-WHE-M", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - WHITE - L", value: "YT-5000B-WHE-L", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - WHITE - XL", value: "YT-5000B-WHE-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - SAND - S", value: "YT-5000B-SAD-S", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - SAND - M", value: "YT-5000B-SAD-M", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - SAND - L", value: "YT-5000B-SAD-L", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - SAND - XL", value: "YT-5000B-SAD-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - RED - S", value: "YT-5000B-RED-S", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - RED - M", value: "YT-5000B-RED-M", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - RED - L", value: "YT-5000B-RED-L", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - RED - XL", value: "YT-5000B-RED-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - SPORT GREY - S", value: "YT-5000B-SPY-S", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - SPORT GREY - M", value: "YT-5000B-SPY-M", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - SPORT GREY - L", value: "YT-5000B-SPY-L", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - SPORT GREY - XL", value: "YT-5000B-SPY-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - DARK HEATHER - S", value: "YT-5000B-DAR-S", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - DARK HEATHER - M", value: "YT-5000B-DAR-M", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - DARK HEATHER - L", value: "YT-5000B-DAR-L", enabled: ["other"] },
    { label: "Heavy Cotton™ Youth T-Shirt - 5000B - DARK HEATHER - XL", value: "YT-5000B-DAR-XL", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - BLACK - S", value: "YT-3001Y-BLK-S", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - BLACK - M", value: "YT-3001Y-BLK-M", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - BLACK - L", value: "YT-3001Y-BLK-L", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - BLACK - XL", value: "YT-3001Y-BLK-XL", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - WHITE - S", value: "YT-3001Y-WHE-S", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - WHITE - M", value: "YT-3001Y-WHE-M", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - WHITE - L", value: "YT-3001Y-WHE-L", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - WHITE - XL", value: "YT-3001Y-WHE-XL", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - NATURAL - S", value: "YT-3001Y-NAL-S", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - NATURAL - M", value: "YT-3001Y-NAL-M", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - NATURAL - L", value: "YT-3001Y-NAL-L", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - NATURAL - XL", value: "YT-3001Y-NAL-XL", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - TRUE ROYAL - S", value: "YT-3001Y-TRL-S", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - TRUE ROYAL - M", value: "YT-3001Y-TRL-M", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - TRUE ROYAL - L", value: "YT-3001Y-TRL-L", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - TRUE ROYAL - XL", value: "YT-3001Y-TRL-XL", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - DARK GREY - S", value: "YT-3001Y-DAY-S", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - DARK GREY - M", value: "YT-3001Y-DAY-M", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - DARK GREY - L", value: "YT-3001Y-DAY-L", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - DARK GREY - XL", value: "YT-3001Y-DAY-XL", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - PINK - S", value: "YT-3001Y-PIK-S", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - PINK - M", value: "YT-3001Y-PIK-M", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - PINK - L", value: "YT-3001Y-PIK-L", enabled: ["other"] },
    { label: "Youth Jersey Tee - 3001Y - PINK - XL", value: "YT-3001Y-PIK-XL", enabled: ["other"] },
    { label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - BLACK - S", value: "HG-18500-BLK-S", enabled: ["other"] },
    { label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - BLACK - M", value: "HG-18500-BLK-M", enabled: ["other"] },
    { label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - BLACK - L", value: "HG-18500-BLK-L", enabled: ["other"] },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - BLACK - XL",
        value: "HG-18500-BLK-XL",
        enabled: ["other"],
    },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - BLACK - 2XL",
        value: "HG-18500-BLK-2XL",
        enabled: ["other"],
    },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - BLACK - 3XL",
        value: "HG-18500-BLK-3XL",
        enabled: ["other"],
    },
    { label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - WHITE - S", value: "HG-18500-WHE-S", enabled: ["other"] },
    { label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - WHITE - M", value: "HG-18500-WHE-M", enabled: ["other"] },
    { label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - WHITE - L", value: "HG-18500-WHE-L", enabled: ["other"] },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - WHITE - XL",
        value: "HG-18500-WHE-XL",
        enabled: ["other"],
    },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - WHITE - 2XL",
        value: "HG-18500-WHE-2XL",
        enabled: ["other"],
    },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - WHITE - 3XL",
        value: "HG-18500-WHE-3XL",
        enabled: ["other"],
    },
    { label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - SAND - S", value: "HG-18500-SAD-S", enabled: ["other"] },
    { label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - SAND - M", value: "HG-18500-SAD-M", enabled: ["other"] },
    { label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - SAND - L", value: "HG-18500-SAD-L", enabled: ["other"] },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - SAND - XL",
        value: "HG-18500-SAD-XL",
        enabled: ["other"],
    },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - SAND - 2XL",
        value: "HG-18500-SAD-2XL",
        enabled: ["other"],
    },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - SAND - 3XL",
        value: "HG-18500-SAD-3XL",
        enabled: ["other"],
    },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - DARK HEATHER - S",
        value: "HG-18500-DAR-S",
        enabled: ["other"],
    },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - DARK HEATHER - M",
        value: "HG-18500-DAR-M",
        enabled: ["other"],
    },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - DARK HEATHER - L",
        value: "HG-18500-DAR-L",
        enabled: ["other"],
    },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - DARK HEATHER - XL",
        value: "HG-18500-DAR-XL",
        enabled: ["other"],
    },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - DARK HEATHER - 2XL",
        value: "HG-18500-DAR-2XL",
        enabled: ["other"],
    },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - DARK HEATHER - 3XL",
        value: "HG-18500-DAR-3XL",
        enabled: ["other"],
    },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - SPORT GREY - S",
        value: "HG-18500-SPY-S",
        enabled: ["other"],
    },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - SPORT GREY - M",
        value: "HG-18500-SPY-M",
        enabled: ["other"],
    },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - SPORT GREY - L",
        value: "HG-18500-SPY-L",
        enabled: ["other"],
    },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - SPORT GREY - XL",
        value: "HG-18500-SPY-XL",
        enabled: ["other"],
    },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - SPORT GREY - 2XL",
        value: "HG-18500-SPY-2XL",
        enabled: ["other"],
    },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - SPORT GREY - 3XL",
        value: "HG-18500-SPY-3XL",
        enabled: ["other"],
    },
    { label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - NAVY - S", value: "HG-18500-NAY-S", enabled: ["other"] },
    { label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - NAVY - M", value: "HG-18500-NAY-M", enabled: ["other"] },
    { label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - NAVY - L", value: "HG-18500-NAY-L", enabled: ["other"] },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - NAVY - XL",
        value: "HG-18500-NAY-XL",
        enabled: ["other"],
    },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - NAVY - 2XL",
        value: "HG-18500-NAY-2XL",
        enabled: ["other"],
    },
    {
        label: "Unisex Heavy Blend™ Hooded Sweatshirt - 18500 - NAVY - 3XL",
        value: "HG-18500-NAY-3XL",
        enabled: ["other"],
    },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - BLACK - S", value: "UT-3001-BLK-S", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - BLACK - M", value: "UT-3001-BLK-M", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - BLACK - L", value: "UT-3001-BLK-L", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - BLACK - XL", value: "UT-3001-BLK-XL", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - BLACK - 2XL", value: "UT-3001-BLK-2XL", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - WHITE - S", value: "UT-3001-WHE-S", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - WHITE - M", value: "UT-3001-WHE-M", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - WHITE - L", value: "UT-3001-WHE-L", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - WHITE - XL", value: "UT-3001-WHE-XL", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - WHITE - 2XL", value: "UT-3001-WHE-2XL", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - NATURAL - S", value: "UT-3001-NAL-S", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - NATURAL - M", value: "UT-3001-NAL-M", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - NATURAL - L", value: "UT-3001-NAL-L", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - NATURAL - XL", value: "UT-3001-NAL-XL", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - NATURAL - 2XL", value: "UT-3001-NAL-2XL", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - NAVY - S", value: "UT-3001-NAY-S", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - NAVY - M", value: "UT-3001-NAY-M", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - NAVY - L", value: "UT-3001-NAY-L", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - NAVY - XL", value: "UT-3001-NAY-XL", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - NAVY - 2XL", value: "UT-3001-NAY-2XL", enabled: ["other"] },
    {
        label: "Unisex Jersey Short Sleeve Tee - 3001 - DARK GREY HEATHER - S",
        value: "UT-3001-DAR-S",
        enabled: ["other"],
    },
    {
        label: "Unisex Jersey Short Sleeve Tee - 3001 - DARK GREY HEATHER - M",
        value: "UT-3001-DAR-M",
        enabled: ["other"],
    },
    {
        label: "Unisex Jersey Short Sleeve Tee - 3001 - DARK GREY HEATHER - L",
        value: "UT-3001-DAR-L",
        enabled: ["other"],
    },
    {
        label: "Unisex Jersey Short Sleeve Tee - 3001 - DARK GREY HEATHER - XL",
        value: "UT-3001-DAR-XL",
        enabled: ["other"],
    },
    {
        label: "Unisex Jersey Short Sleeve Tee - 3001 - DARK GREY HEATHER - 2XL",
        value: "UT-3001-DAR-2XL",
        enabled: ["other"],
    },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - HEATHER MAUVE - S", value: "UT-3001-HEE-S", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - HEATHER MAUVE - M", value: "UT-3001-HEE-M", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - HEATHER MAUVE - L", value: "UT-3001-HEE-L", enabled: ["other"] },
    {
        label: "Unisex Jersey Short Sleeve Tee - 3001 - HEATHER MAUVE - XL",
        value: "UT-3001-HEE-XL",
        enabled: ["other"],
    },
    {
        label: "Unisex Jersey Short Sleeve Tee - 3001 - HEATHER MAUVE - 2XL",
        value: "UT-3001-HEE-2XL",
        enabled: ["other"],
    },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - HEATHER NAVY - S", value: "UT-3001-HEY-S", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - HEATHER NAVY - M", value: "UT-3001-HEY-M", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - HEATHER NAVY - L", value: "UT-3001-HEY-L", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - HEATHER NAVY - XL", value: "UT-3001-HEY-XL", enabled: ["other"] },
    {
        label: "Unisex Jersey Short Sleeve Tee - 3001 - HEATHER NAVY - 2XL",
        value: "UT-3001-HEY-2XL",
        enabled: ["other"],
    },
    {
        label: "Unisex Jersey Short Sleeve Tee - 3001 - ATHLETIC HEATHER - S",
        value: "UT-3001-ATR-S",
        enabled: ["other"],
    },
    {
        label: "Unisex Jersey Short Sleeve Tee - 3001 - ATHLETIC HEATHER - M",
        value: "UT-3001-ATR-M",
        enabled: ["other"],
    },
    {
        label: "Unisex Jersey Short Sleeve Tee - 3001 - ATHLETIC HEATHER - L",
        value: "UT-3001-ATR-L",
        enabled: ["other"],
    },
    {
        label: "Unisex Jersey Short Sleeve Tee - 3001 - ATHLETIC HEATHER - XL",
        value: "UT-3001-ATR-XL",
        enabled: ["other"],
    },
    {
        label: "Unisex Jersey Short Sleeve Tee - 3001 - ATHLETIC HEATHER - 2XL",
        value: "UT-3001-ATR-2XL",
        enabled: ["other"],
    },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - FOREST GREEN - S", value: "UT-3001-FON-S", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - FOREST GREEN - M", value: "UT-3001-FON-M", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - FOREST GREEN - L", value: "UT-3001-FON-L", enabled: ["other"] },
    { label: "Unisex Jersey Short Sleeve Tee - 3001 - FOREST GREEN - XL", value: "UT-3001-FON-XL", enabled: ["other"] },
    {
        label: "Unisex Jersey Short Sleeve Tee - 3001 - FOREST GREEN - 2XL",
        value: "UT-3001-FON-2XL",
        enabled: ["other"],
    },
    { label: "Heavy Cotton™ Women’s T-Shirt - 5000L - BLACK - S", value: "WT-5000L-BLK-S", enabled: ["other"] },
    { label: "Heavy Cotton™ Women’s T-Shirt - 5000L - BLACK - M", value: "WT-5000L-BLK-M", enabled: ["other"] },
    { label: "Heavy Cotton™ Women’s T-Shirt - 5000L - BLACK - L", value: "WT-5000L-BLK-L", enabled: ["other"] },
    { label: "Heavy Cotton™ Women’s T-Shirt - 5000L - BLACK - XL", value: "WT-5000L-BLK-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ Women’s T-Shirt - 5000L - BLACK - 2XL", value: "WT-5000L-BLK-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ Women’s T-Shirt - 5000L - BLACK - 3XL", value: "WT-5000L-BLK-3XL", enabled: ["other"] },
    { label: "Heavy Cotton™ Women’s T-Shirt - 5000L - WHITE - S", value: "WT-5000L-WHE-S", enabled: ["other"] },
    { label: "Heavy Cotton™ Women’s T-Shirt - 5000L - WHITE - M", value: "WT-5000L-WHE-M", enabled: ["other"] },
    { label: "Heavy Cotton™ Women’s T-Shirt - 5000L - WHITE - L", value: "WT-5000L-WHE-L", enabled: ["other"] },
    { label: "Heavy Cotton™ Women’s T-Shirt - 5000L - WHITE - XL", value: "WT-5000L-WHE-XL", enabled: ["other"] },
    { label: "Heavy Cotton™ Women’s T-Shirt - 5000L - WHITE - 2XL", value: "WT-5000L-WHE-2XL", enabled: ["other"] },
    { label: "Heavy Cotton™ Women’s T-Shirt - 5000L - WHITE - 3XL", value: "WT-5000L-WHE-3XL", enabled: ["other"] },
    { label: "Mineral Wash T-Shirt - 1300 - BLACK - S", value: "UT-1300-BLK-S", enabled: ["other"] },
    { label: "Mineral Wash T-Shirt - 1300 - BLACK - M", value: "UT-1300-BLK-M", enabled: ["other"] },
    { label: "Mineral Wash T-Shirt - 1300 - BLACK - L", value: "UT-1300-BLK-L", enabled: ["other"] },
    { label: "Mineral Wash T-Shirt - 1300 - BLACK - XL", value: "UT-1300-BLK-XL", enabled: ["other"] },
    { label: "Mineral Wash T-Shirt - 1300 - BLACK - 2XL", value: "UT-1300-BLK-2XL", enabled: ["other"] },
    { label: "Mineral Wash T-Shirt - 1300 - BLACK - 3XL", value: "UT-1300-BLK-3XL", enabled: ["other"] },
    { label: "Mineral Wash T-Shirt - 1300 - GREY - S", value: "UT-1300-GRY-S", enabled: ["other"] },
    { label: "Mineral Wash T-Shirt - 1300 - GREY - M", value: "UT-1300-GRY-M", enabled: ["other"] },
    { label: "Mineral Wash T-Shirt - 1300 - GREY - L", value: "UT-1300-GRY-L", enabled: ["other"] },
    { label: "Mineral Wash T-Shirt - 1300 - GREY - XL", value: "UT-1300-GRY-XL", enabled: ["other"] },
    { label: "Mineral Wash T-Shirt - 1300 - GREY - 2XL", value: "UT-1300-GRY-2XL", enabled: ["other"] },
    { label: "Mineral Wash T-Shirt - 1300 - GREY - 3XL", value: "UT-1300-GRY-3XL", enabled: ["other"] },
    { label: "Giày Vans", value: "VANS-01", enabled: ["other"] },
    { label: "Weight from 0 gram to 99 gram", value: "0g - 99g", enabled: ["other"] },
    { label: "Weight from 100 gram to 199 gram", value: "100g - 199g", enabled: ["other"] },
    { label: "Weight from 200 gram to 299 gram", value: "200g - 299g", enabled: ["other"] },
    { label: "Weight from 300 gram to 399 gram", value: "300g - 399g", enabled: ["other"] },
    { label: "Weight from 400 gram to 499 gram", value: "400g - 499g", enabled: ["other"] },
    { label: "Weight from 500 gram to 599 gram", value: "500g - 599g", enabled: ["other"] },
    { label: "Weight from 600 gram to 699 gram", value: "600g - 699g", enabled: ["other"] },
    { label: "Weight from 700 gram to 799 gram", value: "700g - 799g", enabled: ["other"] },
    { label: "Weight from 800 gram to 899 gram", value: "800g - 899g", enabled: ["other"] },
    { label: "Weight from 900 gram to 999 gram", value: "900g - 999g", enabled: ["other"] },
    { label: "Weight from 1000 gram to 1099 gram", value: "1000g - 1099g", enabled: ["other"] },
    { label: "Weight from 1100 gram to 1199 gram", value: "1100g - 1199g", enabled: ["other"] },
    { label: "Weight from 1200 gram to 1299 gram", value: "1200g - 1299g", enabled: ["other"] },
    { label: "Weight from 1300 gram to 1399 gram", value: "1300g - 1399g", enabled: ["other"] },
    { label: "Weight from 1400 gram to 1499 gram", value: "1400g - 1499g", enabled: ["other"] },
    { label: "Weight from 1500 gram to 1599 gram", value: "1500g - 1599g", enabled: ["other"] },
    { label: "Weight from 1600 gram to 1699 gram", value: "1600g - 1699g", enabled: ["other"] },
    { label: "Weight from 1700 gram to 1799 gram", value: "1700g - 1799g", enabled: ["other"] },
    { label: "Weight from 1800 gram to 1899 gram", value: "1800g - 1899g", enabled: ["other"] },
    { label: "Weight from 1900 gram to 1999 gram", value: "1900g - 1999g", enabled: ["other"] },
];

const getSizePerPageList = (totalsize) => {
    return [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalsize || 50,
        },
    ];
};

const OptionSaigongroups = [
    // { label: "L0001", value: "L0001", enabled: ["other3"] },
    // { label: "L0002", value: "L0002", enabled: ["other3"] },
    // { label: "L0003", value: "L0003", enabled: ["other3"] },
    // { label: "L0004", value: "L0004", enabled: ["other3"] },
    // { label: "L0005", value: "L0005", enabled: ["other3"] },
    // { label: "M1", value: "M1", enabled: ["other3"] },
    // { label: "M2", value: "M2", enabled: ["other3"] },
    // { label: "M3", value: "M3", enabled: ["other3"] },
    // { label: "M4", value: "M4", enabled: ["other3"] },
    // { label: "M5", value: "M5", enabled: ["other3"] },
    // { label: "M6", value: "M6", enabled: ["other3"] },
    // { label: "M7", value: "M7", enabled: ["other3"] },
    // { label: "M8", value: "M8", enabled: ["other3"] },
    // { label: "M9", value: "M9", enabled: ["other3"] },
    // { label: "M10", value: "M10", enabled: ["other3"] },
    // { label: "M11", value: "M11", enabled: ["other3"] },
    // { label: "M12", value: "M12", enabled: ["other3"] },
    // { label: "M13", value: "M13", enabled: ["other3"] },
    // { label: "M14", value: "M14", enabled: ["other3"] },
    { label: "GD5000", value: "GD5000", enabled: ["other3"] },
    // { label: "M37", value: "M37", enabled: ["other3"] },
    // { label: "M16", value: "M16", enabled: ["other3"] },
    { label: "Flexfit", value: "FLEX", enabled: ["other3"] },
    { label: "Snapback", value: "SNAP", enabled: ["other3"] },
    { label: "Richardson 112", value: "R112", enabled: ["other3"] },
];

const BrandsDefault = [
    { value: "7374732359353157382", label: "No Brand" },
    { value: "7184176719711028997", label: "GILDAN" },
    { value: "7191523297467680518", label: "No BRAND ORIGINAL" },
];

export {
    AppRegions,
    ListDateType,
    TeamRole,
    CurrencyTiktok,
    ShopStatus,
    OrderCancelReason,
    OptionKVFulfill,
    getSizePerPageList,
    OptionSaigongroups,
    BrandsDefault,
};
