const initialState = {
	isLoading: false,
	list: [],
	all_profileautomates_for_export: [],
	totalsize: 0,
};

function profileAutomateReducer(state = initialState, action) {
	switch (action.type) {
		case 'LOADING_PROFILE_AUTOMATE':
			return {
				...state,
				isLoading: action.payload
			};
		case 'LIST_PROFILE_AUTOMATE':
			return {
				...state,
				list: action.payload.listprofileautomates,
				totalsize: action.payload.totalsize
			};
		case 'EXPORT_PROFILE_AUTOMATE':
			return {
				...state,
				all_profileautomates_for_export: action.payload.all_profileautomates_for_export
			};
		default:
			return state;
	}
}

export default profileAutomateReducer;
