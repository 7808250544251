import { useState, useEffect, useMemo } from "react";
import { ReactTableDataGrid } from "components/Table";
import { Link } from "react-router-dom";

const limit = 10;
const TableProduct = ({ data }) => {
    const [list, setList] = useState([]);

    useEffect(() => {
        if (data) {
            setList(data?.slice(0, limit));
        }
    }, [data]);

    const columns = [
        {
            field: "_id",
            width: 40,
            cellClass: "centered-cell",
            headerName: "",
            cellRenderer: ({ rowIndex, value: cell, data: row }) => rowIndex + 1,
            sortable: false,
        },
        {
            field: "product",
            headerName: "Product Name",
            width: 650,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => (
                <div className="d-flex flex-stack">
                    <Link
                        to={"https://shop.tiktok.com/view/product/" + row._id}
                        className="d-flex flex-stack"
                        target="_blank"
                    >
                        <span className="text-primary fw-semibold fs-6 me-2">{row.name}</span>{" "}
                        <i className="ki-outline ki-exit-right-corner fs-2"></i>
                    </Link>
                </div>
            ),
            sortable: false,
        },
        {
            field: "state",
            headerName: "Order Total",
            cellRenderer: ({ rowIndex, value: cell, data: row }) => row.count,
            sortable: false,
            flex: 1,
        },
    ];

    const onTableChanged = ({ page }) => {
        const startIndex = (page - 1) * limit;
        setList(data.slice(startIndex, startIndex + limit));
    };

    const defaultColDef = useMemo(
        () => ({
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        }),
        []
    );

    return (
        <ReactTableDataGrid
            idProperty="_id"
            columns={columns}
            defaultColDef={defaultColDef}
            height={350}
            rowData={list}
            gridOptions={{
                reactiveCustomComponents: true,
            }}
            paginationPageSize={limit}
            totalSize={data?.length || 0}
            emptyText={"No data!"}
            rowSelection="multiple"
            onTableChanged={onTableChanged}
            isSearchText={false}
        />
    );
};

export default TableProduct;
