import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { saveSplitOrder } from "actions/fulfillmentActions";
import Loading from "components/Loading";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import Select from 'react-select';

function Modalsplitorder(props) {
    const [_id] = useState(props.splitorder._id);
    const [splittable_groups, setSplittableGroups] = useState([]);
    var { isLoading } = props.fulfillment;
    var { isOpenModalSplitOrder, openModal, saveSplitOrder } = props;
    const { t } = useTranslation();
    const list_line_items = props.splitorder.line_items?.map((v, i) => {
        return { label: `${v?.product_name}`, value: v?.id };
    });

    const onClickSaveSplitOrder = () => {
        saveSplitOrder({ _id, splittable_groups, page: props.splitorder.page, sizePerPage: props.splitorder.sizePerPage, searchText: props.splitorder.searchText, filter_printer: props.splitorder.filter_printer, filter_shop_code: props.splitorder.filter_shop_code, filter_status: props.splitorder.filter_status, filter_work_status: props.splitorder.filter_work_status, filter_staff: props.splitorder.filter_staff }, openModal);
    };

    const onAddSplittableGroups = () => {
        setSplittableGroups((prev) => [
            ...prev,
            {
                id: (splittable_groups.length + 1).toString(),
                order_line_item_ids: [],
            },
        ]);
    };

    const handleDelete = (key) => {
        setSplittableGroups((prev) => prev.filter((_v, k) => k !== key));
    };

    const handleChange = ({ key, id, values }) => {
        setSplittableGroups((prev) => {
            return prev.map((v, i) => {
                if (i === key && values) {
                    let order_line_item_ids = values.reduce((r, o) => {
                        const arrValue = o?.value?.split("|")?.map((v) => v?.trim()).filter((v) => v);
                        if (arrValue?.length > 1) {
                            r.push(...arrValue);
                        } else {
                            r.push(o?.value);
                        }

                        return r;
                    }, []);
                    return { ...v, order_line_item_ids };
                }
                if (i === key && typeof id === "string") {
                    return { ...v, id };
                }

                return v;
            });
        });
    };

    const onOpenModal = () => {
        openModal();
    };

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={() => { onOpenModal() }} isOpen={isOpenModalSplitOrder}>
                <Loading isLoading={isLoading} />
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Split Order")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onOpenModal() }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="form-group row mb-4">
                        <div className="col-md-12">
                            <button type="button" className="btn btn-primary" onClick={() => { onAddSplittableGroups(); }} >
                                {t("Add Split Order")}
                            </button>
                            <div className="form-text text-danger"><i>{t("The remaining items that you do not select will be grouped into a separate split order")}</i></div>
                        </div>
                    </div>
                    {splittable_groups.map((v, i) => (
                        <div key={i} className="form-group row mb-4">
                            <div className="col-md-4">
                                <input type="text" className="form-control" placeholder={t("ID")} onChange={(e) => handleChange({ key: i, id: e.target.value }) } value={v.id} />
                            </div>
                            <div className="col-md-7">
                                <Select
                                    isMulti
                                    isClearable
                                    name="line_items"
                                    options={list_line_items.filter(({ value }) => !splittable_groups.some((selected) => selected.order_line_item_ids.includes(value)))}
                                    className="basic-multi-select al-custom-select-search-variants me-2 mb-3 al-filter-table w-100 rounded-0"
                                    classNamePrefix="select"
					                value={list_line_items.filter(({ value }) => splittable_groups?.[i]?.order_line_item_ids?.includes(value))}
                                    onChange={(e) => { handleChange({ key: i, values: e }) }}
                                    placeholder={t("Select a item ... ")}
                                />
                            </div>
                            <div className="col-md-1 d-flex align-items-center">
                                <span type="button" onClick={() => handleDelete(i)}>
                                    <i className="ki-outline ki-trash fs-2 text-danger"></i>
                                </span>
                            </div>
                        </div>
                    ))}
                </ModalBody>
                <ModalFooter>
                    <button type="reset" id="kt_modal_users_search_reset" data-bs-dismiss="modal" className="btn btn-active-light me-3" onClick={() => { onOpenModal() }} >{t("Cancel")}</button>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveSplitOrder() }}  >{t("Save")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

Modalsplitorder.propTypes = {
    saveSplitOrder: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    fulfillment: state.fulfillment
});
const mapDispatchToProps = {
    saveSplitOrder
}

export default connect(mapStateToProps, mapDispatchToProps)(Modalsplitorder);