import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import Select from "react-select";

function ModalChange({ onSubmit, shops }) {
    const [shop, setShop] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [select_all, setSelectAll] = useState(false);
    const { t } = useTranslation();

    const onChangeShop = (e) => {
        var shops_tmp = e.map((v, i) => {
            return v.value;
        });
        setShop(shops_tmp);
    };

    const onChange = (e) => {
        if (e.target.name === "select_all") {
            if (e.target.checked) {
                setShop(shops?.map((v) => v.value))
            }
            if (!e.target.checked) {
                setShop([])
            }
            setSelectAll(e.target.checked);
        }
    }

    const onClose = () => {
        setIsOpen(false);
    };

    const handleSubmit = () => {
        onSubmit({ shop });
        onClose();
    };

    return (
        <Fragment>
            <button
                className="btn btn-sm btn-info"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_create_app"
                id="kt_toolbar_primary_button"
                onClick={() => setIsOpen(true)}
            >
                <i className="ki-outline ki-cloud-download"></i>
                {t("Set")}
            </button>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    onClose();
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Set to shop")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="form-group">
                        <div className="d-flex align-items-center">
                            <input type="checkbox" placeholder="1 day" style={{ zoom: '1.5' }} name="select_all" value={select_all} onChange={(e) => {
                                onChange(e);
                            }} /> <label className="h5">Select all shop</label>
                        </div>
                        <div className="al-form-input-date">

                            {!select_all && <>Or
                                <Select
                                    isClearable
                                    isMulti
                                    name="shopSelect"
                                    options={shops}
                                    className="cotik-select-virtual"
                                    classNamePrefix="select"
                                    value={shops.filter(({ value }) => shop.includes(value))}
                                    onChange={(e) => {
                                        onChangeShop(e);
                                    }}
                                    placeholder={t("Select a shop ... ")}
                                    theme={(theme) => ({
                                        ...theme,
                                        spacing: {
                                            ...theme.spacing,
                                            controlHeight: "100%",
                                            controlWidth: "200px",
                                        },
                                    })}
                                />
                            </>}
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalChange);
