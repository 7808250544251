import { useState, Fragment } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { getStaffs } from "actions/teamActions";

const UpdateMember = ({ isOpen, data, onClose, onSubmit}) => {
    const [formData, setFormData] = useState({});
    const { t } = useTranslation();

    const handleChange = (e) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async () => {
        let d = formData;
        await onSubmit?.(d);
        onClose?.();
    };

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={onClose} isOpen={!!isOpen}>
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Transfer To")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Email")}</span>
                        </label>
                        <input type="text" className="form-control" value={formData?.email || ""} placeholder={t("Email")} name="email" onChange={handleChange} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({ shop: state.shop, team: state.team });

export default connect(mapStateToProps, { getStaffs })(UpdateMember);
