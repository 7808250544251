import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import SelectBox from "components/elements/selectBox";

function ModalMove({ onSubmit, staffs }) {
    const [profile_name, setProfileName] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const [staff, setStaff] = useState("");

    const onChange = (e) => {
        if (e.target.name === "profile_name") {
            setProfileName(e.target.value);
        }
    }

    const onClose = () => {
        setIsOpen(false);
    };

    const handleSubmit = () => {
        onSubmit({ data: profile_name, staff });
        onClose();
    };

    return (
        <Fragment>
            <button
                className="btn btn-dark btn-sm me-2"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_create_app"
                id="kt_toolbar_primary_button"
                onClick={() => setIsOpen(true)}
            >
                <i className="ki-outline ki-share"></i>
                {t("Move")}
            </button>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    onClose();
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Move User")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="form-group mb-5">
                        <label className="me-5">Select member: </label>
                        <SelectBox
                            options={staffs}
                            name="staff"
                            value={staff || ""}
                            onChange={(data) => {
                                setStaff(data.value || "");
                            }}
                            className="basic-multi-select al-custom-select-search-variants mr-1 mb-0 al-filter-table me-2 css-b62m3t-container"
                            placeholder={t("Select a staff ... ")}
                        />
                    </div>

                    <div className="form-group">
                        <label>List Profile Name</label>
                        <div className="al-form-input-date">
                            <textarea type="text" className="form-control w-100" rows={5} placeholder="1 day" name="profile_name" value={profile_name} onChange={(e) => {
                                onChange(e);
                            }} ></textarea>
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalMove);
