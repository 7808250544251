import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { saveEditWarehouse } from "actions/shopActions";
import Loading from "components/Loading";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import { ReactTable } from "components/Table";


function ModalChangeWarehouse(props) {
    const [data] = useState(props?.changeWarehouse?.warehouses?.filter((v) => v?.type === "SALES_WAREHOUSE"));
    const [_id] = useState(props.changeWarehouse._id);
    const [warehouseDefault, setWareHouseDefault] = useState(data?.find((v) => v.is_default)?.id);

    var { isLoading } = props.shop;
    var { isOpenModalChangeWarehouse, openModal, saveEditWarehouse } = props;
    const { t } = useTranslation();

    const onClickSaveEditPromotion = () => {
        saveEditWarehouse({ _id, warehouseDefault }, openModal);
    };

    const onChange = (e) => {
        if (e.target.name === "warehouses") {
            setWareHouseDefault(e.target.value);
        }
    }

    const onOpenModal = () => {
        openModal();
    };
    const columns = [
        {
            dataField: "id",
            text: "",
            sort: true,
            editable: false,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "name",
            text: t("Name"),
            editable: false,
            sort: true,
        },
        {
            dataField: "is_default",
            text: t("Is Default"),
            editable: false,
            sort: true,
        },
        {
            dataField: "effect_status",
            text: t("Effect Status"),
            editable: false,
            sort: true,
        },
        {
            dataField: "address",
            text: t("Address"),
            editable: false,
            sort: true,
            formatter: (cell, row, rowIndex) => <>{cell?.contact_person}<br></br>{cell?.phone_number}, {cell?.full_address}, {cell?.city}, {cell?.postal_code}</>,
        },
    ];

    return (
        <Fragment>
            <Modal centered={true} size="xl" toggle={() => { onOpenModal() }} isOpen={isOpenModalChangeWarehouse}>
                <Loading isLoading={isLoading} />
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Change Warehouse")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onOpenModal() }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Select Default Warehouse")}</span>
                        </label>
                        <div className="row">
                            <div className="col-9">
                                <select className="form-select" data-control="select2" data-hide-search="true" data-placeholder="Select a Warehouse" value={warehouseDefault} name="warehouses" onChange={(e) => { onChange(e) }} >
                                    {data.map((data, key) => (
                                        <option key={key} value={data.id}>
                                            {data.name} {" "}
                                            - {" "}
                                            {data?.address?.phone_number}, {data?.address?.full_address}, {data?.address?.city}, {data?.address?.postal_code}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-3">
                                <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveEditPromotion() }}  >{t("Change")}</button>
                            </div>

                        </div>

                    </div>
                    <div className="card-body p-9">
                        <div className="al-variants-table">
                            <ReactTable
                                isSearchBar={false}
                                columns={columns}
                                data={data}
                                alClassName="table-layout-inherit"
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="reset" id="kt_modal_users_search_reset" data-bs-dismiss="modal" className="btn btn-active-light me-3" onClick={() => { onOpenModal() }} >{t("Cancel")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

ModalChangeWarehouse.propTypes = {
    saveEditWarehouse: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    shop: state.shop
});
const mapDispatchToProps = {
    saveEditWarehouse,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalChangeWarehouse);