import { Fragment, useState, useEffect, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { getCategories, getAttributes, getBrands, getRecommendCategories } from "actions/categoryAction";
import { getCategoriesProduct } from "actions/categoryProductAction";
import { multiSelectFilter, Comparator } from "react-bootstrap-table2-filter";
import JoditEditor from "jodit-react";
import { TreeSelect } from "antd";
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import {
    getProductTemplateById,
    updateProductTemplate,
    uploadProductTemplate,
    uploadProductTemplateCSV,
} from "actions/productTemplateAction";
import { uploadImage } from "actions/websiteActions";
import ModalLinkImages from "../modalLinkImages";
import InputFile from "components/elements/inputFile";
import ImageBox from "components/elements/imageBox";
import { splitVariants, convertKeyValuePair, isNumeric } from "components/Function";
import { ReactTable } from "components/Table";
import Loading from "components/Loading";
import ModalUpdateVariant from "../modalUpdateVariant";
import ModalUpload from "../modalUpload";
import ModalUploadCSV from "../modalUploadCSV";
import ModalImageSelect from "components/elements/modalImageSelect";
import { sleep } from "components/Function";
import Swal from "sweetalert2";
import SelectBox from "components/elements/selectBox";
import { CurrencyTiktok, BrandsDefault } from "helper/constant";
import ModalListPrice from "../../products/modalListPrice";

function EditProduct({
    getCategories,
    getProductTemplateById,
    updateProductTemplate,
    splitVariants,
    convertKeyValuePair,
    getAttributes,
    uploadImage,
    uploadProductTemplate,
    uploadProductTemplateCSV,
    getCategoriesProduct,
    getBrands,
    getRecommendCategories,
}) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [openProductAttrs, setOpenProductAttr] = useState(true);
    const [openProductCom, setOpenProductCom] = useState(true);
    const [isEditVariant, setIsEditVariant] = useState(false);
    const [editListPrice, setEditListPrice] = useState(null);
    const [formData, setFormData] = useState({ category_version: "v2" });
    const [categories, setCategories] = useState([]);
    const [objCategories, setObjCategories] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [variantSelects, setVariantSelects] = useState([]);
    const { id } = useParams();
    const [optionsVariants, setOptionsVariants] = useState([]);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(999);
    const [totalSize] = useState(999);
    const [imageSku, setImageSku] = useState(null);
    const editor = useRef(null);
    const [categoriesProduct, setCategoriesProduct] = useState("");
    const [listCategoriesProduct, setListCategoriesProduct] = useState([]);
    const [brands, setBrands] = useState(BrandsDefault);
    const [isFocusedBrand, setIsFocusedBrand] = useState(false);

    const [columns, setColumns] = useState([
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
    ]);
    const config = useMemo(() => {
        return {
            placeholder: "Product description",
            height: "500px",
        };
    }, []);

    useEffect(() => {
        if (formData.category) {
            getAttributes(formData.category).then((d) => setAttributes(d || []));
        }
    }, [getAttributes, formData.category]);

    useEffect(() => {
        const promiseCate = getCategoriesProduct({ sizeperpage: 100 }).then((data) => {
            setListCategoriesProduct(
                data?.categoryProducts?.map((cate) => ({ value: cate?._id, label: cate.title })) || []
            );
        });

        // const promiseCatePlatform = getCategories((d) => {
        //     setObjCategories(
        //         d.reduce((result, obj) => {
        //             result[obj.id] = obj;
        //             return result;
        //         }, {})
        //     );
        // }).then((data) =>
        //     setCategories((data || []).map((c) => ({ value: c.id, label: c.local_name, children: c.children })))
        // );

        const promiseProduct = getProductTemplateById(id).then((data) => {
            if (data)
                setFormData({
                    ...data,
                    product_name: data.title,
                    weight: data.weight?.value,
                    weight_type: data.weight?.type,
                    isCod: data.isCod ? "on_cod" : "no_cod",
                    d_length: data?.package_dimensions?.length,
                    d_width: data?.package_dimensions?.width,
                    d_height: data?.package_dimensions?.height,
                    d_unit: data?.package_dimensions?.unit,
                });
            setCategoriesProduct(data?.categories);
            setOptionsVariants(data?.optionsVariants || []);
        });
        setLoading(true);
        Promise.all([promiseCate, promiseProduct])
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    }, [getProductTemplateById, getCategoriesProduct, id]);

    useEffect(() => {
        getCategories((d) => {
            setObjCategories(
                d.reduce((result, obj) => {
                    result[obj.id] = obj;
                    return result;
                }, {})
            );
        }, formData?.category_version || "v2").then((data) =>
            setCategories((data || []).map((c) => ({ value: c.id, label: c.local_name, children: c.children })))
        );
    }, [formData.category_version, getCategories]);

    useEffect(() => {
        let columns_tmp = [];
        for (const [key] of Object.entries(formData?.skus?.[0] || {})) {
            let obj = {};
            if (key === "_id") {
                obj = {
                    dataField: key,
                    text: "",
                    sort: true,
                    editable: false,
                    formatter: (cell, row, rowIndex) => rowIndex + 1,
                };
            } else if (key === "thumbnail") {
                obj = {
                    dataField: key,
                    text: key,
                    sort: true,
                    editable: false,
                    formatter: (cell, row, rowIndex) => (
                        <Fragment>
                            <img
                                src={cell}
                                alt=""
                                className="al-img-table al-cursor-pointer"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = require("components/assets/vendors/images/no-image.png");
                                }}
                            />
                        </Fragment>
                    ),
                };
            } else if (key === "price") {
                obj = {
                    dataField: key,
                    text: key,
                    sort: true,
                    classes: "al-cursor-pointer",
                    formatter: (cell, row, rowIndex) => (
                        <Fragment>
                            {cell} <i className="fa fa-pencil"></i>
                        </Fragment>
                    ),
                    validator: (newValue, row, column) => {
                        if (isNumeric(newValue)) {
                            return true;
                        } else {
                            return {
                                valid: false,
                                message: "INVALID TYPE! TRY AGAIN!",
                            };
                        }
                    },
                };
            } else if (key === "quantity") {
                obj = {
                    dataField: key,
                    text: key,
                    sort: true,
                    classes: "al-cursor-pointer",
                    formatter: (cell, row, rowIndex) => (
                        <Fragment>
                            {cell} <i className="fa fa-pencil"></i>
                        </Fragment>
                    ),
                    validator: (newValue, row, column) => {
                        if (isNumeric(newValue)) {
                            return true;
                        } else {
                            return {
                                valid: false,
                                message: "INVALID TYPE! TRY AGAIN!",
                            };
                        }
                    },
                };
            } else if (key === "seller_sku") {
                obj = {
                    dataField: key,
                    text: key,
                    sort: true,
                    classes: "al-cursor-pointer",
                    formatter: (cell, row, rowIndex) => (
                        <Fragment>
                            {cell} <i className="fa fa-pencil"></i>
                        </Fragment>
                    ),
                    validator: (newValue, row, column) => {
                        return true;
                    },
                };
            } else {
                let selectOptions_find = optionsVariants.find((v, i) => {
                    return v["key"] === key;
                });

                if (selectOptions_find) {
                    let selectOptions = selectOptions_find["value"].reduce(
                        (obj, item) => Object.assign(obj, { [item]: item }),
                        {}
                    );
                    obj = {
                        dataField: key,
                        text: key,
                        sort: true,
                        editable: false,
                        classes: "al-custom-options-variants",
                        attrs: {
                            "data-type": key,
                        },
                        formatter: (cell, row, rowIndex) => <Fragment>{selectOptions[cell]}</Fragment>,
                        filter: multiSelectFilter({
                            options: selectOptions,
                            comparator: Comparator.EQ,
                        }),
                    };
                }
            }

            if (obj && Object.keys(obj).length) columns_tmp.push(obj);
        }

        if (columns_tmp.length)
            setColumns([
                ...columns_tmp,
                {
                    dataField: "list_price",
                    editable: false,
                    text: t("List Price"),
                    formatter: (cell, row) => cell?.amount || 0,
                },
                {
                    dataField: "-",
                    text: t("Actions"),
                    editable: false,
                    formatExtraData: { currency: formData?.currency },
                    formatter: (cell, row, rowIndex, formatExtraData) => (
                        <>
                            <button
                                className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={t("Delete")}
                                onClick={() =>
                                    setFormData((prev) => ({
                                        ...prev,
                                        skus: prev?.skus?.filter((sku) => sku._id !== row._id),
                                    }))
                                }
                            >
                                <i className="ki-outline ki-trash fs-2"></i>
                            </button>
                            {(formatExtraData.currency || "USD") === "USD" && (
                                <button
                                    className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={t("Edit List Price")}
                                    onClick={() => setEditListPrice(row)}
                                >
                                    <i className="ki-outline ki-pencil fs-2"></i>
                                </button>
                            )}
                        </>
                    ),
                },
            ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.skus, optionsVariants, formData?.currency]);

    const handleChangeForm = (e) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async () => {
        if (isEditVariant) return Swal.fire(t("Error"), "Please done variant to save", "error");
        await updateProductTemplate({ ...formData, id, optionsVariants, categories: categoriesProduct }, setLoading);
    };

    const onUpdateOptions = (type = "") => {
        if (optionsVariants.length >= 3) {
            return false;
        }
        setOptionsVariants((prev) => [
            ...prev,
            {
                key: "",
                value: [],
                type,
            },
        ]);
    };

    const handleDeleteOption = (key) => {
        if (!isEditVariant) return;
        setOptionsVariants((prev) => prev.filter((_v, k) => k !== key));
    };

    const handleChangeOption = ({ key, values = null, title = null, target }) => {
        setOptionsVariants((prev) => {
            return prev.map((v, k) => {
                if (k === key && values) {
                    let value = values.reduce((r, o) => {
                        const arrValue = o?.value
                            ?.split("|")
                            ?.map((v) => v?.trim())
                            .filter((v) => v);
                        if (arrValue?.length > 1) {
                            r.push(...arrValue);
                        } else {
                            r.push(o?.value);
                        }

                        return r;
                    }, []);
                    return { ...v, value };
                }
                if (k === key && title && target)
                    return { ...v, key: target.selectedOptions[0].text, attributeId: title };
                if (k === key && typeof title === "string") return { ...v, key: title };

                return v;
            });
        });
    };

    const handleDoneVariants = () => {
        if (!optionsVariants?.length)
            return setFormData((prev) => ({
                ...prev,
                skus: [],
            }));

        setFormData((prev) => {
            const dataNew = convertKeyValuePair(splitVariants(optionsVariants), formData?.main_images || [], "USD");

            const skusResult = dataNew.map((sku) => {
                const arrCheck = Object.keys(sku).reduce((r, key) => {
                    if (!["_id", "thumbnail", "price", "quantity", "seller_sku", "list_price"].includes(key)) {
                        r.push(key);
                    }
                    return r;
                }, []);
                const dataOld = (prev?.skus || []).find((i) => {
                    return arrCheck.every((kCheck) => i[kCheck] === sku[kCheck]);
                });

                return dataOld ? { ...dataOld, _id: sku._id, seller_sku: sku?.seller_sku } : sku;
            });

            return {
                ...prev,
                skus: skusResult,
            };
        });
    };

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        setPage(page);
        setSizePerPage(sizePerPage);
    };

    const handleUploadImage = async (e, key = "main_images") => {
        const images = Object.values(e).filter((f) => f.type.includes("image"));
        if (images.length) {
            setLoading(true);
            const postData = await uploadImage(images);
            const links = postData?.arr_images || [];
            setFormData((prev) => ({ ...prev, [key]: [...(prev?.[key] || []), ...links] }));
            setLoading(false);
        }
    };

    const onSortEnd = (oldIndex, newIndex) => {
        setFormData((prev) => ({
            ...prev,
            main_images: arrayMove(prev?.main_images || [], oldIndex, newIndex),
        }));
    };

    const handleUpload = async (data) => {
        const files = [...data];
        const file = files.shift();
        await uploadProductTemplate([file], id);
        if (files?.length) {
            return handleUpload(files);
        }
        return Promise.resolve(true);
    };

    const handleUploadCSV = async (data) => {
        setLoading(true);
        await Promise.all(
            data?.map(async (obj, iv) => {
                await sleep(iv * 50);
                return new Promise(async (resolve2, reject2) => {
                    if (obj?.title && obj?.image1) {
                        await uploadProductTemplateCSV(obj, id).then(async (a) => resolve2("ok"));
                    } else {
                        resolve2("ok");
                    }
                });
            })
        ).then(async (dat) => {
            setLoading(false);
            return Swal.fire(t("Success"), t("Upload data success!"), "success");
        });
        // return uploadProductTemplateCSV(data, id);
    };

    const handleChangeImageSku = (image) => {
        setOptionsVariants((prev) =>
            prev.map((opt) => {
                if (imageSku.key === opt.key) {
                    return { ...opt, image: { ...(opt?.image || {}), [imageSku.item]: image } };
                }

                if (imageSku.key !== opt.key && Object.keys(opt?.image || {})) {
                    return { ...opt, image: {} };
                }

                return opt;
            })
        );
    };

    const handleClearImageVariant = () => {
        setOptionsVariants((prev) =>
            prev.map((opt) => {
                if (imageSku.key === opt.key) {
                    return { ...opt, image: { ...(opt?.image || {}), [imageSku.item]: null } };
                }

                return opt;
            })
        );
    };

    const handleChangeProductAttrs = (data, attrId) => {
        if (!Array.isArray(data)) data = [data];
        const values = data.reduce((result, item) => {
            if (item) {
                let val = { id: item?.value, name: item?.label };
                if (item?.["__isNew__"] || !Number.isInteger(+item?.value)) {
                    val = { name: item.label };
                }
                result.push(val);
            }

            return result;
        }, []);

        setFormData((prev) => {
            let result = [...(prev?.product_attributes || []), { id: attrId, values }];
            const exist = prev?.product_attributes?.find((a) => a.id === attrId);
            if (exist) {
                result = prev?.product_attributes.map((a) => {
                    if (a.id === attrId) return { id: attrId, values };
                    return a;
                });
            }
            return { ...prev, product_attributes: result };
        });
    };

    const handleChangeCertifications = async (id, e, key) => {
        setLoading(true);
        const images = Object.values(e).filter((f) => f.type.includes("image") || f.type.includes("pdf"));
        const postData = await uploadImage(images);
        const links = postData?.arr_images;
        setFormData((prev) => {
            let data = prev?.certifications || [];
            data[key] = { id, value: links[0] };
            return { ...prev, certifications: data };
        });
        setLoading(false);
    };

    const handleUpdateSkuListPrice = (amount) => {
        setFormData((prev) => ({
            ...prev,
            skus: prev?.skus?.map((sku) => {
                if (sku._id === editListPrice._id) {
                    return { ...sku, list_price: { amount: amount.toString(), currency: "USD" } };
                }
                return sku;
            }),
        }));
        setEditListPrice(null);
    };

    const handleMatchListPrice = () => {
        setFormData((prev) => ({
            ...prev,
            skus: prev?.skus?.map((sku) => ({
                ...sku,
                list_price: {
                    amount: sku.price,
                    currency: "USD",
                },
            })),
        }));
    };

    const handleClearListPrice = () => {
        setFormData((prev) => ({
            ...prev,
            skus: prev?.skus?.map((sku) => ({ ...sku, list_price: undefined })),
        }));
    };

    const onClickGetRecommendCategories = () => {
        setLoading(true);
        setFormData((prev) => ({ ...prev, category: "" }));
        getRecommendCategories((d) => {
            setObjCategories(
                d.reduce((result, obj) => {
                    result[obj.id] = obj;
                    return result;
                }, {})
            );
        }, formData?.category_version || "v2", formData?.product_name).then((data) => {
            setCategories((data || []).map((c) => ({ value: c.id, label: c.local_name, children: c.children })))
            setLoading(false);
        });
    };

    const groupButtonTableSku = () => (
        <div className="d-flex gap-4 mt-4 mb-4">
            <ModalUpdateVariant
                showListPrice={(formData.currency || "USD") === "USD"}
                disabled={!variantSelects?.length}
                onSubmit={(data) => {
                    setFormData((prev) => ({
                        ...prev,
                        skus: prev?.skus?.map((sku) => {
                            const priceChange = data?.price || sku?.price;
                            if (variantSelects.includes(sku._id)) {
                                let list_price;
                                if (data?.list_price?.type === "plus" && +data?.list_price?.extValue) {
                                    list_price = {
                                        amount: (+data?.list_price?.extValue + +priceChange).toString(),
                                        currency: "USD",
                                    };
                                }

                                if (data?.list_price?.type === "percent" && data?.list_price?.extValue) {
                                    list_price = {
                                        amount: (
                                            +priceChange +
                                            (+priceChange * +data?.list_price?.extValue) / 100
                                        ).toFixed(2),
                                        currency: "USD",
                                    };
                                }

                                if (data?.list_price?.type === "fixed" && +data?.list_price?.amount) {
                                    list_price = {
                                        amount: data?.list_price?.amount?.toString(),
                                        currency: "USD",
                                    };
                                }
                                return {
                                    ...sku,
                                    price: priceChange,
                                    quantity: data?.quantity || sku?.quantity,
                                    seller_sku: data?.seller_sku || sku?.seller_sku || "",
                                    list_price,
                                };
                            }

                            return { ...sku, seller_sku: sku?.seller_sku || "" };
                        }),
                    }));
                }}
            />
            <button onClick={handleMatchListPrice} className="btn btn-success">
                Match all List Price
            </button>
            <button onClick={handleClearListPrice} className="btn btn-danger">
                Clear all List Price
            </button>
        </div>
    );

    const handleSearchBrand = async (event) => {
        const value = event.target.value;
        if (event.key === "Enter" && value) {
            setLoading(true);
            const data = await getBrands({
                category_version: formData?.category_version,
                category_id: formData.category,
                brand_name: value,
            });

            if (data?.brands?.length) {
                setBrands([...data.brands.map((b) => ({ value: b.id, label: b.name })), ...BrandsDefault]);
            }
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <Loading isLoading={loading} />
            <ModalImageSelect
                images={formData?.image_variant || []}
                isOpen={!!imageSku?.toString()}
                onClose={() => setImageSku(null)}
                onSubmit={handleChangeImageSku}
                onClearImage={handleClearImageVariant}
            />
            <div className="card min-w-full">
                <div className="card-body">
                    <div className="d-flex align-items-center flex-wrap gap-5 mb-4">
                        <button
                            className="btn btn-sm btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_app"
                            id="kt_toolbar_primary_button"
                            onClick={handleSubmit}
                        >
                            <i className="ki-outline ki-cloud-download"></i>
                            {t("Save Product Template")}
                        </button>
                        <ModalUpload onSubmit={handleUpload} />
                        <ModalUploadCSV onSubmit={handleUploadCSV} />
                        <SelectBox
                            options={listCategoriesProduct}
                            name="filter_categories"
                            value={categoriesProduct || ""}
                            onChange={(data) => {
                                setCategoriesProduct(data.value || "");
                                setPage(1);
                            }}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <div>
                            <h3 className="required">{t("Product Image")}</h3>
                            <span className="text-gray-600 text-2sm font-medium">
                                {t(
                                    "It's recommended to include at least 5 images to adequately represent your product."
                                )}
                            </span>
                        </div>
                        <div className="flex items-center mt-5">
                            <ModalLinkImages
                                onSubmit={(data) => {
                                    setFormData((prev) => ({
                                        ...prev,
                                        main_images: [...(prev?.main_images || []), ...data],
                                    }));
                                }}
                            />
                            <InputFile onChange={(e) => handleUploadImage(e, "main_images")}>
                                <button
                                    className="btn btn-danger ms-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_create_app"
                                    id="kt_toolbar_primary_button"
                                >
                                    <i className="ki-outline ki-cloud-download"></i>
                                    {t("Upload Image")}
                                </button>
                            </InputFile>
                        </div>
                        <div className="mt-4">
                            {!!formData?.main_images?.length && (
                                <SortableList
                                    onSortEnd={onSortEnd}
                                    className="al-thumbsContainer"
                                    draggedItemClassName="dragged"
                                >
                                    {formData?.main_images.map((link, key) => (
                                        <SortableItem key={key}>
                                            <div className="al-thumb">
                                                <div className="al-thumbInner position-relative">
                                                    <button
                                                        onClick={() =>
                                                            setFormData((prev) => ({
                                                                ...prev,
                                                                main_images: (prev?.main_images || []).filter(
                                                                    (_s, k) => k !== key
                                                                ),
                                                            }))
                                                        }
                                                        type="button"
                                                        className="btn-delete-image"
                                                    >
                                                        <i className="fa fa-trash" />
                                                    </button>
                                                    <img
                                                        src={link}
                                                        alt=""
                                                        className="al-product-img al-cursor-move w-100 h-100"
                                                        draggable="false"
                                                        style={{ objectFit: "cover" }}
                                                    />
                                                </div>
                                            </div>
                                        </SortableItem>
                                    ))}
                                </SortableList>
                            )}
                        </div>
                        <div className="d-flex mb-4 fv-row fv-plugins-icon-container gap-5">
                            <label className="d-flex align-items-center fs-6 fw-semibold">
                                <span>{t("OptimizedImage Main Image")}:</span>
                            </label>
                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                                <input
                                    onChange={(e) =>
                                        setFormData((prev) => ({ ...prev, optimizedImage: e.target.checked }))
                                    }
                                    checked={formData?.optimizedImage}
                                    className="form-check-input"
                                    type="checkbox"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <div>
                            <h3 className="">{t("Image Other")}</h3>
                        </div>
                        <div className="flex items-center mt-5 gap-2">
                            <ModalLinkImages
                                onSubmit={(data) => {
                                    setFormData((prev) => ({
                                        ...prev,
                                        image_other: [...(prev?.image_other || []), ...data],
                                    }));
                                }}
                            />
                            <InputFile onChange={(e) => handleUploadImage(e, "image_other")}>
                                <button
                                    className="btn btn-danger ms-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_create_app"
                                    id="kt_toolbar_primary_button"
                                >
                                    <i className="ki-outline ki-cloud-download"></i>
                                    {t("Upload Image")}
                                </button>
                            </InputFile>
                        </div>
                        <div className="mt-4">
                            {!!formData?.image_other?.length &&
                                formData?.image_other.map((link, key) => (
                                    <ImageBox
                                        key={key}
                                        src={link}
                                        onRemove={() => {
                                            setFormData((prev) => ({
                                                ...prev,
                                                image_other: (prev?.image_other || []).filter((_s, k) => k !== key),
                                            }));
                                        }}
                                    />
                                ))}
                        </div>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <div>
                            <h3 className="required">{t("Size Chart")}</h3>
                        </div>
                        <div className="flex items-center mt-5 gap-2">
                            <ModalLinkImages
                                onSubmit={(data) => {
                                    setFormData((prev) => ({
                                        ...prev,
                                        size_chart: [...(prev?.size_chart || []), ...data],
                                    }));
                                }}
                            />
                            <InputFile onChange={(e) => handleUploadImage(e, "size_chart")}>
                                <button
                                    className="btn btn-danger ms-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_create_app"
                                    id="kt_toolbar_primary_button"
                                >
                                    <i className="ki-outline ki-cloud-download"></i>
                                    {t("Upload Image")}
                                </button>
                            </InputFile>
                        </div>
                        <div className="mt-4">
                            {!!formData?.size_chart?.length &&
                                formData?.size_chart.map((link, key) => (
                                    <ImageBox
                                        key={key}
                                        src={link}
                                        onRemove={() => {
                                            setFormData((prev) => ({
                                                ...prev,
                                                size_chart: (prev?.size_chart || []).filter((_s, k) => k !== key),
                                            }));
                                        }}
                                    />
                                ))}
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-8 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">
                                    {t("Product name")}{" "}
                                    <span
                                        className={
                                            formData?.product_name?.length < 140 && formData?.product_name?.length > 40
                                                ? "text-success"
                                                : "text-danger"
                                        }
                                    >
                                        Total: {formData?.product_name?.length}
                                    </span>
                                </span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t(
                                    "[Brand] + [Content] + [Scope of application] + [Product type] + [Main function/Feature]"
                                )}
                                name="product_name"
                                onChange={handleChangeForm}
                                value={formData?.product_name || ""}
                            />
                        </div>
                        <div className="col-4 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span>{t("Digit Number")}</span>
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="0"
                                name="digit_number"
                                onChange={handleChangeForm}
                                value={formData?.digit_number || ""}
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">{t("Category")}</span>
                            </label>
                            <TreeSelect
                                showSearch
                                style={{ width: "100%" }}
                                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                className="h-100"
                                placeholder="Please select"
                                treeNodeFilterProp="label"
                                onChange={(cate) => {
                                    if (cate !== formData.category) {
                                        setFormData((prev) => ({
                                            ...prev,
                                            // skus: [],
                                            product_attributes: [],
                                        }));
                                        // setOptionsVariants([]);
                                    }
                                    setFormData((prev) => ({ ...prev, category: cate }));
                                }}
                                treeData={categories}
                                value={formData.category}
                            />
                        </div>
                        <div className="col-2 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">{t("Category Version")}</span>
                            </label>
                            <SelectBox
                                options={[
                                    { value: "v1", label: "v1" },
                                    { value: "v2", label: "v2" },
                                ]}
                                className="h-100"
                                name="category_version"
                                value={formData?.category_version || "v2"}
                                onChange={(data) => {
                                    setFormData((prev) => ({ ...prev, category_version: data?.value || "v2" }));
                                }}
                            />
                        </div>
                        <div className="col-1 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span>&nbsp;</span>
                            </label>
                            <button className="btn btn-info" data-tooltip-id="my-tooltip" data-tooltip-content={t("Recommend Category")} onClick={() => onClickGetRecommendCategories()} >
                                <i className="fa fa-lightbulb" style={{ "fontSize": "20px"}}></i>
                            </button>
                        </div>
                        <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">{t("Product SKU")}</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("Product SKU")}
                                name="product_sku"
                                onChange={handleChangeForm}
                                value={formData?.product_sku || ""}
                            />
                        </div>
                        <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span>{t("Brand")}</span>
                            </label>
                            <SelectBox
                                placeholder="Search Brand"
                                options={brands}
                                name="brand"
                                value={isFocusedBrand ? "" : formData?.brand || ""}
                                onKeyDown={handleSearchBrand}
                                onChange={(data) => {
                                    setFormData((prev) => ({ ...prev, brand: data.value || "" }));
                                }}
                                noOptionsMessage={() => "Typing and press enter to search"}
                                onFocus={() => setIsFocusedBrand(true)}
                                onBlur={() => setIsFocusedBrand(false)}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Product description")}</span>
                        </label>
                        <div className="row">
                            <div className="col-lg-6 col-md-12 form-text">
                                <i>
                                    <b>Prerequisites:</b>
                                    <ul>
                                        <li>Must conform to html syntax</li>
                                        <li>
                                            {`Currently, it only supports html tags <p> <img> <ul> <ol> <li> <br> <strong> <b> <i> <em> <u>, other HTML tags will be filtered out and will not take effect. `}
                                        </li>
                                        <li>Tags can not be nested</li>
                                        <li>This field character limit needs to be within 10000 characters.</li>
                                        <li>
                                            It is recommended to avoid using Chinese because the copy will be displayed
                                            to local users.
                                        </li>
                                        <li>
                                            The img tag needs to include the src, width, and height attributes, and the
                                            image dimensions can not exceed 4000
                                        </li>
                                        <li>{`<strong> <b> <i> <em> <u> <br> should be used within  <p> and <li>`}</li>
                                    </ul>
                                </i>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <b>Description content code:</b>
                                <ul>
                                    <li>{`{main_image} - Insert Main Image at the position you fill in`}</li>
                                    <li>
                                        {`{random_image} - Insert Image Random in Product Image at the position you fill in`}
                                    </li>
                                    <li>{`{title} - Insert Product Name at the position you fill in`}</li>
                                </ul>
                            </div>
                        </div>
                        <JoditEditor
                            ref={editor}
                            value={formData?.description || ""}
                            config={config}
                            tabIndex={1}
                            onBlur={(newDescription) =>
                                setFormData((prev) => ({ ...prev, description: newDescription }))
                            }
                            onChange={(newDescription) => {}}
                            maxHeight={200}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="">{t("Video")}</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="https://"
                            name="video"
                            onChange={handleChangeForm}
                            value={formData?.video || ""}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Quantity")}</span>
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            placeholder={t("quantity")}
                            name="quantity"
                            onChange={handleChangeForm}
                            value={formData?.quantity || "0"}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Currency")}</span>
                        </label>
                        <select
                            className="form-select"
                            data-control="select2"
                            data-hide-search="true"
                            data-placeholder="currency"
                            name="currency"
                            onChange={handleChangeForm}
                            value={formData?.currency || "USD"}
                        >
                            {Object.keys(CurrencyTiktok).map((key) => (
                                <option value={key}>{CurrencyTiktok[key]}</option>
                            ))}
                        </select>
                    </div>
                    <div className="row">
                        <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">{t("Price")}</span>
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder={t("Price")}
                                name="price"
                                onChange={handleChangeForm}
                                value={formData?.price || "0"}
                            />
                        </div>
                        <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">{t("Weight with Package")}</span>
                            </label>
                            <div className="d-flex flex-row">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder={t("Weight with Package")}
                                    name="weight"
                                    onChange={handleChangeForm}
                                    value={formData?.weight || ""}
                                />
                                <select
                                    className="btn border border-secondary"
                                    data-control="select2"
                                    data-hide-search="true"
                                    data-placeholder="Status"
                                    name="weight_type"
                                    onChange={handleChangeForm}
                                    value={formData?.weight_type || ""}
                                >
                                    <option value="">{t("Please choose option")}</option>
                                    <option value="KILOGRAM">KILOGRAM</option>
                                    <option value="POUND">POUND</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span>{t("Dimensions")}</span>
                        </label>
                        <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <input
                                type="number"
                                className="form-control"
                                placeholder={t("Length")}
                                name="d_length"
                                onChange={handleChangeForm}
                                value={formData?.d_length || ""}
                            />
                        </div>
                        <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <input
                                type="number"
                                className="form-control"
                                placeholder={t("width")}
                                name="d_width"
                                onChange={handleChangeForm}
                                value={formData?.d_width || ""}
                            />
                        </div>
                        <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <input
                                type="number"
                                className="form-control"
                                placeholder={t("height")}
                                name="d_height"
                                onChange={handleChangeForm}
                                value={formData?.d_height || ""}
                            />
                        </div>
                        <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <select
                                className="btn border border-secondary"
                                data-control="select2"
                                data-hide-search="true"
                                data-placeholder="Status"
                                name="d_unit"
                                onChange={handleChangeForm}
                                value={formData?.d_unit}
                            >
                                <option value="">{t("Please choose option")}</option>
                                <option value="CENTIMETER">CENTIMETER</option>
                                <option value="INCH">INCH</option>
                            </select>
                        </div>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Delivery options")}</span>
                        </label>
                        <select
                            className="form-select"
                            data-control="select2"
                            data-hide-search="true"
                            data-placeholder="Status"
                            name="delivery_option"
                            onChange={handleChangeForm}
                            value={formData.delivery_option}
                        >
                            <option value="default">{t("Default")}</option>
                            <option value="custom">{t("Custom")}</option>
                        </select>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="">{t("Cash on Delivery")}</span>
                        </label>
                        <select
                            className="form-select"
                            data-control="select2"
                            data-hide-search="true"
                            data-placeholder="Status"
                            name="isCod"
                            onChange={handleChangeForm}
                            value={formData?.isCod || ""}
                        >
                            <option value="no_cod">{t("No COD")}</option>
                            <option value="on_cod">{t("On COD")}</option>
                        </select>
                    </div>
                    {Boolean(objCategories[formData.category]?.certifications?.length) && (
                        <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2 gap-2">
                                <h3 className="mb-0">{t("Certifications")}</h3>
                            </label>
                            <div className="row">
                                {objCategories[formData.category]?.certifications?.map((c, key) => (
                                    <div key={key} className="mb-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                            <span className="">{c?.name}</span>
                                        </label>
                                        <InputFile
                                            onChange={(e) => handleChangeCertifications(c.id, e, key)}
                                        >
                                            <button
                                                className="btn btn-danger"
                                                data-bs-toggle="modal"
                                                data-bs-target="#kt_modal_create_app"
                                                id="kt_toolbar_primary_button"
                                            >
                                                <i className="ki-outline ki-cloud-download"></i>
                                                {t("Upload Image Certifications")}
                                            </button>
                                        </InputFile>
                                        <div className="mt-4">
                                            {!!formData?.certifications?.[key] && (
                                                <ImageBox
                                                    key={key}
                                                    src={formData?.certifications?.[key].value}
                                                    onRemove={() => {
                                                        setFormData((prev) => ({
                                                            ...prev,
                                                            certifications: (prev?.certifications || []).filter(
                                                                (_s, k) => k.id !== c.id
                                                            ),
                                                        }));
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {!!attributes?.length && (
                        <>
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2 gap-2">
                                    <h3 className="mb-0">{t("Product Attributes")}</h3>
                                    <div onClick={() => setOpenProductAttr(!openProductAttrs)} className={"rotate-180"}>
                                        <i
                                            className={`ki-duotone ki-${
                                                openProductAttrs ? "up" : "down"
                                            } fs-1 cursor-pointer`}
                                        ></i>
                                    </div>
                                </label>
                                {openProductAttrs && (
                                    <div className="row">
                                        {attributes
                                            .filter(
                                                (attr) =>
                                                    !(
                                                        !attr.is_customizable &&
                                                        !attr.is_multiple_selection &&
                                                        !attr.is_requried
                                                    )
                                            )
                                            .map((attr, key) => (
                                                <div key={key} className="col-3 mb-2">
                                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                        <span className={attr.is_requried ? "required" : ""}>{attr.name}</span>
                                                    </label>
                                                    {attr.is_customizable ? (
                                                        <CreatableSelect
                                                            isClearable
                                                            isMulti={attr.is_multiple_selection}
                                                            onChange={(data) => handleChangeProductAttrs(data, attr.id)}
                                                            value={
                                                                formData?.product_attributes
                                                                    ?.find((a) => a.id === attr.id)
                                                                    ?.values.map((v, i) => {
                                                                        return { label: v.name, value: v.id || v.name };
                                                                    }) || []
                                                            }
                                                            className="w-100"
                                                            options={attr?.values.map((value) => ({
                                                                label: `${value?.name}`,
                                                                value: value?.id,
                                                            }))}
                                                            name="options-variants-value"
                                                            styles={{ height: "100%" }}
                                                            placeholder={t(`Select or Create a ${attr.name} ... `)}
                                                        />
                                                    ) : (
                                                        <Select
                                                            isClearable
                                                            isMulti={attr.is_multiple_selection}
                                                            name="shop"
                                                            options={attr?.values.map((value) => ({
                                                                label: `${value?.name}`,
                                                                value: value?.id,
                                                            }))}
                                                            value={
                                                                formData?.product_attributes
                                                                    ?.find((a) => a.id === attr.id)
                                                                    ?.values.map((v, i) => {
                                                                        return { label: v.name, value: v.id || v.name };
                                                                    }) || []
                                                            }
                                                            className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-0 al-filter-table w-100"
                                                            classNamePrefix="select"
                                                            onChange={(data) => handleChangeProductAttrs(data, attr.id)}
                                                            placeholder={t(`Select a ${attr.name} ... `)}
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                    </div>
                                )}
                            </div>
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2 gap-2">
                                    <h3 className="mb-0">{t("Product Compliance")}</h3>
                                    <div onClick={() => setOpenProductCom(!openProductCom)} className={"rotate-180"}>
                                        <i
                                            className={`ki-duotone ki-${
                                                openProductCom ? "up" : "down"
                                            } fs-1 cursor-pointer`}
                                        ></i>
                                    </div>
                                </label>
                                {openProductCom && (
                                    <div className="row">
                                        {attributes
                                            .filter(
                                                (attr) =>
                                                    !attr.is_customizable &&
                                                    !attr.is_multiple_selection &&
                                                    !attr.is_requried
                                            )
                                            .map((attr, key) => (
                                                <div key={key} className="col-12 mb-2">
                                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                        <span className={attr.is_requried ? "required" : ""}>{attr.name}</span>
                                                    </label>
                                                    {attr.is_customizable ? (
                                                        <CreatableSelect
                                                            isClearable
                                                            isMulti={attr.is_multiple_selection}
                                                            onChange={(data) => handleChangeProductAttrs(data, attr.id)}
                                                            value={
                                                                formData?.product_attributes
                                                                    ?.find((a) => a.id === attr.id)
                                                                    ?.values.map((v, i) => {
                                                                        return { label: v.name, value: v.id || v.name };
                                                                    }) || []
                                                            }
                                                            className="w-100"
                                                            options={attr?.values.map((value) => ({
                                                                label: `${value?.name}`,
                                                                value: value?.id,
                                                            }))}
                                                            name="options-variants-value"
                                                            styles={{ height: "100%" }}
                                                            placeholder={t(`Select or Create a ${attr.name} ... `)}
                                                        />
                                                    ) : (
                                                        <Select
                                                            isClearable
                                                            isMulti={attr.is_multiple_selection}
                                                            name="shop"
                                                            options={attr?.values.map((value) => ({
                                                                label: `${value?.name}`,
                                                                value: value?.id,
                                                            }))}
                                                            value={
                                                                formData?.product_attributes
                                                                    ?.find((a) => a.id === attr.id)
                                                                    ?.values.map((v, i) => {
                                                                        return { label: v.name, value: v.id || v.name };
                                                                    }) || []
                                                            }
                                                            className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-0 al-filter-table w-100"
                                                            classNamePrefix="select"
                                                            onChange={(data) => handleChangeProductAttrs(data, attr.id)}
                                                            placeholder={t(`Select a ${attr.name} ... `)}
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                    </div>
                                )}
                            </div>
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <h3 className="required">{t("Variations")}</h3>
                                </label>
                                <div className="d-flex gap-4 mb-4">
                                    {isEditVariant ? (
                                        <>
                                            {/* <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    onUpdateOptions();
                                                }}
                                            >
                                                Add option
                                            </button> */}
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    onUpdateOptions("custom");
                                                }}
                                            >
                                                Add Option Custom
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-success"
                                                onClick={() => {
                                                    handleDoneVariants();
                                                    setIsEditVariant(false);
                                                }}
                                            >
                                                Done
                                            </button>
                                        </>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-success"
                                            onClick={() => {
                                                setIsEditVariant(true);
                                            }}
                                        >
                                            Edit Option
                                        </button>
                                    )}
                                </div>
                                <div className="form-text mt-0 mb-4">
                                    <i>Using | between items to create multiple items</i>
                                </div>
                                {optionsVariants.map((opt, key) => (
                                    <div key={key} className="form-group row mb-4">
                                        <div className="col-md-3">
                                            {opt.type === "custom" || !opt.attributeId ? (
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t("Name")}
                                                    onChange={(e) =>
                                                        handleChangeOption({ key, title: e.target.value, target: null })
                                                    }
                                                    value={opt.key}
                                                    disabled={!isEditVariant}
                                                />
                                            ) : (
                                                <select
                                                    className="form-select"
                                                    data-control="select2"
                                                    data-hide-search="true"
                                                    data-placeholder="Status"
                                                    name="options-variants-key"
                                                    onChange={(e) =>
                                                        handleChangeOption({
                                                            key,
                                                            title: e.target.value,
                                                            target: e.target,
                                                        })
                                                    }
                                                    disabled={!isEditVariant}
                                                    value={opt.attributeId}
                                                >
                                                    <option value="">{t("Please choose attribute")}</option>
                                                    {attributes
                                                        .filter(
                                                            (attr) =>
                                                                !(
                                                                    opt.attributeId !== attr.id &&
                                                                    optionsVariants.find(
                                                                        (variant) => variant.attributeId === attr.id
                                                                    )
                                                                )
                                                        )
                                                        .map((a, ak) => (
                                                            <option key={ak} value={a.id}>
                                                                {a.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            )}
                                        </div>
                                        <div className="col-md-7 d-flex align-items-center">
                                            <CreatableSelect
                                                isMulti
                                                onChange={(e) => handleChangeOption({ key, values: e })}
                                                value={opt.value.map((v, i) => {
                                                    return { label: v, value: v, image: opt?.image?.[v] };
                                                })}
                                                formatOptionLabel={(v) => (
                                                    <div
                                                        onClick={() => setImageSku({ key: opt.key, item: v.value })}
                                                        className="option cursor-pointer"
                                                    >
                                                        {v?.image && (
                                                            <img
                                                                style={{
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    objectFit: "contain",
                                                                }}
                                                                className="mx-2"
                                                                src={v.image}
                                                                alt="country-image"
                                                            />
                                                        )}
                                                        <span>{v.label}</span>
                                                    </div>
                                                )}
                                                isDisabled={!isEditVariant}
                                                className="w-100"
                                                options={[]}
                                                name="options-variants-value"
                                                styles={{ height: "100%" }}
                                            />
                                        </div>
                                        <div className="col-md-2 d-flex align-items-center">
                                            <span type="button" onClick={() => handleDeleteOption(key)}>
                                                <i className="ki-outline ki-trash fs-2 text-danger"></i>
                                            </span>
                                        </div>
                                    </div>
                                ))}
                                {!!optionsVariants?.length && (
                                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <div>
                                            <p className="mb-0">{t("Image Variants")}</p>
                                        </div>
                                        <div className="flex items-center mt-5 gap-2">
                                            <ModalLinkImages
                                                onSubmit={(data) => {
                                                    setFormData((prev) => ({
                                                        ...prev,
                                                        image_variant: [...(prev?.image_variant || []), ...data],
                                                    }));
                                                }}
                                            />
                                            <InputFile onChange={(e) => handleUploadImage(e, "image_variant")}>
                                                <button
                                                    className="btn btn-danger ms-2"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#kt_modal_create_app"
                                                    id="kt_toolbar_primary_button"
                                                >
                                                    <i className="ki-outline ki-cloud-download"></i>
                                                    {t("Upload Image")}
                                                </button>
                                            </InputFile>
                                        </div>
                                        <div className="mt-4">
                                            {!!formData?.image_variant?.length &&
                                                formData?.image_variant.map((link, key) => (
                                                    <ImageBox
                                                        key={key}
                                                        src={link}
                                                        onRemove={() => {
                                                            setFormData((prev) => ({
                                                                ...prev,
                                                                image_variant: (prev?.image_variant || []).filter(
                                                                    (_s, k) => k !== key
                                                                ),
                                                            }));
                                                        }}
                                                    />
                                                ))}
                                        </div>
                                    </div>
                                )}
                                <div className="al-variants-table">
                                    <ReactTable
                                        columns={columns || []}
                                        data={formData?.skus || []}
                                        alClassName="table-layout-inherit zi-cs"
                                        handleTableChange={handleTableChange}
                                        page={page}
                                        sizePerPage={sizePerPage}
                                        totalSize={totalSize}
                                        isSearchBar={false}
                                        isCellEdit={true}
                                        isSelectRow={true}
                                        remote={{ search: true, pagination: false, sort: false }}
                                        selectRow={{
                                            mode: "checkbox",
                                            clickToSelect: false,
                                            selected: variantSelects,
                                            onSelect: (data, checked) => {
                                                if (checked) {
                                                    setVariantSelects((prev) => [...prev, data._id]);
                                                } else {
                                                    setVariantSelects((prev) =>
                                                        [...prev].filter((d) => d !== data._id)
                                                    );
                                                }
                                            },
                                            onSelectAll: (checked, data) => {
                                                let selects = [];
                                                if (checked) selects = (data || []).map((s) => s._id);
                                                setVariantSelects(selects);
                                            },
                                            selectionHeaderRenderer: ({ indeterminate, checked, onChange }) => (
                                                <div className="d-flex">
                                                    <input
                                                        type="checkbox"
                                                        ref={(input) => {
                                                            if (input) {
                                                                input.indeterminate = indeterminate;
                                                                input.checked = checked;
                                                            }
                                                        }}
                                                        onChange={onChange}
                                                    />
                                                    <span style={{ marginLeft: "8px" }}>({variantSelects.length})</span>
                                                </div>
                                            ),
                                        }}
                                        groupButton={groupButtonTableSku}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <ModalListPrice
                isOpen={Boolean(editListPrice)}
                onClose={() => setEditListPrice(null)}
                sku={editListPrice}
                onSubmit={handleUpdateSkuListPrice}
            />
        </Fragment>
    );
}
const mapStateToProps = () => ({});
const mapDispatchToProps = {
    getCategories,
    getAttributes,
    getProductTemplateById,
    updateProductTemplate,
    splitVariants,
    convertKeyValuePair,
    uploadImage,
    uploadProductTemplate,
    uploadProductTemplateCSV,
    getCategoriesProduct,
    getBrands,
    getRecommendCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProduct);
