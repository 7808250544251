import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { saveAddPrinter } from "actions/printerActions";
import Loading from "components/Loading";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";

function Modaladdprinter(props) {
    const [printer, setPrinter] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [access_token, setAccessToken] = useState("");
    const [auto_place, setAutoPlace] = useState("");
    const [api_key, setApiKey] = useState("");
    const [api_signature, setApiSignature] = useState("");
    var { isLoading } = props.printer;
    var { isOpenModalAddPrinter, openModal, saveAddPrinter } = props;
	const { t } = useTranslation();

    const onClickSaveAddPrinter = () => {
        saveAddPrinter({ printer, username, password, access_token, auto_place, api_key, api_signature }, openModal);
    };

    const onChange = (e) => {
        if (e.target.name === "printer") {
            setPrinter(e.target.value);
        }
        if (e.target.name === "username") {
            setUsername(e.target.value);
        }
        if (e.target.name === "password") {
            setPassword(e.target.value);
        }
        if (e.target.name === "access_token") {
            setAccessToken(e.target.value);
        }
        if (e.target.name === "auto_place") {
            setAutoPlace(e.target.value);
        }
        if (e.target.name === "api_key") {
            setApiKey(e.target.value);
        }
        if (e.target.name === "api_signature") {
            setApiSignature(e.target.value);
        }
    }

    const onOpenModal = () => {
        openModal();
    };

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={() => { onOpenModal() }} isOpen={isOpenModalAddPrinter}>
                <Loading isLoading={isLoading} />
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Add Printer")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onOpenModal() }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Printer")}</span>
                        </label>
                        <select className="form-select" data-control="select2" data-hide-search="true" data-placeholder="Select a printer" value={printer} name="printer" onChange={(e) => { onChange(e) }} >
                            <option value="">{t("-- Select a printer --")}</option>
                            <option value="flashship">Flashship</option>
                            <option value="mangoteeprints">Mangoteeprints</option>
                            <option value="gearment">Gearment</option>
                            <option value="hmfulfill">HM Fulfill</option>
                            <option value="lenful">Lenful</option>
                            <option value="onospod">OnosPOD</option>
                            <option value="a2kecom">A2K Ecom</option>
                            <option value="printeeshub">Printeeshub</option>
                            <option value="monkeykingprint">Monkeykingprint</option>
                            <option value="kvfulfill">KV Fulfill</option>
                            <option value="visiovsolutions">VisiovSolutions</option>
                            <option value="saigongroups">Saigongroups</option>
                        </select>
                    </div>
                    {
                        printer === "flashship" || printer === "hmfulfill" || printer === "lenful" || printer === "monkeykingprint" || printer === "kvfulfill" || printer === "saigongroups" ?
                            <Fragment>
                                <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                        <span className="required">{t("Username")}</span>
                                    </label>
                                    <input type="text" className="form-control" value={username} placeholder={t("Username")} name="username" onChange={(e) => { onChange(e) }} />
                                </div>
                                <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                        <span className="required">{t("Password")}</span>
                                    </label>
                                    <input type="password" className="form-control" value={password} placeholder={t("Password")} name="password" onChange={(e) => { onChange(e) }} />
                                </div>
                            </Fragment>
                        : ""
                    }
                    {
                        printer === "mangoteeprints" || printer === "onospod" || printer === "a2kecom" || printer === "visiovsolutions" ?
                            <Fragment>
                                <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                        <span className="required">{t("Access Token")}</span>
                                    </label>
                                    <input type="text" className="form-control" value={access_token} placeholder={t("Access Token")} name="access_token" onChange={(e) => { onChange(e) }} />
                                </div>
                                {
                                    printer === "mangoteeprints" ?
                                        <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                <span className="required">{t("Auto Place")}</span>
                                            </label>
                                            <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                                <input type="checkbox" className="form-check-input w-45px h-30px" value={!auto_place} id="auto_place" name="auto_place" checked={auto_place} onChange={(e) => { onChange(e) }} />
                                                <label className="form-check-label" htmlFor="auto_place"></label>
                                            </div>
                                        </div>
                                    : ""
                                }
                            </Fragment>
                        : ""
                    }
                    {
                        printer === "gearment" || printer === "printeeshub" ?
                            <Fragment>
                                <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                        <span className="required">{t("API key")}</span>
                                    </label>
                                    <input type="text" className="form-control" value={api_key} placeholder={t("API key")} name="api_key" onChange={(e) => { onChange(e) }} />
                                </div>
                                {
                                    printer === "gearment" ?
                                        <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                <span className="required">{t("API signature")}</span>
                                            </label>
                                            <input type="text" className="form-control" value={api_signature} placeholder={t("API signature")} name="api_signature" onChange={(e) => { onChange(e) }} />
                                        </div>
                                    : ""
                                }
                            </Fragment>
                        : ""
                    }
                </ModalBody>
                <ModalFooter>
                    <button type="reset" id="kt_modal_users_search_reset" data-bs-dismiss="modal" className="btn btn-active-light me-3" onClick={() => { onOpenModal() }} >{t("Cancel")}</button>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveAddPrinter() }}  >{t("Save")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

Modaladdprinter.propTypes = {
    saveAddPrinter: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    printer: state.printer
});
const mapDispatchToProps = {
    saveAddPrinter
}

export default connect(mapStateToProps, mapDispatchToProps)(Modaladdprinter);