import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { updateDesign } from "actions/designAction";
import ReactImageMagnify from 'react-image-magnify';
import { formatterImage } from "components/Function";

const ModalEditDesign = ({ data, onClose, onSubmit, updateDesign, formatterImage }) => {
    const { t } = useTranslation();
    const [product_id, setProductId] = useState(data?.product_id);
    const [sku_name, setSkuName] = useState(data?.sku_name);
    const [title, setTitle] = useState(data?.title);
    const [design_front, setDesignFront] = useState(data?.design?.Front);
    const [design_back, setDesignBack] = useState(data?.design?.Back);
    const [design_neck, setDesignNeck] = useState(data?.design?.Neck);
    const [design_right, setDesignRight] = useState(data?.design?.Right);
    const [design_left, setDesignLeft] = useState(data?.design?.Left);
    const [design_aop_3d, setDesignAOP3D] = useState(data?.design?.AOP_3D);

    const [mockup_front, setMockupFront] = useState(data?.mockup?.Front);
    const [mockup_back, setMockupBack] = useState(data?.mockup?.Back);
    const [mockup_neck, setMockupNeck] = useState(data?.mockup?.Neck);
    const [mockup_right, setMockupRight] = useState(data?.mockup?.Right);
    const [mockup_left, setMockupLeft] = useState(data?.mockup?.Left);
    const [mockup_aop_3d, setMockupAOP3D] = useState(data?.mockup?.AOP_3D);

    const [isEditMockupFront, setIsEditMockupFront] = useState(false);
    const [isEditMockupBack, setIsEditMockupBack] = useState(false);
    const [isEditMockupNeck, setIsEditMockupNeck] = useState(false);
    const [isEditMockupRight, setIsEditMockupRight] = useState(false);
    const [isEditMockupLeft, setIsEditMockupLeft] = useState(false);
    const [isEditMockupAop3d, setIsEditMockupAop3d] = useState(false);
    const [isEditDesignFront, setIsEditDesignFront] = useState(false);
    const [isEditDesignBack, setIsEditDesignBack] = useState(false);
    const [isEditDesignNeck, setIsEditDesignNeck] = useState(false);
    const [isEditDesignRight, setIsEditDesignRight] = useState(false);
    const [isEditDesignLeft, setIsEditDesignLeft] = useState(false);
    const [isEditDesignAop3d, setIsEditDesignAop3d] = useState(false);

    useEffect(() => {
        if (data) {
            setProductId(data?.product_id);
            setSkuName(data?.sku_name);
            setTitle(data?.title);
            setDesignFront(data?.design?.Front);
            setDesignBack(data?.design?.Back);
            setDesignNeck(data?.design?.Neck);
            setDesignRight(data?.design?.Right);
            setDesignLeft(data?.design?.Left);
            setDesignAOP3D(data?.design?.AOP_3D);
        
            setMockupFront(data?.mockup?.Front);
            setMockupBack(data?.mockup?.Back);
            setMockupNeck(data?.mockup?.Neck);
            setMockupRight(data?.mockup?.Right);
            setMockupLeft(data?.mockup?.Left);
            setMockupAOP3D(data?.mockup?.AOP_3D);
        }
    }, [data]);

    const onChange = (e) => {
        if (e.target.name === "product_id") {
            setProductId(e.target.value);
        }
        if (e.target.name === "sku_name") {
            setSkuName(e.target.value);
        }
        if (e.target.name === "title") {
            setTitle(e.target.value)
        }
        
        if (e.target.name === "mockup_front") {
            setMockupFront(e.target.value);
        }
        if (e.target.name === "mockup_back") {
            setMockupBack(e.target.value);
        }
        if (e.target.name === "mockup_neck") {
            setMockupNeck(e.target.value);
        }
        if (e.target.name === "mockup_right") {
            setMockupRight(e.target.value);
        }
        if (e.target.name === "mockup_left") {
            setMockupLeft(e.target.value);
        }
        if (e.target.name === "mockup_aop_3d") {
            setMockupAOP3D(e.target.value);
        }
        if (e.target.name === "design_front") {
            setDesignFront(e.target.value);
        }
        if (e.target.name === "design_back") {
            setDesignBack(e.target.value);
        }
        if (e.target.name === "design_neck") {
            setDesignNeck(e.target.value);
        }
        if (e.target.name === "design_right") {
            setDesignRight(e.target.value);
        }
        if (e.target.name === "design_left") {
            setDesignLeft(e.target.value);
        }
        if (e.target.name === "design_aop_3d") {
            setDesignAOP3D(e.target.value);
        }
    }

    const onClickMockup = (type, mockup_type) => {
        if (type === "confirm") {
            if (mockup_type === "Front") {
                setMockupFront(mockup_front);
            }
            if (mockup_type === "Back") {
                setMockupBack(mockup_back);
            }
            if (mockup_type === "Neck") {
                setMockupNeck(mockup_neck);
            }
            if (mockup_type === "Right") {
                setMockupRight(mockup_right);
            }
            if (mockup_type === "Left") {
                setMockupLeft(mockup_left);
            }
            if (mockup_type === "AOP_3D") {
                setMockupAOP3D(mockup_aop_3d);
            }
        }
        if (mockup_type === "Front") {
            setIsEditMockupFront(!isEditMockupFront);
        }
        if (mockup_type === "Back") {
            setIsEditMockupBack(!isEditMockupBack);
        }
        if (mockup_type === "Neck") {
            setIsEditMockupNeck(!isEditMockupNeck);
        }
        if (mockup_type === "Right") {
            setIsEditMockupRight(!isEditMockupRight);
        }
        if (mockup_type === "Left") {
            setIsEditMockupLeft(!isEditMockupLeft);
        }
        if (mockup_type === "AOP_3D") {
            setIsEditMockupAop3d(!isEditMockupAop3d);
        }
    }

    const onClickDesign = (type, design_type) => {
        if (type === "confirm") {
            if (design_type === "Front") {
                setDesignFront(design_front);
            }
            if (design_type === "Back") {
                setDesignBack(design_back);
            }
            if (design_type === "Neck") {
                setDesignNeck(design_neck);
            }
            if (design_type === "Right") {
                setDesignRight(design_right);
            }
            if (design_type === "Left") {
                setDesignLeft(design_left);
            }
            if (design_type === "AOP_3D") {
                setDesignAOP3D(design_aop_3d);
            }
        }
        if (design_type === "Front") {
            setIsEditDesignFront(!isEditDesignFront);
        }
        if (design_type === "Back") {
            setIsEditDesignBack(!isEditDesignBack);
        }
        if (design_type === "Neck") {
            setIsEditDesignNeck(!isEditDesignNeck);
        }
        if (design_type === "Right") {
            setIsEditDesignRight(!isEditDesignRight);
        }
        if (design_type === "Left") {
            setIsEditDesignLeft(!isEditDesignLeft);
        }
        if (design_type === "AOP_3D") {
            setIsEditDesignAop3d(!isEditDesignAop3d);
        }
    }

    const handleSubmit = async () => {
        const mockup = {
            Front: mockup_front,
            Back: mockup_back,
            Neck: mockup_neck,
            Right: mockup_right,
            Left: mockup_left,
            AOP_3D: mockup_aop_3d
        }
        const design = {
            Front: design_front,
            Back: design_back,
            Neck: design_neck,
            Right: design_right,
            Left: design_left,
            AOP_3D: design_aop_3d
        }
        await updateDesign({ _id: data?._id, product_id, sku_name, title, design, mockup, page: data?.page, sizeperpage: data?.sizeperpage, search: data?.search, filter: data?.filter, onClose })
        // onSubmit?.();
        // onClose?.();
    };

    return (
        <Fragment>
            <Modal centered={true} size="xl" toggle={onClose} isOpen={!!data}>
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Edit Design")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onClose(); }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Product ID")}</span>
                        </label>
                        <input type="text" className="form-control" value={product_id} placeholder={t("Product ID")} name="product_id" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("SKU name")}</span>
                        </label>
                        <input type="text" className="form-control" value={sku_name} placeholder={t("SKU name")} name="sku_name" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Title")}</span>
                        </label>
                        <input type="text" className="form-control" value={title} placeholder={t("Title")} name="title" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="row">
                        <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container col-md-6">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="">{t("Mockup")}</span>
                            </label>
                            <div className="d-flex justify-content-sm-evenly mb-5">
                                <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                    <span className="h3">Front</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditMockupFront ?
                                                mockup_front ?
                                                    <div onClick={() => { onClickMockup("change", "Front") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup_front) }, largeImage: { src: formatterImage(mockup_front), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickMockup("change", "Front") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={mockup_front} placeholder="" name="mockup_front" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickMockup("confirm", "Front") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column ml-5">
                                    <span className="h3">Back</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditMockupBack ?
                                                mockup_back ?
                                                    <div onClick={() => { onClickMockup("change", "Back") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup_back) }, largeImage: { src: formatterImage(mockup_back), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickMockup("change", "Back") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={mockup_back} placeholder="" name="mockup_back" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickMockup("confirm", "Back") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">Neck</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditMockupNeck ?
                                            mockup_neck ?
                                                    <div onClick={() => { onClickMockup("change", "Neck") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup_neck) }, largeImage: { src: formatterImage(mockup_neck), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickMockup("change", "Neck") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={mockup_neck} placeholder="" name="mockup_neck" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickMockup("confirm", "Neck") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-sm-evenly mb-5">
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">Right</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditMockupRight ?
                                            mockup_right ?
                                                    <div onClick={() => { onClickMockup("change", "Right") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup_right) }, largeImage: { src: formatterImage(mockup_right), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickMockup("change", "Right") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={mockup_right} placeholder="" name="mockup_right" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickMockup("confirm", "Right") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">Left</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditMockupLeft ?
                                                mockup_left ?
                                                    <div onClick={() => { onClickMockup("change", "Left") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup_left) }, largeImage: { src: formatterImage(mockup_left), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickMockup("change", "Left") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={mockup_left} placeholder="" name="mockup_left" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickMockup("confirm", "Left") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">AOP 3D</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditMockupAop3d ?
                                                mockup_aop_3d ?
                                                    <div onClick={() => { onClickMockup("change", "AOP_3D") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup_aop_3d) }, largeImage: { src: formatterImage(mockup_aop_3d), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickMockup("change", "AOP_3D") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={mockup_aop_3d} placeholder="" name="mockup_aop_3d" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickMockup("confirm", "AOP_3D") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container col-md-6">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="">{t("Design")}</span>
                            </label>
                            <div className="d-flex justify-content-sm-evenly mb-5">
                                <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                    <span className="h3">Front</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditDesignFront ?
                                                design_front ?
                                                    <div onClick={() => { onClickDesign("change", "Front") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(design_front) }, largeImage: { src: formatterImage(design_front), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickDesign("change", "Front") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={design_front} placeholder="" name="design_front" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickDesign("confirm", "Front") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column ml-5">
                                    <span className="h3">Back</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditDesignBack ?
                                                design_back ?
                                                    <div onClick={() => { onClickDesign("change", "Back") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(design_back) }, largeImage: { src: formatterImage(design_back), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickDesign("change", "Back") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={design_back} placeholder="" name="design_back" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickDesign("confirm", "Back") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">Neck</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditDesignNeck ?
                                                design_neck ?
                                                    <div onClick={() => { onClickDesign("change", "Neck") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(design_neck) }, largeImage: { src: formatterImage(design_neck), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickDesign("change", "Neck") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={design_neck} placeholder="" name="design_neck" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickDesign("confirm", "Neck") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-sm-evenly mb-5">
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">Right</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditDesignRight ?
                                                design_right ?
                                                    <div onClick={() => { onClickDesign("change", "Right") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(design_right) }, largeImage: { src: formatterImage(design_right), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickDesign("change", "Right") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={design_right} placeholder="" name="design_right" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickDesign("confirm", "Right") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">Left</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditDesignLeft ?
                                                design_left ?
                                                    <div onClick={() => { onClickDesign("change", "Left") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(design_left) }, largeImage: { src: formatterImage(design_left), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickDesign("change", "Left") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={design_left} placeholder="" name="design_left" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickDesign("confirm", "Left") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">AOP 3D</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditDesignAop3d ?
                                                design_aop_3d ?
                                                    <div onClick={() => { onClickDesign("change", "AOP_3D") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(design_aop_3d) }, largeImage: { src: formatterImage(design_aop_3d), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickDesign("change", "AOP_3D") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={design_aop_3d} placeholder="" name="design_aop_3d" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickDesign("confirm", "AOP_3D") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={handleSubmit}  >{t("Save")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

ModalEditDesign.propTypes = {
    updateDesign: PropTypes.func.isRequired,
    formatterImage: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    design: state.design
});
const mapDispatchToProps = {
    updateDesign,
    formatterImage,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditDesign);
