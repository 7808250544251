import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";

function ModalBuyCustom({ onSubmit }) {
    const [isOpen, setIsOpen] = useState(false);
    const [limit, setLimit] = useState(0);
    const [estimate, setEstimate] = useState(0);
    const { t } = useTranslation();

    const onChange = (e) => {
        if (e.target.name === "limit") {
            setLimit(e.target.value);
            if (+e.target.value <= 50) {
                setEstimate(+e.target.value * 2);
            } else if (+e.target.value <= 150) {
                setEstimate(+e.target.value * 1.8);
            } else if (+e.target.value <= 350) {
                setEstimate(+e.target.value * 1.6);
            } else {
                setEstimate(+e.target.value * 1.4);
            }
        }
    };

    const handleSubmit = async () => {
        await onSubmit(limit);
        setIsOpen(false);
    };

    return (
        <Fragment>
            <button
                className="btn btn-sm btn-success me-2 mb-2"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_create_app"
                id="kt_toolbar_primary_button"
                onClick={() => setIsOpen(true)}
            >
                <i className="ki-outline ki-exit-right fs-2"></i>{t("Buy Custom")}
            </button>
            <div> <b>10-day free trial </b>
                From $0.01/month to $20/month
            </div>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    setIsOpen(!isOpen);
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Total Shop?")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="form-group mb-5">
                        <input className="form-control" placeholder="Total min 1" type="number" name="limit" min={1} onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Estimate: <strong className="text-danger">{estimate}$</strong> <i>(This number may vary slightly over time.)</i></label>
                    </div>
                    <div className="form-group"><span className="form-label text-danger">Note: </span>If you have purchased a package and it is over 5 days old. Go here <Link to="/admin/account-package">
                        Account Package
                    </Link> to buy more slots!</div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Buy")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalBuyCustom);
