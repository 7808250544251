import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { getAdminSku, createAdminSku, editAdminSku } from "actions/adminSkuAction";
import { formatterCreateAt, FormatterStatus } from "components/Function";
import { ReactTable } from "components/Table";
import Loading from "components/Loading";
import { getSizePerPageList } from "helper/constant";
import SkuForm from "./form";

function AdminSku(props) {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [filter, setFilter] = useState({});
    const [totalSize, setTotalSize] = useState(0);
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(0);
    const [openAdd, setOpenAdd] = useState(false);
    const [itemEdit, setItemEdit] = useState(null);
    var { getAdminSku, createAdminSku, editAdminSku, formatterCreateAt } = props;

    useEffect(() => {
        setIsLoading(true);
        getAdminSku({ page, sizeperpage: sizePerPage, filter }).then((data) => {
            setIsLoading(false);
            setList(data.sku);
            setTotalSize(data?.totalSize || 0);
        });
    }, [getAdminSku, page, sizePerPage, filter]);

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "name",
            text: "Name",
            sort: true,
        },
        {
            dataField: "sku",
            text: "Sku",
            sort: true,
        },
        {
            dataField: "base_cost",
            text: "Base Cost",
            sort: true,
        },
        {
            dataField: "handling_fee",
            text: "Handling Fee",
            sort: true,
        },
        {
            dataField: "shipping_fee",
            text: "Shipping Fee",
            sort: true,
        },
        {
            dataField: "label_fee",
            text: "Label Fee",
            sort: true,
        },
        {
            dataField: "created_at",
            text: "Created At",
            sort: true,
            formatter: (cell, row) => formatterCreateAt(cell),
        },
        {
            dataField: "-",
            text: "Actions",
            formatter: (cell, row, rowIndex, formatExtraData) => (
                <Fragment>
                    <button
                        onClick={() => {
                            setItemEdit(row);
                        }}
                        className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={"Edit"}
                    >
                        <i className="ki-outline ki-notepad-edit fs-2"></i>
                    </button>
                </Fragment>
            ),
        },
    ];

    const groupButton = () => {
        return (
            <div className="flex gap-2">
                <button className="btn btn-primary" onClick={() => setOpenAdd(true)}>
                    Create Sku
                </button>
            </div>
        );
    };

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setFilter((prev) => ({ ...prev, search: searchText }));
        } else {
            setPage(page || 1);
            setSizePerPage(sizePerPage);
        }
    };

    const reloadList = () => {
        return getAdminSku({ page, sizeperpage: sizePerPage, filter }).then((data) => {
            setList(data.sku);
            setTotalSize(data?.totalSize || 0);
        });
    };

    const handleCreate = async (data) => {
        setIsLoading(true);
        await createAdminSku(data);
        await reloadList();
        setIsLoading(false);
        setOpenAdd(false);
    };

    const handleEdit = async (data) => {
        setIsLoading(true);
        await editAdminSku(data);
        await reloadList();
        setIsLoading(false);
        setItemEdit(null);
    };

    return (
        <Fragment>
            <Loading isLoading={isLoading} />
            <div className="card mb-5 mb-xl-10">
                <div className="card-body p-9">
                    <div className="al-variants-table">
                        <ReactTable
                            columns={columns}
                            data={list}
                            groupButton={groupButton}
                            handleTableChange={handleTableChange}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            alClassName="table-layout-inherit"
                            sizePerPageList={getSizePerPageList(totalSize)}
                        />
                        <SkuForm
                            isOpen={openAdd}
                            onClose={() => setOpenAdd(false)}
                            title="Create Sku"
                            onSubmit={handleCreate}
                        />
                        <SkuForm
                            isOpen={Boolean(itemEdit)}
                            onClose={() => setItemEdit(null)}
                            formData={itemEdit}
                            title="Edit Sku"
                            onSubmit={handleEdit}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    adminTransition: state.adminTransition,
});
const mapDispatchToProps = {
    formatterCreateAt,
    FormatterStatus,
    getAdminSku,
    createAdminSku,
    editAdminSku,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSku);
