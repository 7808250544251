import { connect } from "react-redux";
import React, { useRef, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Loading from 'components/Loading';
import { removeBackground } from 'actions/designAction';

const ImageEditor = ({ removeBackground, user }) => {
    const cropperRef = useRef(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [imageUrl, setImageUrl] = useState("");
    const [forcusRender, setForcusRender] = useState(false);
    const [loading, setLoading] = useState(false);
    var { removebg } = user.user;
    // Hàm để tải ảnh từ máy tính
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // setImageSrc(URL.createObjectURL(file));
            setForcusRender(!forcusRender);
            const reader = new FileReader();
            reader.onload = () => setImageSrc(reader.result);
            reader.readAsDataURL(file);
        }
    };

    // Hàm để tải ảnh từ URL
    const handleUrlChange = (event) => {
        setImageUrl(event.target.value);
    };

    const loadImageFromUrl = () => {
        if (imageUrl) {
            setImageSrc(imageUrl);
        }
    };
    // const sharpenImage = () => {
    //     const canvas = cropperRef.current.getCroppedCanvas();
    //     const ctx = canvas.getContext('2d');
    //     const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    //     const sharpenedData = new Uint8ClampedArray(imageData.data);
    //     // Implement sharpening logic here
    //     ctx.putImageData(new ImageData(sharpenedData, canvas.width, canvas.height), 0, 0);
    //     const sharpenedBase64 = canvas.toDataURL();
    //     // setSharpenedImage(sharpenedBase64);
    // };

    // Hàm cắt ảnh và lấy dữ liệu ảnh đã cắt
    const getCroppedImage = async () => {
        setLoading(true)
        var cropper = cropperRef.current;
        if (typeof cropper.getCroppedCanvas !== 'function') {
            cropper = cropperRef.current?.cropper;
        }
        if (cropper) {
            // Kiểm tra cropperRef đã sẵn sàng chưa
            const croppedCanvas = cropper.getCroppedCanvas();

            const ctx = croppedCanvas.getContext('2d');
            const imageData = ctx.getImageData(0, 0, croppedCanvas.width, croppedCanvas.height);
            const sharpenedData = new Uint8ClampedArray(imageData.data);
            // Implement sharpening logic here

            const sharpenMatrix = [
                [0, -1, 0],
                [-1, 5, -1],
                [0, -1, 0]
            ];

            // Apply convolution matrix to each pixel in the image
            for (let i = 1; i < croppedCanvas.height - 1; i++) {
                for (let j = 1; j < croppedCanvas.width - 1; j++) {
                    const x = i * 4 + j * 4 * croppedCanvas.width;
                    let r = 0, g = 0, b = 0;

                    // Convolve with sharpen matrix
                    for (let mi = -1; mi <= 1; mi++) {
                        for (let mj = -1; mj <= 1; mj++) {
                            const idx = x + (mi * 4 * croppedCanvas.width) + (mj * 4);
                            const weight = sharpenMatrix[mi + 1][mj + 1];
                            r += imageData.data[idx] * weight;
                            g += imageData.data[idx + 1] * weight;
                            b += imageData.data[idx + 2] * weight;
                        }
                    }

                    // Set the sharpened pixel data
                    sharpenedData[x] = Math.min(Math.max(r, 0), 255);
                    sharpenedData[x + 1] = Math.min(Math.max(g, 0), 255);
                    sharpenedData[x + 2] = Math.min(Math.max(b, 0), 255);
                }
            }
            ctx.putImageData(new ImageData(sharpenedData, croppedCanvas.width, croppedCanvas.height), 0, 0);


            const croppedImageUrl = croppedCanvas.toDataURL();

            // Chuyển đổi ảnh đã cắt thành file Blob
            const croppedBlob = await fetch(croppedImageUrl).then(res => res.blob());
            // const croppedFile = new File([croppedBlob], 'cropped-image.png', { type: 'image/png' });

            // Gửi ảnh đã cắt tới API để tách nền
            const formData = new FormData();
            formData.append('image', croppedBlob); // ảnh đã cắt
            const backgroundRemovedImage = await removeBackground(formData);
            if (backgroundRemovedImage && backgroundRemovedImage !== "") {
                setCroppedImage(backgroundRemovedImage);
            }
        } else {
            console.error('cropperRef.current is not available');
        }
        setLoading(false)
    };

    // const binaryToBase64 = (binaryString) => {
    //     const CHUNK_SIZE = 1024; // Chia chuỗi nhị phân thành các phần nhỏ, mỗi phần có độ dài là 1024 ký tự
    //     let base64String = "";

    //     for (let i = 0; i < binaryString.length; i += CHUNK_SIZE) {
    //         const chunk = binaryString.slice(i, i + CHUNK_SIZE); // Cắt ra từng phần nhỏ
    //         const uint8Array = new Uint8Array(chunk.length); // Mảng Uint8Array chứa các byte

    //         for (let j = 0; j < chunk.length; j++) {
    //             uint8Array[j] = chunk.charCodeAt(j); // Chuyển mỗi ký tự thành mã byte
    //         }

    //         base64String += btoa(String.fromCharCode.apply(null, uint8Array));
    //     }

    //     return `data:image/png;base64,${base64String}`; // Tiền tố cho Base64 hình ảnh PNG
    // }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    // Hàm tách nền ảnh qua API PhotRoom
    // const removeBackground = async (croppedImage) => {
    //     const apiKey = 'vk-oD6cCj22omNVMd4kmQrfo8WcTeAvxCbgxBqhn3CgNgaS5f'; // Thay thế bằng API key của bạn
    //     const formData = new FormData();
    //     formData.append('image', croppedImage); // ảnh đã cắt

    //     try {
    //         const response = await axios.post('https://api.vyro.ai/v2/image/background/remover', formData, {
    //             headers: {
    //                 'Authorization': `Bearer ${apiKey}`,
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //             responseType: 'blob'
    //         });
    //         if (response.status === 200) {
    //             return await toBase64(response.data);
    //         } else {
    //             throw new Error('Không thể tách nền.');
    //         }
    //     } catch (error) {
    //         console.error('Lỗi khi tách nền:', error);
    //         return null;
    //     }
    // };

    const upscaleImage = (imageBase64) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = imageBase64;

            img.onload = () => {
                const maxHeight = 1500;
                const maxWidth = 2000;
                let width = img.width;
                let height = img.height;

                // Phóng to hình ảnh nếu kích thước nhỏ hơn giới hạn
                if (width < maxWidth) {
                    height = (maxWidth / width) * height;
                    width = maxWidth;
                }
                if (height < maxHeight) {
                    width = (maxHeight / height) * width;
                    height = maxHeight;
                }

                // Vẽ lại hình ảnh đã thay đổi kích thước lên canvas
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                // Trả về hình ảnh mới dưới dạng Base64
                resolve(canvas.toDataURL('image/png'));  // Hoặc 'image/jpeg' nếu muốn JPEG
            };

            img.onerror = reject;
        });
    };

    const handleDownload = async () => {
        const resizedImage = await upscaleImage(croppedImage);
        const link = document.createElement('a');
        link.href = resizedImage;
        link.download = 'image-' + Date.now() + '.png';
        link.click();
    };

    return (

        <div>
            <Loading isLoading={loading} />
            <h2 className="text-center mb-4">CROP & REMOVE BACKGROUND</h2>
            <div className="row">
                <div className="col">
                    <div className="mb-4">
                        <input type="file" className="form-control" onChange={handleImageChange} />
                    </div>

                    <div className="mb-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="URL image"
                            value={imageUrl}
                            onChange={handleUrlChange}
                        />
                        <button className="btn btn-primary mt-2" onClick={loadImageFromUrl}>
                            Image URL
                        </button>
                    </div>

                    {imageSrc && (
                        <div className="mb-4">
                            <Cropper
                                key={forcusRender}
                                src={imageSrc}
                                ref={cropperRef}
                                style={{ height: 800, width: '100%' }}
                                aspectRatio={0}
                                guides={false}
                                onInitialized={(instance) => {
                                    cropperRef.current = instance;
                                }}
                            />
                            <button className="btn btn-success mt-3" onClick={getCroppedImage}>
                                Start
                            </button>
                            <div><i className="text-danger">You have <b>{removebg || 0}</b> turns left before it expires.</i></div>
                        </div>
                    )}
                </div>
                <div className="col">
                    {croppedImage && (
                        <div className="mt-4 d-flex flex-column align-items-center">
                            <h4 className="text-center">Result:</h4>
                            <img src={croppedImage} alt="Cropped and Background Removed" className="img-fluid border p-3 border-primary" />
                            <button className="btn btn-primary mt-3" onClick={handleDownload}>
                                Download Image
                            </button>
                        </div>
                    )}

                </div>

            </div>



        </div>
    );
};
const mapStateToProps = (state) => ({
    user: state.user,
});
const mapDispatchToProps = {
    removeBackground,
};
export default connect(mapStateToProps, mapDispatchToProps)(ImageEditor);