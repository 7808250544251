import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { getShops } from "actions/shopActions";
import { getStaffs } from "actions/teamActions";
import { getDesigns, deleteDesign } from "actions/designAction";
import { formatterCreateAt, formatterImage } from "components/Function";
import { ReactTable } from "components/Table";
import Loading from "components/Loading";
import SelectBox from "components/elements/selectBox";
import { ModalAddDesign, ModalEditDesign } from "./modal";
import ReactImageMagnify from 'react-image-magnify';
import { Link } from "react-router-dom";


function DesignList(props) {
    const [isOpenModalAddDesign, setIsOpenModalAddDesign] = useState(false);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [toggleCard, setToggleCard] = useState(false);
    const [filter, setFilter] = useState({});
    const [staffs, setStaffs] = useState([]);
    const [designSelect, setDesignSelect] = useState(null);
    const { t } = useTranslation();

    var { list, totalsize, isLoading } = props.design;
    var { getStaffs, getDesigns, formatterCreateAt, deleteDesign, formatterImage } = props;

    useEffect(() => {
        getDesigns({ page, sizeperpage: sizePerPage, search: searchText, filter });
    }, [getDesigns, page, sizePerPage, searchText, filter]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    useEffect(() => {
        getStaffs({ sizeperpage: 100 }).then((data) => {
            setStaffs(data?.data?.map((staff) => ({ value: staff?.user?._id, label: staff.user.email })) || []);
        });
    }, [getStaffs]);

    const onClickOpenModalAddDesign = () => {
        setIsOpenModalAddDesign(!isOpenModalAddDesign);
    };
    const onClickDeleteDesign = (row = {}) => {
        row = {
            ...row,
            page, sizeperpage: sizePerPage, search: searchText, filter
        }
        deleteDesign(row);
    };

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "product_id",
            text: t("Product ID"),
            sort: true,
        },
        {
            dataField: "sku_name",
            text: t("SKU name"),
            sort: true,
        },
        {
            dataField: "title",
            text: t("Title"),
            sort: true,
        },
        {
            dataField: "mockup",
            text: t("Mockup"),
            sort: true,
            formatter: (cell, row, rowIndex) => <>
                <div className="d-flex justify-content-center mb-5">
                    <div className="d-flex align-items-center justify-content-center flex-column">
                        <span className="h3">Front</span>
                        <div className="symbol symbol-50px me-2">
                            {
                                cell?.Front ?
                                    <Link to={cell?.Front} target="_blank">
                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(cell?.Front) }, largeImage: { src: formatterImage(cell?.Front), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                    </Link>
                                    :
                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                            }
                        </div>
                    </div>
                    <div className="d-flex  align-items-center justify-content-center flex-column ml-5">
                        <span className="h3">Back</span>
                        <div className="symbol symbol-50px me-2">
                            {
                                cell?.Back ?
                                    <Link to={cell?.Back} target="_blank">
                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(cell?.Back) }, largeImage: { src: formatterImage(cell?.Back), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                    </Link>
                                    :
                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                            }
                        </div>
                    </div>
                    <div className="d-flex  align-items-center justify-content-center flex-column mr-5">
                        <span className="h3">Neck</span>
                        <div className="symbol symbol-50px me-2">
                            {
                                cell?.Neck ?
                                    <Link to={cell?.Neck} target="_blank">
                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(cell?.Neck) }, largeImage: { src: formatterImage(cell?.Neck), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                    </Link>
                                    :
                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                            }
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center mb-5">
                    <div className="d-flex  align-items-center justify-content-center flex-column mr-5">
                        <span className="h3">Right</span>
                        <div className="symbol symbol-50px me-2">
                            {
                                cell?.Right ?
                                    <Link to={cell?.Right} target="_blank">
                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(cell?.Right) }, largeImage: { src: formatterImage(cell?.Right), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                    </Link>
                                    :
                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                            }
                        </div>
                    </div>
                    <div className="d-flex  align-items-center justify-content-center flex-column mr-5">
                        <span className="h3">Left</span>
                        <div className="symbol symbol-50px me-2">
                            {
                                cell?.Left ?
                                    <Link to={cell?.Left} target="_blank">
                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(cell?.Left) }, largeImage: { src: formatterImage(cell?.Left), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                    </Link>
                                    :
                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                            }
                        </div>
                    </div>
                    <div className="d-flex  align-items-center justify-content-center flex-column mr-5">
                        <span className="h3">AOP 3D</span>
                        <div className="symbol symbol-50px me-2">
                            {
                                cell?.AOP_3D ?
                                    <Link to={cell?.AOP_3D} target="_blank">
                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(cell?.AOP_3D) }, largeImage: { src: formatterImage(cell?.AOP_3D), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                    </Link>
                                    :
                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                            }
                        </div>
                    </div>
                </div>
            </>,
        },
        {
            dataField: "design",
            text: t("Design"),
            sort: true,
            formatter: (cell, row, rowIndex) => <>
                <div className="d-flex justify-content-center mb-5">
                    <div className="d-flex align-items-center justify-content-center flex-column">
                        <span className="h3">Front</span>
                        <div className="symbol symbol-50px me-2">
                            {
                                cell?.Front ?
                                    <Link to={cell?.Front} target="_blank">
                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(cell?.Front) }, largeImage: { src: formatterImage(cell?.Front), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                    </Link>
                                    :
                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                            }
                        </div>
                    </div>
                    <div className="d-flex  align-items-center justify-content-center flex-column ml-5">
                        <span className="h3">Back</span>
                        <div className="symbol symbol-50px me-2">
                            {
                                cell?.Back ?
                                    <Link to={cell?.Back} target="_blank">
                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(cell?.Back) }, largeImage: { src: formatterImage(cell?.Back), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                    </Link>
                                    :
                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                            }
                        </div>
                    </div>
                    <div className="d-flex  align-items-center justify-content-center flex-column mr-5">
                        <span className="h3">Neck</span>
                        <div className="symbol symbol-50px me-2">
                            {
                                cell?.Neck ?
                                    <Link to={cell?.Neck} target="_blank">
                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(cell?.Neck) }, largeImage: { src: formatterImage(cell?.Neck), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                    </Link>
                                    :
                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                            }
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center mb-5">
                    <div className="d-flex  align-items-center justify-content-center flex-column mr-5">
                        <span className="h3">Right</span>
                        <div className="symbol symbol-50px me-2">
                            {
                                cell?.Right ?
                                    <Link to={cell?.Right} target="_blank">
                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(cell?.Right) }, largeImage: { src: formatterImage(cell?.Right), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                    </Link>
                                    :
                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                            }
                        </div>
                    </div>
                    <div className="d-flex  align-items-center justify-content-center flex-column mr-5">
                        <span className="h3">Left</span>
                        <div className="symbol symbol-50px me-2">
                            {
                                cell?.Left ?
                                    <Link to={cell?.Left} target="_blank">
                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(cell?.Left) }, largeImage: { src: formatterImage(cell?.Left), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                    </Link>
                                    :
                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                            }
                        </div>
                    </div>
                    <div className="d-flex  align-items-center justify-content-center flex-column mr-5">
                        <span className="h3">AOP 3D</span>
                        <div className="symbol symbol-50px me-2">
                            {
                                cell?.AOP_3D ?
                                    <Link to={cell?.AOP_3D} target="_blank">
                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(cell?.AOP_3D) }, largeImage: { src: formatterImage(cell?.AOP_3D), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                    </Link>
                                    :
                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                            }
                        </div>
                    </div>
                </div>
            </>,
        },
        {
            dataField: "created_at",
            text: t("Created At"),
            sort: true,
            formatter: (cell, row) => formatterCreateAt(cell),
        }, {
            dataField: '-',
            text: t("Actions"),
            formatter: (cell, row, rowIndex) =>
                <Fragment>
                    <button onClick={() => {
                        setDesignSelect({
                            ...row,
                            page, sizeperpage: sizePerPage, search: searchText, filter
                        })
                    }} className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2" data-tooltip-id="my-tooltip" data-tooltip-content={t("Edit")}>
                        <i className="ki-outline ki-notepad-edit fs-2"></i>
                    </button>
                    <button onClick={() => { onClickDeleteDesign(row) }} className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm" data-tooltip-id="my-tooltip" data-tooltip-content={t("Delete")}>
                        <i className="ki-outline ki-trash fs-2"></i>
                    </button>
                </Fragment>

        }
    ];

    const sizePerPageList = [
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalsize || 10,
        },
    ];

    const groupButton = () => {
        return (
            <>
                <div className="mb-5 d-flex">
                    <button onClick={() => { onClickOpenModalAddDesign() }} type="button" className="btn btn-primary btn-sm me-2">
                        <i className="ki-outline ki-plus fs-2"></i> {t("Add Design")}
                    </button>
                    <SelectBox
                        options={staffs}
                        name="filter_staff"
                        value={filter.staff || ""}
                        onChange={(data) => {
                            setFilter((prev) => ({
                                ...prev,
                                staff: data.value || "",
                            }));
                            setPage(1);
                        }}
                    />
                </div>
            </>
        );
    };

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setSearchText(searchText);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };


    return (
        <Fragment>
            <Loading isLoading={isLoading} />
            <div className="card mb-5 mb-xl-10">
                <div
                    className={cx("card-header collapsible cursor-pointer rotate", {
                        active: !toggleCard,
                        collapsed: toggleCard,
                    })}
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_docs_card_collapsible"
                    onClick={() => setToggleCard(!toggleCard)}
                >
                    <h3 className="card-title">{t("List Design")}</h3>
                    <div className="card-toolbar rotate-180">
                        <i className="ki-duotone ki-down fs-1"></i>
                    </div>
                </div>
                <div id="kt_docs_card_collapsible" className={cx("collapse", { show: !toggleCard })}>
                    <div className="card-body p-9">
                        <div className="al-variants-table">
                            <ReactTable
                                columns={columns}
                                data={list}
                                groupButton={groupButton}
                                handleTableChange={handleTableChange}
                                page={page}
                                sizePerPage={sizePerPage}
                                totalSize={totalSize}
                                alClassName="table-layout-inherit"
                                sizePerPageList={sizePerPageList}
                            />
                        </div>
                        {isOpenModalAddDesign ? <ModalAddDesign openModal={onClickOpenModalAddDesign} isOpenModalAddDesign={isOpenModalAddDesign} /> : ''}
                        <ModalEditDesign
                            data={designSelect}
                            onClose={() => setDesignSelect(null)}
                            onSubmit={() => {
                                getDesigns({ page, sizeperpage: sizePerPage, search: searchText, filter });
                            }}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

DesignList.propTypes = {
    getStaffs: PropTypes.func.isRequired,
    getShops: PropTypes.func.isRequired,
    getDesigns: PropTypes.func.isRequired,
    deleteDesign: PropTypes.func.isRequired,
    formatterImage: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    shop: state.shop,
    design: state.design
});
const mapDispatchToProps = {
    formatterCreateAt,
    getShops,
    getStaffs,
    getDesigns,
    deleteDesign,
    formatterImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(DesignList);
