import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import PropTypes from "prop-types";
import { getProductsByShops } from "actions/productActions";
import { formatterCreateAt, subStringText, convertTZ } from "components/Function";
import { ReactTable } from "components/Table";
import { syncProduct } from "actions/productActions";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { ListDateType } from "helper/constant";

function Listproducts(props) {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [totalSize, setTotalSize] = useState(0);
    const [toggleCard, setToggleCard] = useState(false);
    const [loadingSync, setLoadingSync] = useState(false);
    const [dateType, setDateType] = useState("");
    const { t } = useTranslation();
    var { listproductsbyshops, totalsize_listproductsbyshops } = props.product;
    var { list } = props.shop;
    var {
        getProductsByShops,
        promotion_selected,
        shops_selected,
        products_selected,
        setProductsSelected,
        searchText,
        setSearchText,
        syncProduct,
        subStringText,
        check_all_products,
        setCheckAllProducts,
        filter,
        setFilter,
    } = props;

    useEffect(() => {
        if (Object.keys(filter).length) {
            setProductsSelected([]);
            getProductsByShops({ page, sizeperpage: sizePerPage, search: searchText, promotion_selected, shops_selected, filter });
        }
    }, [setProductsSelected, getProductsByShops, page, sizePerPage, searchText, promotion_selected, shops_selected, filter]);

    useEffect(() => {
        setTotalSize(totalsize_listproductsbyshops);
    }, [totalsize_listproductsbyshops]);

    useEffect(() => {
        setPage(1);
        setSizePerPage(10);

        if (dateType === "") {
            setFilter((prev) => ({
                ...prev,
                dateStart: "",
                dateEnd: "",
            }));
        }
        if (dateType === "custom") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "today") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "yesterday") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate());
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - 7 - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_month") {
            let date = new Date();
            date = convertTZ(date);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_month") {
            let date = new Date();
            date = convertTZ(date);
            date.setMonth(date.getMonth() - 1);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_year") {
            let date = new Date();
            date = convertTZ(date);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_year") {
            let date = new Date();
            date = convertTZ(date);
            date.setFullYear(date.getFullYear() - 1);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        // eslint-disable-next-line
    }, [dateType]);

    const onClickSelectAllProducts = async () => {
        setProductsSelected([]);
        setCheckAllProducts(!check_all_products);
    };

    const handleEventDate = (start, end) => {
        setPage(1);
        setSizePerPage(10);

        let date_start = new Date(start);
        date_start.setHours(0, 0, 0, 0);

        let date_end = new Date(end);
        date_end.setHours(23, 59, 59, 999);
        setFilter((prev) => ({
            ...prev,
            dateStart: date_start.getTime(),
            dateEnd: date_end.getTime(),
        }));
    };

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "shopId",
            text: t("Shop"),
            sort: true,
            formatter: (cell, row) => list.find((v) => v._id === cell)?.name || "",
        },
        {
            dataField: "title",
            text: t("Title"),
            sort: true,
            formatter: (cell, row) => (
                <Fragment>
                    <div className="symbol symbol-50px me-2">
                        <img src={row?.main_images?.[0] || require("components/assets/media/misc/image.png")} alt="" />
                    </div>
                    <div className="d-inline-block">
                        <span className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">{subStringText(cell, 130)}</span>
                    </div>
                </Fragment>
            ),
        },
        {
            dataField: 'totalOrder',
            text: t("Total Order"),
            sort: true,
        },
        {
            dataField: "skus",
            text: t("Skus"),
            sort: true,
            formatter: (cell, row) => <Fragment>{cell?.length > 0 ? "VARIANTS" : "PRODUCT"}</Fragment>,
        },
        {
            dataField: "price",
            text: t("Price"),
            sort: true,
        },
    ];

    const groupButton = () => {
        return (
            <div className="mb-5 d-flex flex-wrap gap-5">
                {/* <button
                    onClick={async () => {
                        setLoadingSync(true);
                        await syncProduct(shops_selected).then(() => {
                            // setProductsSelected([]);
                            // getProductsByShops({
                            //     page,
                            //     sizeperpage: sizePerPage,
                            //     search: searchText,
                            //     promotion_selected,
                            //     shops_selected,
                            // });
                        });
                        setLoadingSync(false);
                    }}
                    disabled={loadingSync}
                    type="button"
                    className="btn btn-primary btn-sm me-2 mb-2"
                >
                    {loadingSync && (
                        <div className="spinner-border text-light spinner-border-sm mx-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    )}

                    {t("Product Sync Today")}
                </button>
                <button
                    onClick={async () => {
                        setLoadingSync(true);
                        await syncProduct(shops_selected, "other").then(() => {
                            // setProductsSelected([]);
                            // getProductsByShops({
                            //     page,
                            //     sizeperpage: sizePerPage,
                            //     search: searchText,
                            //     promotion_selected,
                            //     shops_selected,
                            // });
                        });
                        setLoadingSync(false);
                    }}
                    disabled={loadingSync}
                    type="button"
                    className="btn btn-danger btn-sm me-2 mb-2"
                >
                    {loadingSync && (
                        <div className="spinner-border text-light spinner-border-sm mx-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    )}

                    {t("Product Sync All")}
                </button> */}
                <button onClick={() => { onClickSelectAllProducts() }} type="button" className={cx("btn btn-sm me-2 mb-2", { "btn-secondary": !check_all_products, "btn-success": check_all_products })}>
                    <i className={cx("ki-outline fs-2", { "ki-cross-square": !check_all_products, "ki-check-square": check_all_products })}></i> {t("Select all products")}
                </button>
                <Select
                    name="dateType"
                    options={ListDateType}
                    className="basic-multi-select al-custom-select-search-variants mr-1 mb-0 al-filter-table"
                    classNamePrefix="select"
                    value={ListDateType.filter(({ value }) => value === dateType)}
                    onChange={(e) => {
                        setDateType(e?.value || "");
                    }}
                    placeholder="Select a date ... "
                />
                {dateType === "custom" && filter?.dateStart && filter?.dateEnd ? (
                    <DateRangePicker
                        onCallback={handleEventDate}
                        initialSettings={{
                            startDate: formatterCreateAt(filter.dateStart, "dmy"),
                            endDate: formatterCreateAt(filter.dateEnd, "dmy"),
                            showDropdowns: true,
                            locale: { format: "DD/MM/YYYY" },
                        }}
                    >
                        <input type="text" className="form-control d-inline-block w-200px" />
                    </DateRangePicker>
                ) : (
                    ""
                )}

                <div style={{ maxWidth: "450px" }} className="input-group mb-0">
                    <input
                        type="number"
                        className="form-control"
                        placeholder="Order Min"
                        onChange={(e) =>
                            handleChangeOrderFilter("order_min", e.target.value)
                        }
                        value={filter.order_min || 0}
                    />
                    <span className="input-group-text">-</span>
                    <input
                        type="number"
                        className="form-control"
                        placeholder="Order Max"
                        value={filter.order_max || 0}
                        onChange={(e) =>
                            handleChangeOrderFilter("order_max", e.target.value)
                        }
                    />
                </div>
            </div>
        );
    };

    const handleChangeOrderFilter = (name, value) => {
        setPage(1);
        setSizePerPage(10);
        setFilter((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(10);
            setSearchText(searchText);
        } else {
            setPage(page || 1);
            setSizePerPage(sizePerPage);
        }
    };

    const removeSelect = (arr, value) => {
        var i = 0;
        while (i < arr.length) {
            if (arr[i] === value) {
                arr.splice(i, 1);
            } else {
                ++i;
            }
        }
        return arr;
    };

    const onSelect = (row, isSelect) => {
        setCheckAllProducts(false);
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp.push(row._id);
        } else {
            products_selected_tmp = removeSelect(products_selected_tmp, row._id);
        }
        var new_arr = JSON.stringify(products_selected_tmp);
        setProductsSelected(JSON.parse(new_arr));
    };

    const onSelectAll = (isSelect, row) => {
        setCheckAllProducts(false);
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp = row.map((v, i) => v._id);
        } else {
            products_selected_tmp = [];
        }
        setProductsSelected(products_selected_tmp);
    };

    const selectRow = {
        mode: "checkbox",
        clickToSelect: false,
        selected: products_selected,
        onSelect: onSelect,
        onSelectAll: onSelectAll,
        selectionHeaderRenderer: ({ indeterminate, checked, onChange }) => (
            <div className="d-flex">
                <input
                    type="checkbox"
                    ref={(input) => {
                        if (input) {
                            input.indeterminate = indeterminate;
                            input.checked = checked;
                        }
                    }}
                    onChange={onChange}
                />
                <span style={{ marginLeft: '8px' }}>({products_selected.length})</span>
            </div>
        ),
    };

    const sizePerPageList = [
        {
            text: '10', value: 10
        },
        {
            text: '50', value: 50
        },
        {
            text: '100', value: 100
        },
        {
            text: '150', value: 150
        },
        {
            text: '200', value: 200
        },
        {
            text: 'All', value: totalsize_listproductsbyshops || 10
        }
    ];

    return (
        <Fragment>
            <div className="card mb-5 mb-xl-10">
                <div
                    className={cx("card-header collapsible cursor-pointer rotate", {
                        active: !toggleCard,
                        collapsed: toggleCard,
                    })}
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_docs_card_collapsible"
                    onClick={() => setToggleCard(!toggleCard)}
                >
                    <h3 className="card-title mr-5">{t("List Products")} Total: {totalsize_listproductsbyshops} Products</h3>
                    <div className="card-toolbar rotate-180">
                        <i className="ki-duotone ki-down fs-1"></i>
                    </div>
                </div>
                <div id="kt_docs_card_collapsible" className={cx("collapse", { show: !toggleCard })}>
                    <div className="card-body p-9">
                        <div className="al-variants-table">
                            <ReactTable
                                columns={columns}
                                data={listproductsbyshops}
                                groupButton={groupButton}
                                handleTableChange={handleTableChange}
                                page={page}
                                sizePerPage={sizePerPage}
                                totalSize={totalSize}
                                alClassName={cx("table-layout-inherit", { "al-disabled": check_all_products })}
                                isSelectRow={true}
                                selectRow={selectRow}
                                sizePerPageList={sizePerPageList}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

Listproducts.propTypes = {
    formatterCreateAt: PropTypes.func.isRequired,
    getProductsByShops: PropTypes.func.isRequired,
    subStringText: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    product: state.product,
    shop: state.shop,
});
const mapDispatchToProps = {
    formatterCreateAt,
    getProductsByShops,
    syncProduct,
    subStringText,
};

export default connect(mapStateToProps, mapDispatchToProps)(Listproducts);
