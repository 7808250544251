import React, { useState, useEffect, useMemo } from "react";
import { Button, UncontrolledPopover } from "reactstrap";

export const CheckboxDropdown = ({ items, onChangeData, defaultData }) => {
    const labelRandom = useMemo(() => `PopoverLegacy_${Date.now()}`, []);
    const [arrayData, setArrayData] = useState(defaultData?.length ? defaultData : items.map((i) => i.id));
    const checkboxChange = (e) => {
        setArrayData((prev) => {
            let result = [...prev, e.target.value];
            if (!e.target.checked) {
                result = result.filter((i) => i !== e.target.value);
            }
            onChangeData?.(result);
            return result;
        });
    };

    useEffect(() => {
        onChangeData?.(arrayData);
    }, [arrayData, onChangeData])

    return (
        <div>
            <Button id={labelRandom} type="button" className="btn btn-light">
                Columns
            </Button>
            <UncontrolledPopover placement="bottom" target={labelRandom} trigger="legacy">
                <div className="d-flex flex-column gap-3 p-4">
                    {items.reduce((r, i) => {
                        if (i.label || i.id)
                            r.push(
                                <div key={i.id} id={i.id}>
                                    <input
                                        className="form-check-input me-2"
                                        type="checkbox"
                                        value={i.id}
                                        checked={arrayData.includes(i.id)}
                                        onChange={checkboxChange}
                                    />
                                    {i.label || i.id}
                                </div>
                            );
                        return r;
                    }, [])}
                </div>
            </UncontrolledPopover>
        </div>
    );
};
