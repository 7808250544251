import { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { ListDateType } from "helper/constant";
import { convertTZ, formatterCreateAt } from "components/Function";
import DateRangePicker from "react-bootstrap-daterangepicker";

const DatetimePicker = ({ onChange }) => {
    const [dateType, setDateType] = useState("");
    const isMounted = useRef(false);
    const [dateFilter, setDateFilter] = useState({
        dateStart: "",
        dateEnd: "",
    });

    useEffect(() => {
        if (isMounted.current) {
            onChange?.(dateFilter);
        }
        isMounted.current = true;
        // eslint-disable-next-line
    }, [dateFilter]);

    useEffect(() => {
        if (dateType === "") {
            setDateFilter({
                dateStart: "",
                dateEnd: "",
            });
        }
        if (dateType === "custom") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end, "Asia/Ho_Chi_Minh");
            date_end.setHours(23, 59, 59, 999);
            setDateFilter({
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            });
        }
        if (dateType === "today") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end, "Asia/Ho_Chi_Minh");
            date_end.setHours(23, 59, 59, 999);
            setDateFilter({
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            });
        }
        if (dateType === "yesterday") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setDate(date_start.getDate() - 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate());
            date_end.setHours(23, 59, 59, 999);
            setDateFilter({
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            });
        }
        if (dateType === "this_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setDate(date_start.getDate() - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setDateFilter({
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            });
        }
        if (dateType === "last_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setDate(date_start.getDate() - 7 - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setDateFilter({
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            });
        }
        if (dateType === "this_month") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setDateFilter({
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            });
        }
        if (dateType === "last_month") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            date.setMonth(date.getMonth() - 1);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setDateFilter({
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            });
        }
        if (dateType === "this_year") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setDateFilter({
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            });
        }
        if (dateType === "last_year") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            date.setFullYear(date.getFullYear() - 1);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setDateFilter({
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            });
        }
        // eslint-disable-next-line
    }, [dateType]);

    const handleEventDate = (start, end) => {
        let date_start = new Date(start);
        date_start.setHours(0, 0, 0, 0);

        let date_end = new Date(end);
        date_end.setHours(23, 59, 59, 999);
        setDateFilter({
            dateStart: date_start.getTime(),
            dateEnd: date_end.getTime(),
        });
    };

    return (
        <>
            <Select
                name="dateType"
                options={ListDateType.filter((v) => v.value)}
                className="al-select"
                classNamePrefix="select"
                value={ListDateType.filter(({ value }) => value === dateType)}
                onChange={(e) => {
                    setDateType(e?.value || "");
                }}
                placeholder="Select a date ... "
            />
            {dateType === "custom" && dateFilter?.dateStart && dateFilter?.dateEnd && (
                <div>
                    <DateRangePicker
                        onCallback={handleEventDate}
                        initialSettings={{
                            startDate: formatterCreateAt(dateFilter.dateStart, "dmy"),
                            endDate: formatterCreateAt(dateFilter.dateEnd, "dmy"),
                            showDropdowns: true,
                            locale: { format: "DD/MM/YYYY" },
                        }}
                    >
                        <input type="text" className="form-control al-form-control-daterangepicker h-100 ms-4" />
                    </DateRangePicker>
                </div>
            )}
        </>
    );
};

export default DatetimePicker;
