import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";
import { getListPackageActive, buyPackage } from "actions/packageActions";
import ModalBuyCustom from "./modalBuyCustom";

const Packages = ({ getListPackageActive, buyPackage, user, package: props_package }) => {
    const { t } = useTranslation();
    const [packages, setPackages] = useState([]);
    const { isLoading } = props_package;

    useEffect(() => {
        getListPackageActive().then((data) => {
            setPackages(data?.listpackages || []);
        });
    }, [getListPackageActive]);

    const handleBuy = (packageId) => {
        buyPackage({ packageId, checkNew: user?.package_id !== packageId });
    };

    const onClickShareData = async (limit) => {
        buyPackage({ packageId: packages[0]._id, checkNew: user?.package_id !== packages[0]._id, limit });
    };

    return (
        <div className="card min-w-full">
            <Loading isLoading={isLoading} />
            <div className="card-body">
                <div className="d-flex flex-column">
                    <div className="mb-13 text-center">
                        <h1 className="fs-2hx fw-bold mb-5">{t("Choose Your Plan")}</h1>
                        <div className="text-gray-600 fw-semibold fs-5">
                            {t("If you need more info about our pricing, please check")}{" "}
                            <span role="button" className="link-primary fw-bold">
                                {t("Pricing Guidelines")}
                            </span>
                            .
                        </div>
                    </div>
                    <div className="row mb-10">
                        <div className="col">
                            <ModalBuyCustom
                                onSubmit={(data) => {
                                    onClickShareData(data);
                                }}
                            />
                        </div>

                    </div>
                    <div className="row g-10" hidden={true}>
                        {packages.map((_package, key) => (
                            <div key={key} className="col-xl-3">
                                <div className="d-flex h-100 align-items-center">
                                    <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
                                        <div className="mb-7 text-center">
                                            <h1 className="text-gray-900 mb-5 fw-bolder">{_package?.name}</h1>
                                            <div className="text-gray-600 fw-semibold mb-5">
                                                {_package?.description}
                                            </div>
                                            <div className="text-center">
                                                <span className="mb-2 text-primary"> $ </span>
                                                <span
                                                    className="fs-3x fw-bold text-primary"
                                                    data-kt-plan-price-month={39}
                                                    data-kt-plan-price-annual={399}
                                                >
                                                    {_package?.price}
                                                </span>
                                                <span className="fs-7 fw-semibold opacity-50">
                                                    / <span data-kt-element="period">{_package.time} Days</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="w-100 mb-10">
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                                                    Up to {_package.limit_shop} Active Shops
                                                </span>
                                                <i className="ki-outline ki-check-circle fs-1 text-success" />
                                            </div>
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                                                    Up to {_package.limit_staff} Staff in team
                                                </span>
                                                <i className="ki-outline ki-check-circle fs-1 text-success" />
                                            </div>
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                                                    Manager Promotions
                                                </span>
                                                <i className="ki-outline ki-check-circle fs-1 text-success" />
                                            </div>
                                            <div className="d-flex align-items-center mb-5">
                                                <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                                                    Manager Orders
                                                </span>
                                                <i className="ki-outline ki-check-circle fs-1 text-success" />
                                            </div>
                                            {/* <div className="d-flex align-items-center mb-5">
                                                <span className="fw-semibold fs-6 text-gray-600 flex-grow-1">
                                                    Finance Module
                                                </span>
                                                <i className="ki-outline ki-cross-circle fs-1" />
                                            </div> */}
                                        </div>
                                        <button
                                            onClick={() => handleBuy(_package._id)}
                                            type="button"
                                            className="btn btn-sm btn-primary"
                                        >
                                            Buy
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state?.user?.user,
    package: state.package
});
const mapDispatchToProps = { getListPackageActive, buyPackage };

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
