import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { scaleNumber, isNumeric } from "components/Function";
import { ReactTableDataGrid } from "components/Table";

function ModalEditInformations({ onSubmit, row, place = "", column = "", scaleNumber, rowIndex, drop_list }) {
    const [isOpen, setIsOpen] = useState(false);
    const [product_id, setProductId] = useState(place === "groupbutton" ? row : [row?.product_id]);
    const [exchange_type_price, setExchangeTypePrice] = useState("rate");
    const [exchange_rate_price, setExchangeRatePrice] = useState(1);
    const [exchange_type_variants, setExchangeTypeVariants] = useState("rate");
    const [exchange_rate_variants, setExchangeRateVariants] = useState(1);
    const [weight, setWeight] = useState(row?.weight?.value || 0);
    const [weight_type, setWeightType] = useState(row?.weight?.type || "POUND");
    const [length, setLength] = useState(row?.dimensions?.length || 0);
    const [width, setWidth] = useState(row?.dimensions?.width || 0);
    const [height, setHeight] = useState(row?.dimensions?.height || 0);
    const [unit, setUnit] = useState(row?.dimensions?.unit || "INCH");
    const [columns, setColumns] = useState([]);
    const [variants_split, setVariantsSplit] = useState(row?.variants_split || []);
    const initial_data = localStorage.getItem(drop_list) ? JSON.parse(localStorage.getItem(drop_list))?.[rowIndex]?.variants_split : [];
    const { t } = useTranslation();

    useEffect(() => {
        setProductId(place === "groupbutton" ? row : [row?.product_id]);
        setExchangeTypePrice("rate");
        setExchangeRatePrice(1);
        setExchangeTypeVariants("rate");
        setExchangeRateVariants(1);
        setWeight(row?.weight?.value || 0.5);
        setWeightType(row?.weight?.type || "POUND");
        setLength(row?.dimensions?.length || 3);
        setWidth(row?.dimensions?.width || 3);
        setHeight(row?.dimensions?.height || 3);
        setUnit(row?.dimensions?.unit || "INCH");
        setVariantsSplit(initial_data || []);
    }, [isOpen, row, place]); // eslint-disable-line react-hooks/exhaustive-deps

    const onChange = (e) => {
        if (e.target.name === "exchange_type_price") {
            setExchangeTypePrice(e.target.value);
        }
        if (e.target.name === "exchange_rate_price") {
            setExchangeRatePrice(e.target.value);
        }
        if (e.target.name === "exchange_type_variants") {
            setExchangeTypeVariants(e.target.value);
        }
        if (e.target.name === "exchange_rate_variants") {
            setExchangeRateVariants(e.target.value);
        }
        if (e.target.name === "weight") {
            setWeight(e.target.value);
        }
        if (e.target.name === "weight_type") {
            setWeightType(e.target.value);
        }
        if (e.target.name === "length") {
            setLength(e.target.value);
        }
        if (e.target.name === "width") {
            setWidth(e.target.value);
        }
        if (e.target.name === "height") {
            setHeight(e.target.value);
        }
        if (e.target.name === "unit") {
            setUnit(e.target.value);
        }
    };

    const handleSubmit = () => {
        onSubmit({ product_id, price: { exchange_type: exchange_type_price, exchange_rate: exchange_rate_price }, weight: { value: weight, type: weight_type}, dimensions: { length: scaleNumber({ number: length, fix: 2 }), width: scaleNumber({ number: width, fix: 0 }), height: scaleNumber({ number: height, fix: 0 }), unit }, variants: { exchange_type: exchange_type_variants, exchange_rate: exchange_rate_variants }, variants_split });
        setIsOpen(false);
    };

    const onKeyPress = (e) => {
        if ((/^[0-9.]+$/).test(e)) {
            e.preventDefault();
        }
    }

    const onCellValueChanged = ({ data, colDef, newValue, oldValue, node, api }) => {
        if (colDef?.field === "price" && !isNumeric(newValue)) {
            data[colDef.field] = oldValue; 
            api.refreshCells({ rowNodes: [node], columns: [colDef.field] });
            return;
        }
    }

    useEffect(() => {
        let columns_tmp = [];
        for (const [key] of Object.entries(variants_split?.[0] || {})) {
            let obj = {};

            if (key === "image") {
                obj = {
                    field: key,
                    headerName: key,
                    sort: true,
                    editable: false,
                    cellClass: "centered-cell",
                    cellRenderer: ({ rowIndex, value: cell, data: row }) => (
                        <Fragment>
                            <img
                                src={cell}
                                alt=""
                                className="al-img-table al-cursor-pointer"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = require("components/assets/vendors/images/no-image.png");
                                }}
                            />
                        </Fragment>
                    ),
                };
            } else if (key === "price") {
                obj = {
                    field: key,
                    headerName: key,
                    sort: true,
                    editable: true,
                    cellClass: "centered-cell",
                    cellRenderer: ({ rowIndex, value: cell, data: row }) => (
                        <Fragment>
                            {cell} <i className="fa fa-pencil"></i>
                        </Fragment>
                    ),
                };
            } else {
                obj = {
                    field: key,
                    headerName: key,
                    sort: true,
                    editable: false,
                    cellClass: "centered-cell",
                };
            }

            if (obj && Object.keys(obj).length) {
                if (key === "image") {
                    columns_tmp.unshift(obj);
                } else {
                    columns_tmp.push(obj);
                }
            }
        }

        if (columns_tmp.length) {
            setColumns([
                {
                    field: "_id",
                    headerName: "",
                    sort: true,
                    editable: false,
                    cellClass: "centered-cell",
                    width: 10,
                    cellRenderer: ({ rowIndex, value: cell, data: row }) => rowIndex + 1,
                },
                ...columns_tmp,
                {
                    field: "-",
                    headerName: t("Actions"),
                    editable: false,
                    cellRenderer: ({ rowIndex, value: cell, data: row }) => (
                        <Fragment>
                            <button
                                className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={t("Delete")}
                                onClick={() =>
                                    setVariantsSplit((prev) => prev.filter((v, i) => i !== rowIndex))
                                }
                            >
                                <i className="ki-outline ki-trash fs-2"></i>
                            </button>
                        </Fragment>
                    ),
                },
            ]);
        }
    }, [variants_split]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
            {
                place === "groupbutton" ?
                    <button onClick={() => { setIsOpen(true) }} type="button" className="btn btn-info btn-sm me-1" disabled={product_id.length <= 0}>
                        {t("Edit Informations")}
                    </button>
                :
                    <button
                        className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={t("Edit Informations")}
                        onClick={() => { setIsOpen(true) }}
                    >
                        <i className="ki-outline ki-notepad-edit fs-2"></i>
                    </button>
            }
            <Modal
                centered={true}
                size="xl"
                toggle={() => {
                    setIsOpen(!isOpen);
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h2 className="mb-0 text-info">{t("Edit informations")}</h2>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    {
                        column === "variants" || column === "all" ?
                            <Fragment>
                                <div className="row">
                                    <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                            <span className="required">{t("Exchange Type Variants")}</span>
                                        </label>
                                        <select
                                            className="form-select"
                                            name="exchange_type_variants"
                                            onChange={(e) => { onChange(e) }}
                                            value={exchange_type_variants}
                                        >
                                            {/* {
                                                place !== "groupbutton" ? */}
                                                    <Fragment>
                                                        <option value="rate">{t("Rate")}</option>
                                                        <option value="percent">{t("Percent")}</option>
                                                        <option value="plus">{t("Plus")}</option>
                                                    </Fragment>
                                                {/* : ""
                                            } */}
                                            <option value="fix">{t("Fix")}</option>
                                            <option value="custom">{t("Custom")}</option>
                                        </select>
                                    </div>
                                    <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                            <span className="required">{t("Exchange Rate Variants")}</span>
                                        </label>
                                        <input
                                            type={exchange_type_variants === "custom" ? "text" : "number"}
                                            className="form-control"
                                            placeholder={t("Exchange Rate")}
                                            name="exchange_rate_variants"
                                            onChange={(e) => { onChange(e) }}
                                            value={exchange_rate_variants}
                                            onKeyPress={exchange_type_variants === "custom" ? (e) => onKeyPress(e) : undefined}
                                        />
                                        {
                                            exchange_type_variants === "custom" ? 
                                                <div class="form-text">
                                                    {`{price}`} - Example: {`{price}`}*2+3
                                                    <i className="ki-duotone ki-copy cursor-pointer ms-1" onClick={() => navigator.clipboard.writeText(`{price}`)}></i>
                                                </div>
                                            : ""
                                        }
                                    </div>
                                </div>
                            </Fragment>
                        : ""
                    }
                    {
                        column === "variants" ?
                            <Fragment>
                                <ReactTableDataGrid
                                    tableName="modalEditInformations"
                                    idProperty="_id"
                                    columns={columns}
                                    rowData={variants_split}
                                    gridOptions={{
                                        rowHeight: 80,
                                        reactiveCustomComponents: true,
                                    }}
                                    paginationPageSize={999999}
                                    totalSize={999999}
                                    emptyText={t("No data!")}
                                    isSearchText={false}
                                    height={100}
                                    onCellValueChanged={onCellValueChanged}
                                    showAllColumns={true}
                                    stopEditingWhenCellsLoseFocus={true}
                                />
                            </Fragment>
                        : ""
                    }
                    {
                        column === "price" || column === "all" ?
                            <Fragment>
                                <div className="row">
                                    <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                            <span className="required">{t("Exchange Type Price")}</span>
                                        </label>
                                        <select
                                            className="form-select"
                                            name="exchange_type_price"
                                            onChange={(e) => { onChange(e) }}
                                            value={exchange_type_price}
                                        >
                                            {/* {
                                                place !== "groupbutton" ? */}
                                                    <Fragment>
                                                        <option value="rate">{t("Rate")}</option>
                                                        <option value="percent">{t("Percent")}</option>
                                                        <option value="plus">{t("Plus")}</option>
                                                    </Fragment>
                                                {/* : ""
                                            } */}
                                            <option value="fix">{t("Fix")}</option>
                                            <option value="custom">{t("Custom")}</option>
                                        </select>
                                    </div>
                                    <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                            <span className="required">{t("Exchange Rate Price")}</span>
                                        </label>
                                        <input
                                            type={exchange_type_price === "custom" ? "text" : "number"}
                                            className="form-control"
                                            placeholder={t("Exchange Rate")}
                                            name="exchange_rate_price"
                                            onChange={(e) => { onChange(e) }}
                                            value={exchange_rate_price}
                                            onKeyPress={exchange_type_price === "custom" ? (e) => onKeyPress(e) : undefined}
                                        />
                                        {
                                            exchange_type_price === "custom" ? 
                                                <div class="form-text">
                                                    {`{price}`} - Example: {`{price}`}*2+3
                                                    <i className="ki-duotone ki-copy cursor-pointer ms-1" onClick={() => navigator.clipboard.writeText(`{price}`)}></i>
                                                </div>
                                            : ""
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                            <span className="required">{t("Weight")}</span>
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder={t("Weight")}
                                            name="weight"
                                            onChange={(e) => { onChange(e) }}
                                            value={weight}
                                            onKeyPress={(e) => {onKeyPress(e)}}
                                        />
                                    </div>
                                    <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                            <span className="required">{t("Weight Type")}</span>
                                        </label>
                                        <select
                                            className="form-select"
                                            name="weight_type"
                                            onChange={(e) => { onChange(e) }}
                                            value={weight_type}
                                        >
                                            <option value="KILOGRAM">{t("KILOGRAM")}</option>
                                            <option value="POUND">{t("POUND")}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                        <span>{t("Dimensions")}</span>
                                    </label>
                                    <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder={t("Length")}
                                            name="length"
                                            onChange={(e) => { onChange(e) }}
                                            value={length}
                                            onKeyPress={(e) => {onKeyPress(e)}}
                                        />
                                    </div>
                                    <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder={t("Width")}
                                            name="width"
                                            onChange={(e) => { onChange(e) }}
                                            value={width}
                                            onKeyPress={(e) => {onKeyPress(e)}}
                                        />
                                    </div>
                                    <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder={t("Height")}
                                            name="height"
                                            onChange={(e) => { onChange(e) }}
                                            value={height}
                                            onKeyPress={(e) => {onKeyPress(e)}}
                                        />
                                    </div>
                                    <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                        <select
                                            className="form-select"
                                            name="unit"
                                            onChange={(e) => { onChange(e) }}
                                            value={unit}
                                        >
                                            <option value="CENTIMETER">{t("CENTIMETER")}</option>
                                            <option value="INCH">{t("INCH")}</option>
                                        </select>
                                    </div>
                                </div>
                            </Fragment>
                        : ""
                    }
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

// ModalEditInformations.propTypes = {
// };
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    scaleNumber,
    isNumeric
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditInformations);
