import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

function ModalForm({ formData, onSubmit, onClose, isOpen, title }) {
    const [data, setData] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        if (formData) setData(formData);
    }, [formData]);

    const handleSave = () => {
        const form = data;
        if (formData?._id) form.id = formData?._id;
        onSubmit?.(form);
    };

    const onChange = (e) => {
        setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    return (
        <Fragment>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    onClose();
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t(title)}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Name")}</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={data?.name || ""}
                            placeholder={t("Name")}
                            name="name"
                            onChange={(e) => {
                                onChange(e);
                            }}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Sku")}</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={data?.sku || ""}
                            placeholder={t("Sku")}
                            name="sku"
                            onChange={(e) => {
                                onChange(e);
                            }}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Color")}</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={data?.color || ""}
                            placeholder={t("Color")}
                            name="color"
                            onChange={(e) => {
                                onChange(e);
                            }}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Size")}</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={data?.size || ""}
                            placeholder={t("Size")}
                            name="size"
                            onChange={(e) => {
                                onChange(e);
                            }}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Base Cost")}</span>
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            value={data?.base_cost || ""}
                            placeholder={t("Base Cost")}
                            name="base_cost"
                            onChange={(e) => {
                                onChange(e);
                            }}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Handling Fee")}</span>
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            value={data?.handling_fee || ""}
                            placeholder={t("Handling Fee")}
                            name="handling_fee"
                            onChange={(e) => {
                                onChange(e);
                            }}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("2nd Side Print")}</span>
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            value={data?.["2nd_side_print"] || ""}
                            placeholder={t("2nd Side Print")}
                            name="2nd_side_print"
                            onChange={(e) => {
                                onChange(e);
                            }}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Shipping Fee")}</span>
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            value={data?.shipping_fee || ""}
                            placeholder={t("Shipping Fee")}
                            name="shipping_fee"
                            onChange={(e) => {
                                onChange(e);
                            }}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Add Shipping Fee 2nd")}</span>
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            value={data?.add_shipping_fee_2nd || ""}
                            placeholder={t("Add Shipping Fee 2nd")}
                            name="add_shipping_fee_2nd"
                            onChange={(e) => {
                                onChange(e);
                            }}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Label Fee")}</span>
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            value={data?.label_fee || ""}
                            placeholder={t("label_fee")}
                            name="label_fee"
                            onChange={(e) => {
                                onChange(e);
                            }}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Line Shipping Normal")}</span>
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            value={data?.line_ship_normal || ""}
                            placeholder={t("Line Shipping Normal")}
                            name="line_ship_normal"
                            onChange={(e) => {
                                onChange(e);
                            }}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Line Shipping Gold")}</span>
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            value={data?.line_ship_gold || ""}
                            placeholder={t("Line Shipping Gold")}
                            name="line_ship_gold"
                            onChange={(e) => {
                                onChange(e);
                            }}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Line Shipping Plat")}</span>
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            value={data?.line_ship_plat || ""}
                            placeholder={t("Line Shipping Plat")}
                            name="line_ship_plat"
                            onChange={(e) => {
                                onChange(e);
                            }}
                        />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Stock")}</span>
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            value={data?.stock || ""}
                            placeholder={t("Stock")}
                            name="stock"
                            onChange={(e) => {
                                onChange(e);
                            }}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                        onClick={() => {
                            handleSave();
                        }}
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalForm);
