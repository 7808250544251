import { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactTableDataGrid } from "components/Table";
import {
    getListThemes,
    addTheme,
    editTheme,
    deleteTheme,
    themeToShop,
} from "actions/themeActions";
import { getShops } from "actions/shopActions";
import { FormatterStatus, formatterCreateAt } from "components/Function";
import Loading from "components/Loading";
import CustomHeader from "components/elements/customHeaderTable";
import ModalUpload from "./modalUpload";
import ModalChange from "./modalChange";
import EditProfile from "./editProfile";
import ReactImageMagnify from 'react-image-magnify';
import { Link } from "react-router-dom";

const Theme = ({
    theme,
    getListThemes,
    addTheme,
    editTheme,
    deleteTheme,
    formatterCreateAt,
    getShops,
    shop,
    themeToShop,
}) => {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [shopsSelect, setShopsSelect] = useState([]);
    const [themeSelect, setThemeSelect] = useState(null);
    const { t } = useTranslation();
    const { list, totalsize, isLoading } = theme;
    const { list: listShop } = shop;

    const columns = [
        {
            field: 'checkbox',
            headerName: "",
            cellClass: "centered-cell",
            headerCheckboxSelection: true,
            checkboxSelection: true,
            width: 50,
            sortable: false,
        },
        {
            field: "_id",
            width: 50,
            cellClass: "centered-cell",
            headerName: "",
            cellRenderer: ({ rowIndex, value: cell, data: row }) => rowIndex + 1,
            sortable: true,
            comparator: () => 0,
        },
        {
            field: "name",
            headerName: t("Name"),
            flex: 1,
            sortable: true,
        },
        {
            field: "design",
            headerName: t("Design"),
            flex: 1,
            sortable: false,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => <>
                {
                    cell ? <Link to={cell} target="_blank">
                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 100, height: 100, src: cell }, largeImage: { src: cell, width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                    </Link> : <img src={require("components/assets/media/misc/image.png")} alt="" width={100} />
                }
            </>,
        },
        {
            field: "mockup",
            headerName: t("Mockup"),
            flex: 1,
            sortable: false,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => <>
                {
                    cell ? <Link to={cell} target="_blank">
                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 100, height: 100, src: cell }, largeImage: { src: cell, width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                    </Link> : <img src={require("components/assets/media/misc/image.png")} alt="" width={100} />
                }
            </>,
        },
        {
            field: "description",
            headerName: t("Description"),
            flex: 1,
            sortable: false,
        },
        {
            field: "sharing",
            headerName: t("Sharing"),
            flex: 1,
            sortable: false,
        },
        {
            field: "updated_at",
            headerName: t("Last Update"),
            editable: false,
            flex: 1,
            cellRenderer: ({ value: cell }) => <span data-tooltip-id="my-tooltip"
                data-tooltip-content={formatterCreateAt(cell)}>{formatterCreateAt(cell)}</span>,
        },
        {
            field: "-",
            headerName: t("Actions"),
            flex: 1,
            sortable: false,
            cellRenderer: ({ rowIndex, value: cell, data: row }) =>
                <button
                    onClick={() => {
                        setThemeSelect(row);
                    }}
                    className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={t("Edit Note")}
                >
                    <i className="ki-outline ki-notepad-edit fs-2"></i>
                </button>
        },
    ];

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalsize || 50,
        },
    ];

    const onSelectionChanged = (data) => {
        if (data.api) {
            const ids = data.api.getSelectedRows().map((d) => d._id);
            setShopsSelect(ids);
        }
    };

    const handleAddTheme = async (data) => {
        await addTheme({ ...data });
    };

    const handleChangeTheme = async (shop) => {
        await themeToShop({ ...shop, theme: shopsSelect?.[0] });
    };

    const handleChangeDataProfile = async (data) => {
        await editTheme({ _id: themeSelect._id.toString(), ...data });
    };

    useEffect(() => {
        getListThemes({ page, sizeperpage: sizePerPage, search: searchText });
    }, [getListThemes, page, sizePerPage, searchText]);

    useEffect(() => {
        getShops({ page: 1, sizeperpage: 3000 });
    }, [getShops]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);
    const groupButton = () => {
        return (
            <div className="mb-5 d-flex gap-2 flex-wrap pb-4">
                <ModalUpload onSubmit={handleAddTheme} />
                <ModalChange onSubmit={handleChangeTheme}
                    shops={listShop?.map((v, i) => ({
                        value: v?._id,
                        label: `${v.name} - ${v.note}/${v.member_note}`,
                    }))} />
            </div>
        );
    };

    const onTableChanged = ({ page, limit, text }) => {
        if (limit) setSizePerPage(limit);
        if (page) setPage(page);
        if (typeof text === "string") setSearchText(text);
    };

    const defaultColDef = useMemo(() => ({
        wrapText: true,
        autoHeight: true,
        autoHeaderHeight: true,
    }), []);


    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onSortChanged = (e) => {
        if (e.source === "uiColumnSorted") {
            console.log(e.api.setSuppressAnimations);
            const sortedColumns = e.api.getColumnState().filter((col) => col.sort);
            console.log(sortedColumns?.[0]?.colId, sortedColumns?.[0]?.sort);
        }
    };

    return (
        <div className="card mb-5 mb-xl-10">
            <Loading isLoading={isLoading} />
            <div className="card-body">
                <div className="al-variants-table al-max-height-table-cus">
                    {/* <ReactTable
                        columns={columns}
                        data={list || []}
                        groupButton={groupButton}
                        handleTableChange={handleTableChange}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={totalSize}
                        alClassName="table-layout-inherit"
                        sizePerPageList={sizePerPageList}
                        isSelectRow={true}
                        selectRow={selectRow}
                    /> */}
                    <ReactTableDataGrid
                        tableName="shopConnect"
                        idProperty="_id"
                        columns={columns}
                        rowData={list}
                        defaultColDef={defaultColDef}
                        gridOptions={{
                            reactiveCustomComponents: true,
                        }}
                        paginationPageSize={sizePerPage}
                        totalSize={totalSize}
                        onTableChanged={onTableChanged}
                        emptyText={t("No data!")}
                        rowSelection="multiple"
                        onSelectionChanged={onSelectionChanged}
                        sizePerPageList={sizePerPageList}
                        groupButton={groupButton}
                        onSortChanged={onSortChanged}
                        components={components}
                    />
                </div>
                <EditProfile
                    data={themeSelect}
                    onClose={() => setThemeSelect(null)}
                    onSubmit={handleChangeDataProfile}
                />
            </div>
        </div>
    );
};

const mapStateToProps = ({ theme, shop }) => ({ theme, shop });
const mapDispatchToProps = {
    FormatterStatus,
    formatterCreateAt,
    getListThemes,
    addTheme,
    editTheme,
    deleteTheme,
    getShops,
    themeToShop,
};

export default connect(mapStateToProps, mapDispatchToProps)(Theme);
