import { Fragment } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "./assets/css/an.css";
import "./assets/css/style.bundle.css";
import "./assets/plugins/global/plugins.bundle.css";

function Body(props) {
    return (
        <Fragment>
            <div className="post d-flex flex-column-fluid" id="kt_post">
                <div id="kt_content_container" className="container-xxl mw-unset">
                    <Routes>
                        <Route path="/" element={"Operator"} />
                    </Routes>
                </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    user: state.user,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Body);
