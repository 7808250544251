import { useEffect, useState } from "react";
import SelectBox from "components/elements/selectBox";
import { connect } from "react-redux";
import { getShops } from "actions/shopActions";

const SelectShop = ({ getShops, onChange }) => {
    const [shops, setShops] = useState([]);
    const [shop, setShop] = useState("");

    useEffect(() => {
        getShops({ sizeperpage: 1000 }).then((data) => {
            setShops(data?.shops?.map((shop) => ({ value: shop._id, label: shop.name })) || []);
        });
    }, [getShops]);

    return (
        <SelectBox
            options={shops}
            name="filter_staff"
            placeholder="Select Shop"
            value={shop || ""}
            onChange={(data) => {
                setShop(data.value || "");
                onChange?.(data.value || "");
            }}
        />
    );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = { getShops };

export default connect(mapStateToProps, mapDispatchToProps)(SelectShop);
