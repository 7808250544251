import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";
import { arrayHierarchy } from "components/Function";

const getCategories = (callback = null, category_version = "v2") => (dispatch, getState) => {
    return axios
        .get(`/api/categories?version=${category_version || "v2"}`, tokenConfig(getState))
        .then((res) => {
            if (res.data.status === 200) {
                if (callback) callback?.(res?.data.data?.categories || []);
                return arrayHierarchy(res?.data.data?.categories || []);
            } else {
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            Swal.fire(t("Error"), t("An error occurred!"), "error");
        });
};

const getAttributes = (id) => (dispatch, getState) => {
    return axios
        .get(`/api/categories/attributes/${id}`, tokenConfig(getState))
        .then((res) => {
            if (res.data.status === 200) {
                return res.data.data;
            } else {
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            Swal.fire(t("Error"), t("An error occurred!"), "error");
        });
};


const getBrands = (data) => (dispatch, getState) => {
    return axios
        .post(`/api/categories/brands`, data, tokenConfig(getState))
        .then((res) => {
            if (res.data.status === 200) {
                return res.data.data;
            } else {
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            Swal.fire(t("Error"), t("An error occurred!"), "error");
        });
};

const getRecommendCategories = (callback = null, category_version = "v2", product_name = "") => (dispatch, getState) => {
    return axios
        .get(`/api/categories/recommend-categories?version=${category_version || "v2"}&product_name=${encodeURIComponent(product_name)}`, tokenConfig(getState))
        .then((res) => {
            if (res.data.status === 200) {
                if (callback) callback?.(res?.data.data?.categories || []);
                return arrayHierarchy(res?.data.data?.categories || []);
            } else {
                Swal.fire(t("Error"), t(res.data.message), "error");
            }
        })
        .catch((err) => {
            console.log(err);
            Swal.fire(t("Error"), t("An error occurred!"), "error");
        });
};

export { getCategories, getAttributes, getBrands, getRecommendCategories };
