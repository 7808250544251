import { useState, Fragment } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";

const UpdateMember = ({ isOpen, onClose, onSubmit }) => {
    const [formData, setFormData] = useState({});
    const { t } = useTranslation();

    const handleChange = (e) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async () => {
        let d = formData;
        await onSubmit?.(d);
        onClose?.();
    };

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={onClose} isOpen={!!isOpen}>
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Setting")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Optimize Title")}</span>
                        </label>
                        <select
                            className="form-select"
                            data-control="select2"
                            data-hide-search="true"
                            data-placeholder="Select a option"
                            name="ai_optimize"
                            onChange={handleChange}
                        >
                            <option value="">Not use</option>
                            <option value="AIV1">AI v1 (From the photo)</option>
                            <option value="AIV2">AI v2 (From old title)</option>
                            <option value="RANDOM">AI v1 - AI v2</option>
                        </select>
                        {/* <input type="text" className="form-control" value={formData?.email || ""} placeholder={t("Email")} name="email" onChange={handleChange} /> */}
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Optimize Time (day)")}</span>
                        </label>
                        <input type="number" className="form-control" min={20} max={365} value={formData?.time_optimize || ""} placeholder={t("Time")} name="time_optimize" onChange={handleChange} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(UpdateMember);
