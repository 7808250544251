import axios from "axios";
import Swal from "sweetalert2";
import { t } from "i18next";
import { tokenConfig } from "./websiteActions";

const getListThemes =
    ({ page = 1, sizeperpage = 50, search = "", filter = {} }) =>
        (dispatch, getState) => {
            dispatch({ type: "LOADING_THEME", payload: true });
            var param_search = "";
            if (search !== "") {
                param_search = `&search=${search}`;
            }
            filter = new URLSearchParams(filter).toString();
            if (filter) filter = `&${filter}`;

            return axios
                .get(`/api/themes?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}`, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_THEME", payload: false });

                    if (res.data.status === 200) {
                        dispatch({
                            type: "LIST_THEME",
                            payload: res.data.data,
                        });
                        return res.data.data;
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                        return [];
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_THEME", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                    return [];
                });
        };

const addTheme = (data) => (dispatch, getState) => {
    const body = {
        ...data,
    };
    return Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to add theme?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then((result) => {
        if (result.value) {
            dispatch({ type: "LOADING_THEME", payload: true });
            return axios
                .post("/api/themes/add", body, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_THEME", payload: false });
                    if (res.data.status === 200) {
                        Swal.fire("Success", res.data.message, "success");
                    } else {
                        Swal.fire("Error", res.data.message, "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_THEME", payload: false });
                    Swal.fire("Error", "An error occurred!", "error");
                });
        }
    });
};

const editTheme = (data) => (dispatch, getState) => {
    return Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to change theme?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then((result) => {
        if (result.value) {
            dispatch({ type: "LOADING_THEME", payload: true });
            return axios
                .post("/api/themes/edit", data, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_THEME", payload: false });
                    if (res.data.status === 200) {
                        Swal.fire("Success", res.data.message, "success");
                    } else {
                        Swal.fire("Error", res.data.message, "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_THEME", payload: false });
                    Swal.fire("Error", "An error occurred!", "error");
                });
        }
    });
};

const deleteTheme = (data) => (dispatch, getState) => {
    return Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to delete theme?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then((result) => {
        if (result.value) {
            dispatch({ type: "LOADING_THEME", payload: true });
            return axios
                .post("/api/themes/delete", data, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_THEME", payload: false });
                    if (res.data.status === 200) {
                        Swal.fire("Success", res.data.message, "success");
                    } else {
                        Swal.fire("Error", res.data.message, "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_THEME", payload: false });
                    Swal.fire("Error", "An error occurred!", "error");
                });
        }
    });
};

const themeToShop = (data) => (dispatch, getState) => {
    return Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure to change theme?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then((result) => {
        if (result.value) {
            dispatch({ type: "LOADING_THEME", payload: true });
            return axios
                .post("/api/themes/theme-to-shop", data, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_THEME", payload: false });
                    if (res.data.status === 200) {
                        Swal.fire("Success", res.data.message, "success");
                    } else {
                        Swal.fire("Error", res.data.message, "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_THEME", payload: false });
                    Swal.fire("Error", "An error occurred!", "error");
                });
        }
    });
};

export { getListThemes, addTheme, editTheme, deleteTheme, themeToShop };
