import { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import ReactImageMagnify from 'react-image-magnify';
import { Link } from "react-router-dom";

const EditProfile = ({ data, onClose, onSubmit }) => {
    const [formData, setFormData] = useState({ ...data });
    const { t } = useTranslation();
    useEffect(() => {
        setFormData(data);
    }, [data]);

    const handleChange = (e) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async () => {
        let d = formData;
        onSubmit?.(d);
        onClose?.();
    };

    return (
        <Fragment>
            <Modal centered={true} size="xl" toggle={onClose} isOpen={!!data}>
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Edit Shop")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onClose(); }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="row mb-5">
                        <div className="col form-group">
                            <label>Name</label>
                            <div className="al-form-input-date">

                                <input type="text" className="form-control w-100" placeholder="0 second" name="name" value={formData?.name} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="col form-group">
                            <label>Sharing</label>
                            <div className="al-form-input-date">
                                <select name="sharing" value={formData?.sharing} className="form-control w-100" onChange={handleChange}>
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="form-group">
                            <label>Description</label>
                            <div className="al-form-input-date">
                                <textarea type="text" className="form-control w-100" rows={3} placeholder="1 day" name="description" value={formData?.description} onChange={handleChange} ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col form-group">
                            <label>Design</label>
                            <div className="al-form-input-date">
                                <input type="text" className="form-control w-100" placeholder="0 second" name="design" value={formData?.design} onChange={handleChange} />
                                {formData?.design ? <Link to={formData?.design} target="_blank">
                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 100, height: 100, src: formData?.design }, largeImage: { src: formData?.design, width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 } }} />

                                </Link> : <img src={require("components/assets/media/misc/image.png")} alt="" width={100} />}
                            </div>
                        </div>
                        <div className="col form-group">
                            <label>Mockup</label>
                            <div className="al-form-input-date">
                                <input type="text" className="form-control w-100" placeholder="0 second" name="mockup" value={formData?.mockup} onChange={handleChange} />
                                {formData?.mockup ? <Link to={formData?.mockup} target="_blank">
                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 100, height: 100, src: formData?.mockup }, largeImage: { src: formData?.mockup, width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 } }} />

                                </Link> : <img src={require("components/assets/media/misc/image.png")} alt="" width={100} />}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={handleSubmit}  >{t("Change")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(EditProfile);
