import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";

const getTotalOrder =
    ({ filter = {} } = {}) =>
    (dispatch, getState) => {
        filter = new URLSearchParams(filter).toString();
        return axios
            .get(`/api/analytic/total?${filter}`, tokenConfig(getState))
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                Swal.fire(t("Error"), t("An error occurred!"), "error");
            });
    };

const getDataChart =
    ({ filter = {} } = {}) =>
    (dispatch, getState) => {
        filter = new URLSearchParams(filter).toString();
        return axios
            .get(`/api/analytic/overview?${filter}`, tokenConfig(getState))
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                Swal.fire(t("Error"), t("An error occurred!"), "error");
            });
    };

const getOnHold =
    ({ filter = {} } = {}) =>
    (dispatch, getState) => {
        filter = new URLSearchParams(filter).toString();
        return axios
            .get(`/api/analytic/on-hold?${filter}`, tokenConfig(getState))
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                Swal.fire(t("Error"), t("An error occurred!"), "error");
            });
    };

const getProductTotal =
    ({ filter = {} } = {}) =>
    (dispatch, getState) => {
        filter = new URLSearchParams(filter).toString();
        return axios
            .get(`/api/analytic/product?${filter}`, tokenConfig(getState))
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                Swal.fire(t("Error"), t("An error occurred!"), "error");
            });
    };

const getInfoTotal =
    ({ filter = {} } = {}) =>
    (dispatch, getState) => {
        filter = new URLSearchParams(filter).toString();
        return axios
            .get(`/api/analytic/info-total?${filter}`, tokenConfig(getState))
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                Swal.fire(t("Error"), t("An error occurred!"), "error");
            });
    };

const getTotalByShop =
    ({ filter = {} } = {}) =>
    (dispatch, getState) => {
        filter = new URLSearchParams(filter).toString();
        return axios
            .get(`/api/analytic/total-by-shop?${filter}`, tokenConfig(getState))
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                Swal.fire(t("Error"), t("An error occurred!"), "error");
            });
    };

const getShopDetail =
    ({ filter = {} } = {}) =>
    (dispatch, getState) => {
        filter = new URLSearchParams(filter).toString();
        return axios
            .get(`/api/analytic/shop-detail?${filter}`, tokenConfig(getState))
            .then((res) => {
                return res.data?.data;
            })
            .catch((err) => {
                Swal.fire(t("Error"), t("An error occurred!"), "error");
            });
    };


const getProductDetail =
    ({ filter = {} } = {}) =>
    (dispatch, getState) => {
        filter = new URLSearchParams(filter).toString();
        return axios
            .get(`/api/analytic/product-detail?${filter}`, tokenConfig(getState))
            .then((res) => {
                return res.data?.data;
            })
            .catch((err) => {
                Swal.fire(t("Error"), t("An error occurred!"), "error");
            });
    };

export { getTotalOrder, getDataChart, getOnHold, getProductTotal, getInfoTotal, getTotalByShop, getShopDetail, getProductDetail };
